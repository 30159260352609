import React, { useEffect, useState ,useContext } from "react";
import { API_BASE_URL, IMAGES_BASE_URL , tokenCookie ,keyHeader } from "src/config";
import axios from "axios";
import { CCol, CRow, CButton } from "@coreui/react";
import ImageGallery from "react-image-gallery";
import ReactHtmlParser from "react-html-parser";
import ItemCard from "../itemCard";
import Loading from "src/components/myWidgets/loadingWidget";
import FavoriteContext from "../FavoriteContext";
import Breadcrumb from "../Breadcrumb";
import Moment from "react-moment";
import { Link } from "react-router-dom";

export default function BonPlansDetails(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [item, setItem] = useState([]);
  const [similars, setsimilars] = useState([]);
  const [pictures, setPictures] = useState([]);
  const { id } = props.match.params;
  const [mainImg, setMainImg] = useState("");
  const [isFav, setIsFav] = useState(null);
  const [iconSrc, setIconSrc] = useState("");

  const [videoframe, setVideoframe] = useState("");

  const [seance23, setSeance23] = useState([]);
  const [menu, setMenu] = useState([]);
  const [menuLink, setMenuLink] = useState([]);
  const [isBeating, setBeating] = useState(false);
  const [phoneClicked, setPhoneClicked] = useState(false);
  const [isLoadedS, setIsLoadedS] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const { favorites, updateFavorites, checkFavorite  } = useContext(
    FavoriteContext
  );
  const  table  = 'GoodPlans';
  
const setFavourite = async () => {
  setBeating(true);

  try {
    const success = await updateFavorites(id, table);

    if (success) {
      setIsFav(true);
      setBeating(false);
    } else {
      setIsFav(false);
      setBeating(false);
    }
  } catch (error) {
    console.error(error);
    setBeating(false);
  }
};

useEffect(() => {
  if(favorites){
    setIsFav(checkFavorite(id,table));
}
}, [id,favorites]);

  useEffect(() => {

    setIsLoadedS(false);
    setsimilars([]);  
    setIsLoaded(false); 
      let tab = [];
      setPictures([]);
      
      const requestOptions = {
        headers: {
        }
      };
      if (tokenCookie) {
        requestOptions.headers.Authorization = `Bearer ${tokenCookie}`;
      }

    if(props.location.state) {

          if (props.location.state.seance23 && props.location.state.seance23.length > 2) {
            setSeance23(props.location.state.seance23.split("++"));
          }
          let imglink = "";
  
          let videolink = props.location.state.video;

          if (videolink.includes("youtube")) {
            if (videolink.includes("?v=")) {
              let l = videolink.split("?v=")[1];
              let temp =
                '<iframe width="560" height="315" src="https://www.youtube.com/embed/' +
                l +
                '" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>';
              setVideoframe(temp);
            }
          } else setVideoframe(videolink);
  
          let tp = props.location.state.typeGoodPlans.id;

          switch (tp) {
            case 1:
              setIconSrc("assets/icons/loisir.svg");
              break;
            case 2:
              setIconSrc("assets/icons/soiree-dj.svg");
              break;
            case 3:
              setIconSrc("assets/icons/food-drink.svg");
              break;
            case 4:
              setIconSrc("assets/icons/hotels.svg");
              break;
            case 5:
              setIconSrc("assets/icons/bien-etre.svg");
              break;
            case 6:
              setIconSrc("assets/icons/shopping.svg");
              break;
            default:
              setIconSrc("");
          }

          setIsLoaded(true);
          setItem(props.location.state);
          props.location.state.imageGoodPlans.map((image) => {
            if (image.menu) {
              setMenu(image.name);
              setMenuLink(`${IMAGES_BASE_URL}/public/uploads/images/goodPlans/${image.name}`);
            }
            else if(image.principal) { imglink =
              IMAGES_BASE_URL +
              "/public/uploads/images/goodPlans/" +
              image.name;
          setMainImg(imglink);}
             else return tab.push({
                original: `${IMAGES_BASE_URL}/public/uploads/images/goodPlans/${image.name}`,
                thumbnail: `${IMAGES_BASE_URL}/public/uploads/images/goodPlans/${image.name}`,
              });
          });
         tab.unshift({
          original: imglink,
          thumbnail: imglink,
        })
          setPictures(tab);

          if(!props.location.pathname.includes('myItemDetails')) 
          axios(`${API_BASE_URL}/annonce_goodPlans/similaire/${id}` , requestOptions)
      .then((res) => res.data)
      .then(
        (result) => {
          setIsLoadedS(true);
          setsimilars(result);
        },
        (error) => {
          setIsLoadedS(true);
          //setError(error);
        }
      );

      const pathName = 'goodPlans';
      axios(`${API_BASE_URL}/addLog/${pathName}/${id}`, keyHeader)
      .then((res) => res.data)
      .then(
        (result) =>{ 
          //console.log('add log result');
          //console.log(result);
      },
      (error) => {
        //console.log(error);
      }
      );

    }
    
    else {
 
    axios(`${API_BASE_URL}/goodPlans/show/${id}`)
      .then((res) => res.data)
      .then(
        (result) => {
          //console.log("good plans", result);
          if(result.tickets)
          {
            const prices = result.tickets.map(item => parseFloat(item.price));
            //console.log('const Prices:', prices);

// Get the highest price
const highestPrice = Math.max(...result.tickets.map(item => parseFloat(item.price)));

// Get the lowest price
const lowestPrice = Math.min(...result.tickets.map(item => parseFloat(item.price)));

//console.log('Highest Price:', highestPrice);
//console.log('Lowest Price:', lowestPrice);
          }
 
          if (result.seance23 && result.seance23.length > 2) {
            setSeance23(result.seance23.split("++"));
          }
          let imglink = "";

          let videolink = result.video;

          if (videolink.includes("youtube")) {
            if (videolink.includes("?v=")) {
              let l = videolink.split("?v=")[1];
              let temp =
                '<iframe width="560" height="315" src="https://www.youtube.com/embed/' +
                l +
                '" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>';
              setVideoframe(temp);
            }
          } else setVideoframe(videolink);
  
          let tp = result.typeGoodPlans.id;
          //console.log("ttppppppppp", tp);
          switch (tp) {
            case 1:
              setIconSrc("assets/icons/loisir.svg");
              break;
            case 2:
              setIconSrc("assets/icons/soiree-dj.svg");
              break;
            case 3:
              setIconSrc("assets/icons/food-drink.svg");
              break;
            case 4:
              setIconSrc("assets/icons/hotels.svg");
              break;
            case 5:
              setIconSrc("assets/icons/bien-etre.svg");
              break;
            case 6:
              setIconSrc("assets/icons/shopping.svg");
              break;
            default:
              setIconSrc("");
          }

          setIsLoaded(true);
          setItem(result);
          result.imageGoodPlans.map((image) => {
            if (image.menu) {
              setMenu(image.name);
              setMenuLink(`${IMAGES_BASE_URL}/public/uploads/images/goodPlans/${image.name}`);
            }
            else if(image.principal) { imglink =
              IMAGES_BASE_URL +
              "/public/uploads/images/goodPlans/" +
              image.name;
          setMainImg(imglink);}
             else return tab.push({
                original: `${IMAGES_BASE_URL}/public/uploads/images/goodPlans/${image.name}`,
                thumbnail: `${IMAGES_BASE_URL}/public/uploads/images/goodPlans/${image.name}`,
              });
          });
         tab.unshift({
          original: imglink,
          thumbnail: imglink,
        })
          setPictures(tab);
          //console.log(pictures);

          const pathName = 'goodPlans';
          axios(`${API_BASE_URL}/addLog/${pathName}/${id}`, keyHeader)
          .then((res) => res.data)
          .then(
            (result) =>{ 
              //console.log('add log result');
              //console.log(result);
          },
          (error) => {
            //console.log(error);
          }
          );
        },

        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );

      if(!props.location.pathname.includes('myItemDetails')) 
      axios(`${API_BASE_URL}/annonce_goodPlans/similaire/${id}`, requestOptions)
      .then((res) => res.data)
      .then(
        (result) => {
          setIsLoadedS(true);
          setsimilars(result);
        },
        (error) => {
          setIsLoadedS(true);
          setError(error);
        }
      );

        }
  
  }, [id]);

  useEffect(() => {
    // Disable body scrolling when in fullscreen mode
    if (isFullscreen) {
      document.body.style.overflow = 'hidden';
      const elements = document.querySelectorAll('.l-sidebar__widget .vehicle-item button');
      elements.forEach(element => {
      element.style.zIndex = 'auto';
         });
    } else {
      document.body.style.overflow = 'auto';
      const elements = document.querySelectorAll('.l-sidebar__widget .vehicle-item button');
      elements.forEach(element => {
      element.style.zIndex = '9';
         });
    }

    return () => {
      document.body.style.overflow = 'auto';
      const elements = document.querySelectorAll('.l-sidebar__widget .vehicle-item button');
      elements.forEach(element => {
      element.style.zIndex = '9';
         });
    };
  }, [isFullscreen]);

  const handleFullScreen = () => {
    //console.log('fulllllll');
    setIsFullscreen(!isFullscreen);
  };

  if (error) {
    return (
      <div className="container">      
        <main className="l-main annonceDetailsPage">
          <div className="l-section l-section--archive">
            <div>Erreur : {error.message}</div>
          </div>
        </main>
      </div>
    );
  } else if (!isLoaded) {
    return (
  <Loading/>
    );
  } else {
    return (
      <div className="container">
      {(!props.location.pathname.includes('myItemDetails')) && <Breadcrumb name=   {item.nom && item.nom.length > 0
                      ? item.nom
                      : item.typeGoodPlans &&
                      item.typeGoodPlans.label} />
                      }
        <main className="l-main">
          <div className="l-main__grid o-container">
            <div className="l-main__article2 l-main__article l-main__content l-main__section l-article ">
              <CRow>
              <span className="snSpan snSpanImg">SN: {item.sn} </span>
                {pictures.length >= 1 && (
                  <ImageGallery items={pictures} thumbnailPosition="right" onScreenChange={handleFullScreen} />
                )}
              </CRow>
              <CRow>
                <CCol md="8" className="mt-4">
                <span className="snSpan ">SN: {item.sn} </span>
                  <h3>
                    {item.nom && item.nom.length > 0
                      ? item.nom
                      : item.typeGoodPlans &&
                      item.typeGoodPlans.label}
                  </h3>
                  <CButton
                      onClick={
                        isBeating ? ()=>{}
                        : () => {
                        setFavourite();                   
                      }
                      }
                      className={`favorite favIconClass ${isBeating ? 'beating' : ''}`}
                    >
                  {isFav == true ?  <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="var(--ci-primary-color, currentColor)"
                          d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"
                        />
                      </svg>
                      : <svg
                      
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="var(--ci-primary-color, currentColor)"
                        d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8v-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5v3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20c0 0-.1-.1-.1-.1c0 0 0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z"
                      />
                    </svg>}
                    </CButton>
                  <div className="price">
                  
                    {item.tickets && item.tickets.length>1 
                             ? (Math.min(...item.tickets.map(item => parseFloat(item.price))).toLocaleString('en-US', {
    useGrouping: true,
    maximumFractionDigits: 0,
  }) + '-' + Math.max(...item.tickets.map(item => parseFloat(item.price))).toLocaleString('en-US', {
    useGrouping: true,
    maximumFractionDigits: 0,
  })
  )
                             : item.tickets && item.tickets.length==1
                             ? item.tickets[0].price.toLocaleString('en-US', {
    useGrouping: true,
    maximumFractionDigits: 0,
  })
                             : item.price 
                             ?item.price.toLocaleString('en-US', {
    useGrouping: true,
    maximumFractionDigits: 0,
  }) 
                             :''
                          }
                    <span className="suffix"> TND</span>
                  </div>
                </CCol>
                <CCol>
                  <div className="mt-4">
                  {item.createdBy && item.createdBy.id != 68 && <p className="title-feature appelez_le">
                  Le vendeur <strong> 
                  <Link
                      to={`/memberAnnonces/${item.createdBy.prenom ? item.createdBy.prenom + '-' : '' }${item.createdBy.nom && item.createdBy.nom }-${item.createdBy.id && item.createdBy.id }`}
                    >
                  {item.seller && item.seller.length > 0 ? item.seller 
                  : item.createdBy && item.createdBy.accountType && item.createdBy.accountType.id==2? item.createdBy.nom
                  : item.createdBy&& item.createdBy.prenom ? 
                    item.createdBy.prenom+' '+ item.createdBy.nom.charAt(0): ''}
                    </Link> </strong>
                  </p> 
                  }               
                    {phoneClicked ? 
                    <a
                      href={`tel:${item.phoneNumber && item.phoneNumber.length > 0 ?
                        item.phoneNumber
                          :
                          item.createdBy && item.createdBy.phoneNumber ?
                          item.createdBy.phoneNumber
                            : ''
                        }`}
                      className="call-phone"
                    >
                      <span>
                    <svg style={{color: "white"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" fill="white"></path></svg> Appelez {item.phoneNumber && item.phoneNumber.length > 0 ?
                          item.phoneNumber
                          :
                          item.createdBy && item.createdBy.phoneNumber ?
                          item.createdBy.phoneNumber
                            : ''}
                      </span>
                    </a> :
                    <button onClick={()=>setPhoneClicked(true)}  className="call-phone">
                    <svg style={{color: "white"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" fill="white"></path></svg> Contacter le vendeur</button>
                    }
                  </div>
                </CCol>
              </CRow>

              <div className="features-vehicle">
                <CRow>
                  <CCol md="6" xs="6" className="filterWhiteIcon">
                    <img
                      src={iconSrc}
                      alt={
                        item.typeGoodPlans && item.typeGoodPlans.label
                      }
                      className="icon-option-vehicle"
                    />
                    <p className="title-feature">type </p>
                    <p className=" title-feature1">
                      {item.typeGoodPlans && item.typeGoodPlans.label}
                    </p>
                    <p></p>
                  </CCol>
                  <CCol md="6" xs="6">
                    <p className="title-feature">Categorie </p>
                    <p className="title-feature12">
                      {item.categoryGoodPlans &&
                        item.categoryGoodPlans.label}
                    </p>
                    <p></p>
                  </CCol>
                </CRow>

                <CRow className="placeInfoRow1">
                  <CCol md="6" xs="6">
                    <img
                      src="assets/icons/icon_map.svg"
                      alt=""
                      className="icon-option-vehicle"
                    />
                    <p className="title-feature">Pays </p>
                    <p className="title-feature1">
                      {item.country && item.country.label}
                    </p>
                  </CCol>
                  <CCol md="6" xs="6">
                    <p className="title-feature">Gouvernorat</p>
                    <p className="title-feature12">
                      {item.city && item.city.label}
                    </p>
                  </CCol>
                </CRow>
                <CRow className="placeInfoRow placeInfoRow1">
                  <CCol md="6" xs="6" className="placeInfoCol">
                    <p className="title-feature">Délégation</p>
                    <p className="title-feature12">
                      {item.delegate && item.delegate.label}
                    </p>
                  </CCol>
                  <CCol md="6" xs="6">
                    <p className="title-feature">Localité</p>
                    <p className="title-feature12">
                      {item.locality && item.locality.label}
                    </p>
                  </CCol>
                </CRow>
                {menu && menu.length > 0 &&
                  <CRow>
                    <CCol md="12" xs="6" className="filterWhiteIcon">
                      <a style={{ color: 'unset' }}
                        href={menuLink}
                      >  <svg className="icon-option-vehicle bi bi-file-text" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16"> <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z" /> <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" /> </svg>

                        <p className="title-feature">menu</p>
                        <p className=" title-feature1">

                          {menu}

                        </p></a>
                      <p></p>
                    </CCol>
                  </CRow>}
                {
                  <CRow className="placeInfoRow placeInfoRow1">
                    {item.adresse && <CCol md="6" xs="6" className="placeInfoCol addresInfoCol">
                      <p className="title-feature">Adresse </p>
                      <p className="title-feature12">
                        { item.adresse}
                      </p>
                    </CCol>}
                   { item.datef && <CCol md="6" xs="6" className="dateInfoCol">
                      <p className="title-feature">Date et heure </p>
                      <p
                        className="title-feature12"
                        style={{ textTransform: "inherit" }}
                      >
                        {item.datef.replace("T", " à ").split("+")[0]}
                      </p>
                    </CCol>}
                  </CRow>
                }
                { seance23[0] && seance23[0].length > 0 && (
                  <CRow className="placeInfoRow placeInfoRow1">
                    <CCol md="6" xs="6" className="placeInfoCol">
                      <p className="title-feature">2em seance à </p>
                      <p className="title-feature12">{seance23[0]}</p>
                    </CCol>
                    { seance23[1] && seance23[1].length > 0 && (
                      <CCol md="6" xs="6">
                        <p className="title-feature">3em seance à </p>
                        <p
                          className="title-feature12"
                          style={{ textTransform: "inherit" }}
                        >
                          {seance23[1]}
                        </p>
                      </CCol>
                    )}
                  </CRow>
                )}
                {item.tickets && item.tickets.length>0 &&(
                  <>
                    <p className="title-feature">Prix des tickets</p>
                    <CRow>
                      {item.tickets.map((el) => {
                        return (
                          <>
                            <CCol md="6" xs="6" className="ticketsprices">
                              <p className="title-feature12">
                                {el.type} - {el.price}
                              </p>
                            </CCol>
                          </>
                        );
                      })}
                    </CRow>
                  </>
                )}
                {item.placesnom && item.placesnom >0 &&(
                  <>
                    <CRow>
                      <CCol md="6" xs="6">
                        <p className="title-feature">
                          Nombre de place a vendre
                        </p>
                        <p className="title-feature12">
                          { item.placesnom}
                        </p>
                      </CCol>
                    </CRow>
                  </>
                )}
                <CRow className="placeInfoRow1 dateInfoRow">
                  <CCol md="6" xs="6">
                    <img
                      src="assets/icons/icon_calandar.svg"
                      alt="icon_calandar"
                      className="icon-option-vehicle"
                    />
                    <p className="title-feature">Ajouté</p>
                    <p className="title-feature1">
                    <Moment format="DD-MM-YYYY">{item.createdAt}</Moment> 
                    </p>
                  </CCol>
                </CRow>
              </div>
              {(item.description || (videoframe && videoframe.length > 0 ))&&(  
                 <div className="features-vehicle">
                <div className="occasion-details">
                  <div className="wrapper">                 
                      <>
                        <h3>Autres information</h3>
                        <div>
                          {videoframe.length > 0 && (
                            <>
                              <br />
                              <h5>Promo </h5>
                              <br />
                            </>
                          ) &&
                            ReactHtmlParser(videoframe)}
                          {ReactHtmlParser(item.description)}
                        </div>
                      </>                  
                  </div>
                </div>
              </div>
               )}
            </div>
            <aside className="l-main__sidebar l-sidebar">
              <div className="l-sidebar__widget u-show-tablet-landscape">
                <div className="l-sidebar__widgetHeading c-heading c-heading--subhead c-heading--bold c-heading--underline c-posts__heading">
                  Annonces similaires
                </div>
                    {/* <SimilarCar
                      item={item}
                        key={item.id}
                        make={
                          item.nom && item.nom.length > 0 ? item.nom:
                          item.typeGoodPlans ?
                          item.typeitem.label:''
                        }
                        model={
                          item.categoryGoodPlans &&
                          item.categoryitem.label
                        }
                        price={
                          item.ticketsprice &&
                            item.ticketsprice.length > 3
                            ? item.ticketsprice
                            : item.price
                        }
                        image={
                          item.mainPicture
                            ? `${IMAGES_BASE_URL}/public/uploads/images/goodPlans/${item.mainPicture.name}`
                            : ""
                        }
                        url={`/bon-plans/details/${item.id}`}
                      /> */} 
                      {!isLoadedS && <div className="loadingGif">
                  <img src="/assets/icons/loading-bar.gif" />
                </div>}
                      {similars &&
                  similars.map((element) => {
                    return (
                      <ItemCard
                        item={element}
                        id = {element.id}
                         table={table}
                          title={
                            element.nom && element.nom.length > 0
                              ? element.nom
                              : element.typeGoodPlans.label
                          }
                          price={
                            element.ticketsprice &&
                            element.ticketsprice.length > 3
                              ? element.ticketsprice
                              : element.price
                          }
                          subtitle={element.categoryGoodPlans.label}
                          city= {element.city && element.city.label}
                          image={
                            element.mainPicture
                              ? `${IMAGES_BASE_URL}/public/uploads/images/goodPlans/${element.mainPicture.name}`
                              : "assets/icons/loisir.svg"
                          }
                          url={`/bon-plans/details/${element.id}`}
                          at={element.createdAt}
                        />
                    );
                  })}
              </div>
            </aside>
          </div>
        </main>
      </div>
    );
  }
}
