import React from 'react';
import Ticker from 'react-ticker';

const NewsTicker = ({ newsItems, speed = 5, direction }) => {
    
  return (
    <Ticker speed={speed} direction={direction}>
      {({ index }) => (
        <div className="news-ticker">
          {newsItems.map((newsItem, i) => (
            <div key={i}>
              <span key={i} className={`news-item ${index === i ? 'active' : ''}`}>
                {newsItem}
              </span>
              {i < newsItems.length  && <img src='assets/images/logos/retina white.svg' className='logoInInfobar' />}
            </div>
          ))}
        </div>
      )}
    </Ticker>
  );
};

export default NewsTicker;
