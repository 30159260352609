import React, { Component } from "react";
import { API_BASE_URL, IMAGES_BASE_URL , cat_news , tokenU } from "../../../config";
import axios from "axios";
import "./styleCkEditor.css";
import imageCompression from "browser-image-compression";
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CLabel,
  CCol,
  CContainer,
  CForm,
  CInput,
  CRow,
  CFormGroup,
  CSelect,
  CInputCheckbox,
  CTextarea,
  CSpinner,
} from "@coreui/react";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Editor } from "@tinymce/tinymce-react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
export class EditNews extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploadingImages:[false,false,false],
      thumbs:[],
      loading: false,
      title: "",
      content: "",
      lastTitle:"",
      lastContent:"",
      createdBy: localStorage.getItem("id"),
      category: "",
      typeNews: "",
      categories: [],
      typeNewsSelect: [],
      image: null,
      youtubeLink: "",
      imageUrl: null,
      status: false,
      aLaUne: false,
      deux: false,
      sections: [{},{},{}],
      toUpload:[false,false,false],
      bodyImage1:null,
      bodyImage2:null,
      bodyImage0:null,
      sectionsIDs:[],
      images: ['','',''],
      files: [],
      toremove: [],
      imagePreviewUrl: "",
      imagePreviewUrlsec: [],

      errors: {
        title: "",
        content: "",
        category: "",
        typeNews: "",
        image: "",
      },
      success: "",
    };
    this.autosaveInterval = setInterval(this.autoSaveFunction, 30000);

  }
  componentDidMount() {

    const { id } = this.props.match.params;
    axios(`${API_BASE_URL}/news/show/${id}`)
      .then((res) => res.data)
      .then((result) => {
        console.log("resulttt");
        //console.log(result);
        let temp= [{},{},{}];
        let tempIds=[null,null,null];
        for (let i = 0; i< 3; i++) {
          if (result.sections.find(({ position }) => position == i))
          { 
            temp[i]=result.sections.find(({ position }) => position == i);
            tempIds[i]=temp[i]['id'];
          }  
        }
      
        if(result.typeNews)  {this.setState({typeNews: result.typeNews.id,});
      
        if (localStorage.getItem("roles") == "ROLE_JOURNALIST") {
          var typecats = cat_news.filter(obj => (obj.type_news_id == result.typeNews.id && localStorage.getItem("allowedCats").includes(obj.label)));
          //console.log('typecats',typecats);
          this.setState({ categories: typecats, });
  
        } else {
          var typecats = cat_news.filter(obj => obj.type_news_id == result.typeNews.id);
          //console.log('typecats',typecats);
          this.setState({  categories: typecats, });
        }
      }
      if(result.category)  this.setState({category: result.category.id,});
        this.setState({
          title: result.title,
          content: decodeURIComponent(result.content),
          lastTitle:result.title,
          lastContent:decodeURIComponent(result.content),
          youtubeLink: result.youtubeLink,
          imageUrl: `${IMAGES_BASE_URL}/public/uploads/actualites/${result.path}`,
          status: result.status,
          aLaUne: result.aLaUne,
          deux: result.deux,
          sections: temp,
          sectionsIDs: tempIds
        });
        //console.log("statttttttte: ",this.state);
      });
  }

  componentWillUnmount() {
    clearInterval(this.autosaveInterval);
  }

  handleCategChange = (e) => {

    this.setState({ typeNews: e.target.value
     , category:"" });
    let id = e.target.value;

    //console.log("this.state", this.state);

      if (localStorage.getItem("roles") == "ROLE_JOURNALIST") {
        var typecats = cat_news.filter(obj => (obj.type_news_id == id && localStorage.getItem("allowedCats").includes(obj.label)));
        //console.log('typecats',typecats);
        this.setState({ categories: typecats, });

      } else {
        var typecats = cat_news.filter(obj => obj.type_news_id == id);
        //console.log('typecats',typecats);
        this.setState({  categories: typecats, });
      }

      //setModels(categF.models);
      // console.log(categF);
    
  };

  handleChange = (event) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    const name = event.target.name;
    this.setState({
      // [event.target.name]: event.target.value,
      [name]: value,
    });
  };

  handleChangeSection(i, event) {
    let tempsec = this.state.sections;
    //console.log(tempsec);
    //console.log(event.target.value);
    tempsec[i].content = event.target.value;
    this.setState({ sections: tempsec });

  }
  handleFileSection = async (index, event) => {
    let valuesImages = [...this.state.images];

    let tempsec = this.state.sections;
    //console.log(tempsec);
    //console.log(event.target.value);
    tempsec[index].path = event.target.files[0].name;

    //console.log(event.target.files[0]);
    let file = event.target.files[0];
    //console.log("file before");
    //console.log(file);

    let options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
    };
    try {
      file = await imageCompression(file, options);
      //console.log("file after");
      //console.log(file);
    } catch (error) {
      //console.log(error);
    }
    valuesImages[index] = file;
    this.setState({
      sections: tempsec,
      files: [...this.state.files, file],
      images: valuesImages,
    });

    // document.getElementById(index).style.display = 'none';
    let reader = new FileReader();
    let nameu = this.state.imagePreviewUrlsec;

    reader.onloadend = () => {
      nameu[index] = reader.result;
      this.setState({
        imagePreviewUrlsec: nameu,
      });
    };
    reader.readAsDataURL(file);
  };

  handleFileSection2 = async ( event, index) => {
    let tempuploading= this.state.uploadingImages;
    tempuploading[index]=true;
    this.setState({uploadingImages:tempuploading});
    //console.log('images at begining',index,this.state.images,event.target.files[0]);
    
    let valuesImages = this.state.images;
    let tempsec = this.state.sections;
    //console.log("tempsec : ",tempsec);
    //console.log(event.target.value);

  //  const { name } = event.target;
    let file = event.target.files[0];
    let tempThumbs = this.state.thumbs;
  
    //console.log("file before");
    //console.log(file);

    let options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
    };
    try {
      file = await imageCompression(file, options);
      //console.log("file after");
      //console.log(file);
      // this.setState({
      //   thumbs: tempThumbs,
      // });
    } catch (error) {
      //console.log(error);
    }
    let c='';
  tempThumbs[index]=URL.createObjectURL(file);
//valuesImages[index]={content:c,image:file,position:index};
    valuesImages[index] = file;
    let temptoupload= this.state.toUpload;
    temptoupload[index]=true;
    if(index==0) this.setState({bodyImage0:file});
    if(index==1) this.setState({bodyImage1:file});
    if(index==2) this.setState({bodyImage2:file});
    // console.log(valuesImages[index][name])

    // this.setState({ values: [...valuesImages] });
    if(tempsec[index]) {
      tempsec[index].path = file;
      tempsec[index].content = 'true'
    }

    this.setState({
      uploadingImages:[false,false,false],
      thumbs: tempThumbs,
      sections: tempsec,
      files: [...this.state.files, file],
      images: valuesImages,
      toUpload:temptoupload
      
    });

  };


  removeClick(i, id) {
    //console.log("id to rem " + id);
    let values = [...this.state.images];
    values.splice(i, 1);
    let files = [...this.state.files];
    files.splice(i, 1);
    let sections = [...this.state.sections];
    sections.splice(i, 1);
    this.setState({
      images: values,
      files: files,
      sections: sections,
      toremove: [...this.state.toremove, id],
    });

  }

  autoSaveFunction = () => {
    //console.log('interval');
    //console.log(this.state);
    let newContent = this.state.content;
    let newTitle = this.state.title;
    if ((newTitle && newTitle.length>1 && newTitle!=this.state.lastTitle)||(newContent && newContent.length>1 && newContent!=this.state.lastContent) ) {
    //console.log("need to autosave");
    this.setState({ loading:true });

        const formData = new FormData();           
        if(this.state.title) formData.append("title", this.state.title);
        if(this.state.content) formData.append("content", encodeURIComponent(this.state.content));
        formData.append("createdBy", tokenU);
        formData.append("category", this.state.category);
        formData.append("typeNews", this.state.typeNews);
        formData.append("youtubeLink", this.state.youtubeLink);
        //formData.append("status", this.state.status);
        //formData.append("alaUne", this.state.aLaUne);
        //formData.append("deux", this.state.deux);
     
        formData.append("autoSave", true);
     
         //console.log('content----- ', formData.get('content'));
    
         const { id } = this.props.match.params;

        axios
          .post(`${API_BASE_URL}/news/edit/${id}`, formData)
          .then((res) => {
            console.log('tgjhjkkkkkkk',res);
            if (res.data.status.toString() === "ok") {
              this.setState({
                success: "autoSaved",
                loading: false,
                lastContent:newContent,
                lastTitle:newTitle
              });
              setTimeout(() => {
                this.setState({
                  success: "",
                });
              }, 2000);
            } else {
              //console.log("not ok ",res);
             this.setState({ loading: false });
  
            }
          })
          .catch((error) => {
            //console.log(" errorsss : ", error);
            this.setState({ loading: false });
  
          });
      


    
  } else {
    //console.log("not need to autosave");

    }

  }


  handleSubmit = (event) => {
    //console.log(this.state);
    //console.log("cccc");
    event.preventDefault();

    if (this.handleValidation()) {
      const formData = new FormData();
      if (this.state.image !== null) {
        formData.append("dataFile", this.state.image, this.state.image.name);
      }
      formData.append("title", this.state.title);
      formData.append("content", encodeURIComponent(this.state.content));
      formData.append("createdBy", tokenU);
      formData.append("category", this.state.category);
      formData.append("typeNews", this.state.typeNews);
      formData.append("youtubeLink", this.state.youtubeLink);
      formData.append("status", this.state.status);
      formData.append("alaUne", this.state.aLaUne);
      formData.append("deux", this.state.deux);
      formData.append("sections", JSON.stringify(this.state.sections));
      formData.append("toremove", JSON.stringify(this.state.toremove));
      if(this.state.bodyImage0) formData.append("bodyImage0", this.state.bodyImage0);
      if(this.state.bodyImage1) formData.append("bodyImage1", this.state.bodyImage1);
      if(this.state.bodyImage2) formData.append("bodyImage2", this.state.bodyImage2);
      formData.append("sectionsIDs", JSON.stringify(this.state.sectionsIDs));
      formData.append("autoSave", false);
   
      //formData.append("images[]", this.state.images);
      // for (let index = 0; index < this.state.sections.length; index++) {
      //   formData.append("sections2[]", this.state.sections[index]);
      // }

      // for (let index = 0; index < this.state.images.length; index++) {
      //   formData.append("images[]", this.state.images[index]);
      //  //if(this.state.images[index]['image']) formData.append("images[]", this.state.images[index]['image']);
      //  //else formData.append("images[]",null);
      // }
      //formData.append("images", JSON.stringify(this.state.images));
      // formData.append()
      const { id } = this.props.match.params;
      // //console.log('files: ',this.state.files);
      // //console.log('images to send', formData.get('images'));
      // //console.log('sections to send', formData.get('sections'));
       //console.log('content----- ', formData.get('content'));

      this.setState({ loading: true });
      axios
        .post(`${API_BASE_URL}/news/edit/${id}`, formData)
        .then((res) => {
          console.log(res);
          if (res.data.status.toString() === "ok") {
            this.setState({
              success: "L'actualité a été modifiée avec succès",
              loading: false,
            });
            window.scrollTo(0, 0);
            setTimeout(() => {
              this.props.history.push("/5467854/news");
            }, 1500);
          } else {
            //console.log("not ok ",res);
           this.setState({ loading: false });

          }
          // this.props.history.push("/5467854/index");
        })
        .catch((error) => {
          //console.log(" errorsss : ", error);
          this.setState({ loading: false });

        });
    }
  };

  handleValidation = () => {
    let fieldTitle = this.state.title;
    let fieldContent = this.state.content;

    // let fieldCategory = this.state.category;
    let errors = {};
    let formIsValid = true;
    if (!fieldTitle) {
      formIsValid = false;
      errors.title = "Le titre de l'actualité ne peut pas être vide";
    } else {
      errors.title = "";
    }
    if (!fieldContent) {
      formIsValid = false;
      errors.content = "Le contenu de l'actualité ne peut pas être vide";
    } else {
      errors.content = "";
    }
    if (this.state.category === "") {
      formIsValid = false;
      errors.category = "Veuillez choisir la catégorie de l'actualité";
    } else {
      errors.category = "";
    }
    if (this.state.typeNews === "") {
      formIsValid = false;
      errors.typeNews = "Veuillez choisir le type de l'actualité";
    } else {
      errors.typeNews = "";
    }
    /* if (this.state.image === null) {
      formIsValid = false;
      errors.image = "Veuillez ajouer une image";
    } else {
      errors.image = "";
    }*/
    this.setState({ errors: errors });
    return formIsValid;
  };

  handleFile = async (event) => {
    let file = event.target.files[0];
    //console.log("file before");
    // console.log(file);

    let options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
    };
    try {
      file = await imageCompression(file, options);
      // console.log("file after");
      //console.log(file);
    } catch (error) {
      //console.log(error);
    }
    this.setState({
      image: file,
    });

    let reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  render() {
    let { imagePreviewUrl } = this.state;
    let imagePreview = null;
    if (imagePreviewUrl) {
      imagePreview = (
        <img
          src={imagePreviewUrl}
          alt="preview"
          width="150"
          className="img-fluid img-thumbnail "
        />
      );
    }

    let errors = this.state.errors;
    return (
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="12" lg="12" xl="12">
            <CCard className="mx-4">
              <CCardBody className="p-4">
                {this.state.success !== "" ? (
                  <CAlert color="success" closeButton>
                    {this.state.success}
                  </CAlert>
                ) : (
                  ""
                )}
                <CForm onSubmit={this.handleSubmit} id="form-add">
                  <h1>Modifier une actualité</h1>
                  <CCol>
                    <p></p>
                  </CCol>

                  <CRow>
                    <CCol xs="6">
                      <CFormGroup>
                        <CLabel htmlFor="title">Titre</CLabel>
                        <CInput
                          id="title"
                          placeholder="Entrer le titre d'actualité"
                          name="title"
                          value={this.state.title}
                          onChange={this.handleChange}
                        />
                      </CFormGroup>
                    </CCol>
                  </CRow>
                  <CCol>
                    {errors.title.length > 0 && (
                      <p
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.title}
                      </p>
                    )}
                  </CCol>
                  <CRow>
                    <CCol xs="8">
                      <CFormGroup>
                        <CLabel htmlFor="content">Type de l'actualité</CLabel>
                        <CSelect
                          name="typeNews"
                          value={this.state.typeNews}
                          //onChange={this.handleChange}
                          onChange={this.handleCategChange}
                        >
                          <option value="">--------------------</option>
                          <option
                                id={1}
                                value="1"
                              >
                                Highlight
                              </option>
                              <option
                                id={2}
                                value="2"
                              >
                               12 M conso
                              </option>
                        </CSelect>
                      </CFormGroup>
                    </CCol>
                  </CRow>
                  <CCol>
                    {errors.typeNews.length > 0 && (
                      <p
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.typeNews}
                      </p>
                    )}
                  </CCol>

                  <CRow>
                    <CCol xs="8">
                      <CFormGroup>
                        <CLabel htmlFor="content">
                          Catégorie de l'actualité
                        </CLabel>
                        <CSelect
                          name="category"
                          value={this.state.category}
                          onChange={this.handleChange}
                        >
                          <option value="">--------------------</option>
                          {this.state.categories.map((category) => {
                            return (
                              <option
                                key={category.id}
                                id={category.id}
                                value={category.id}
                              >
                                {category.label}
                              </option>
                            );
                          })}
                        </CSelect>
                      </CFormGroup>
                    </CCol>
                  </CRow>
                  <CCol>
                    {errors.category.length > 0 && (
                      <p
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.category}
                      </p>
                    )}
                  </CCol>
          
                  <CRow>
                    <CCol xs="8">
                      <CFormGroup>
                        <CLabel htmlFor="content">News main Image</CLabel>
                        <CInput
                          type="file"
                          name="image"
                          onChange={this.handleFile}
                          accept=".jpg,.png,jpeg"
                        />
                      </CFormGroup>
                    </CCol>
                    {imagePreviewUrl.length > 0 ? (
                      imagePreview
                    ) : (
                      <img
                        src={this.state.imageUrl}
                        width="100"
                        className="img-fluid img-thumbnail "
                        alt=""
                      />
                    )}
                  </CRow><br/>

                  <CRow>
                    <CCol xs="12">
                      <CFormGroup>
                        <CLabel htmlFor="content">News Contenu</CLabel>
                        {/* <CKEditor
                          editor={ClassicEditor}
                          data={this.state.content}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            this.setState({
                              content: data,
                            });
                          }}
                        /> */}
                        <p className="sectionEndImage" onClick={()=>  {
  let temp = this.state.content;
 // temp+='<p style="background: #2eb85c;padding: 4px;border-radius: 5px;color: white;width: fit-content;">new section image here</p><br/>';
temp+='<img src="image-reference-label.png"/>'
  this.setState({
  content: temp
})}}>Add section image reference</p>

                        <Editor
                      apiKey="hf1jaliai3snptyx6rfxy0eyb120vcqrvtvzvuenn82j1xdj"
                      value={this.state.content}
                      onEditorChange={ (content, editor) => {
                        this.setState({
                          content: content,
                        });
                      }}
                      init={{
                        height: 300,
                        menubar:false,
                        plugins: [
                          'advlist', 'autolink', 'lists', 'link' , 'charmap', 'preview',
                          'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                          'insertdatetime', 'table', 'code', 'help', 'wordcount'
                        ],
                        toolbar: 'undo redo | ' +
                          'formatselect | ' +
                          'bold italic underline strikethrough | ' +
                          'alignleft aligncenter alignright alignjustify | ' +
                          'bullist numlist outdent indent | ' +
                          'blockquote subscript superscript removeformat | ' +
                          'link table charmap emoticons hr pagebreak | ' +
                          'searchreplace visualblocks code fullscreen | ' +
                          'insertdatetime | ' +
                          'forecolor backcolor | ' +
                          'help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                      }}                                
                     />
                      </CFormGroup>
                    </CCol>
                  </CRow>
                  <CCol>
                    {errors.content.length > 0 && (
                      <p
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.content}
                      </p>
                    )}
                  </CCol>
            
             
             
                  {this.state.sections.length > 0 && this.state.sections[0].position==-1 ?
                    this.state.sections.map((el, i) => {
                      return (
                       <><br/><br/> <div
                          key={el.id}
                          className="card card-accent-info"
                          style={{ marginTop: "20px"}}
                        >
                          <div className="card-header">
                            <CButton
                              type="button"
                              value="remove"
                              className="btn btn-outline-danger btn-sm"
                              onClick={this.removeClick.bind(this, i, el.id)}
                              style={{float:"right"}}
                            >
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" viewBox="0 0 128 128">
<path d="M 49 1 C 47.34 1 46 2.34 46 4 C 46 5.66 47.34 7 49 7 L 79 7 C 80.66 7 82 5.66 82 4 C 82 2.34 80.66 1 79 1 L 49 1 z M 24 15 C 16.83 15 11 20.83 11 28 C 11 35.17 16.83 41 24 41 L 101 41 L 101 104 C 101 113.37 93.37 121 84 121 L 44 121 C 34.63 121 27 113.37 27 104 L 27 52 C 27 50.34 25.66 49 24 49 C 22.34 49 21 50.34 21 52 L 21 104 C 21 116.68 31.32 127 44 127 L 84 127 C 96.68 127 107 116.68 107 104 L 107 40.640625 C 112.72 39.280625 117 34.14 117 28 C 117 20.83 111.17 15 104 15 L 24 15 z M 24 21 L 104 21 C 107.86 21 111 24.14 111 28 C 111 31.86 107.86 35 104 35 L 24 35 C 20.14 35 17 31.86 17 28 C 17 24.14 20.14 21 24 21 z M 50 55 C 48.34 55 47 56.34 47 58 L 47 104 C 47 105.66 48.34 107 50 107 C 51.66 107 53 105.66 53 104 L 53 58 C 53 56.34 51.66 55 50 55 z M 78 55 C 76.34 55 75 56.34 75 58 L 75 104 C 75 105.66 76.34 107 78 107 C 79.66 107 81 105.66 81 104 L 81 58 C 81 56.34 79.66 55 78 55 z"></path>
</svg>
                             <strong> Supprimer</strong>
                            </CButton>
                          </div>

                          <div xs="6" key={i} className="card-body">
                             <CFormGroup>
                              <CLabel htmlFor="content">Contenu section</CLabel>
                              {/* <CTextarea
                                name="content"
                                defaultValue={el.content}
                                onChange={this.handleChangeSection.bind(
                                  this,
                                  i
                                )}
                              /> */}

<ReactQuill theme="snow" value={el.content} onChange={(content) => {
                            let tempsec = this.state.sections;
                            tempsec[i].content= content;
                            this.setState({ sections: tempsec });
                            //console.log('cccccccffffdffdafdh',content);
                          }} />

                           {/* <CKEditor
                          editor={ClassicEditor}
                          data={el.content}
                          onChange={(event, editor) => {
                            let tempsec = this.state.sections;
                            tempsec[i].content= editor.getData();
                            this.setState({ sections: tempsec });
                          }}
                        /> */}

                            </CFormGroup>

                            {el.path && (
                              <CRow>
                                <CCol xs="8">
                                  <CFormGroup>
                                     <CLabel htmlFor="content"> Image </CLabel>:
                                  
                                    <CInput
                                      type="file"
                                      name="image"
                                      onChange={this.handleFileSection.bind(
                                        this,
                                        i
                                      )}
                                      accept=".jpg,.png,jpeg"
                                    />
                                  </CFormGroup>
                                </CCol>
                                {this.state.imagePreviewUrlsec[i] ? (
                                  <img
                                    src={this.state.imagePreviewUrlsec[i]}
                                    alt="preview"
                                    width="150"
                                    className="img-fluid img-thumbnail "
                                  />
                                ) : (
                                  <img
                                    src={`${IMAGES_BASE_URL}/public/uploads/actualites/${el.path}`}
                                    width="100"
                                    className="img-fluid img-thumbnail "
                                    id={i}
                                    alt={this.state.title}
                                  />
                                )}
                              </CRow>
                            )}
                          </div>
                        </div></>
                      );
                    })
                  :<>
                 { [0,1,2].map((el,i)=>{
    return (
      <><br/><br/> 
           <CRow>
                    <CCol xs="8">
                      <CFormGroup>
                        <CLabel htmlFor="content">Body Image {i+1}</CLabel>
                        <CInput
                          type="file"
                          name="image"
                          onChange={(event)=>this.handleFileSection2(event, i)}
                          accept=".jpg,.png,jpeg"
                        />
                      </CFormGroup>
                    </CCol>
                    {this.state.uploadingImages[i] ? <span style={{height:"fit-content",marginLeft:"10px",lineHeight:"90px"}}>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span> :
                 this.state.thumbs[i] ? (
                                  <img
                                    src={this.state.thumbs[i]}
                                    alt="preview"
                                    width="150"
                                    className="img-fluid img-thumbnail "
                                  />
                                ) :
                                
                                this.state.sections[i].path?(
                                  <img
                                    src={`${IMAGES_BASE_URL}/public/uploads/actualites/${this.state.sections[i].path}`}
                                    width="100"
                                    className="img-fluid img-thumbnail "
                                    id={i}
                                    alt={this.state.title}
                                  />
                                ):""}
                    
                  </CRow>
      
      </>)
                 })}
                  {/* <CRow>
                    <CCol xs="8">
                      <CFormGroup>
                        <CLabel htmlFor="content">Body Image 1</CLabel>
                        <CInput
                          type="file"
                          name="image"
                          onChange={(event)=>this.handleFileSection2(event, 0)}
                          accept=".jpg,.png,jpeg"
                        />
                      </CFormGroup>
                    </CCol>
                    {this.state.uploadingImages[0] ? <span style={{height:"fit-content",marginLeft:"10px",lineHeight:"90px"}}>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span> :
                 this.state.thumbs[0] ? (
                                  <img
                                    src={this.state.thumbs[0]}
                                    alt="preview"
                                    width="150"
                                    className="img-fluid img-thumbnail "
                                  />
                                ) :
                                
                                this.state.sections[0]?(
                                  <img
                                    src={`${IMAGES_BASE_URL}/public/uploads/actualites/${this.state.sections[0].path}`}
                                    width="100"
                                    className="img-fluid img-thumbnail "
                                    id={0}
                                    alt={this.state.title}
                                  />
                                ):""}

                    {/* {this.state.thumbs[0] && this.state.thumbs[0].length>0 && <img
          src={this.state.thumbs[0]}
          alt="preview"
          width="150"
          className="img-fluid img-thumbnail "
        />} */}
                    
                  {/* </CRow>  */}

          
                  </>
                  }
                 <br/><br/>
                  <CRow>
                    <CCol xs="8">
                      <CFormGroup>
                        <CLabel htmlFor="content">Lien youTube</CLabel>
                        <CInput
                          type="text"
                          name="youtubeLink"
                          onChange={this.handleChange}
                          value={this.state.youtubeLink}
                        />
                      </CFormGroup>
                    </CCol>
                  </CRow>

                  {localStorage.getItem('roles')=='ROLE_JOURNALIST' ? 
                   (
                    ""
                  )
                  :
                  (
                    <>
                      <CRow>
                        <CCol xs="8">
                          <CFormGroup variant="checkbox" className="checkbox">
                            <CInputCheckbox
                              checked={this.state.aLaUne}
                              onChange={this.handleChange}
                              value={this.state.aLaUne}
                              name="aLaUne"
                            />
                            <CLabel
                              variant="checkbox"
                              className="form-check-label"
                            >
                              Mettre à la une
                            </CLabel>
                          </CFormGroup>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol xs="8">
                          <CFormGroup variant="checkbox" className="checkbox">
                            <CInputCheckbox
                              checked={this.state.deux}
                              onChange={this.handleChange}
                              value={this.state.deux}
                              name="deux"
                            />
                            <CLabel
                              variant="checkbox"
                              className="form-check-label"
                            >
                              Mettre deux au milieu
                            </CLabel>
                          </CFormGroup>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol xs="8">
                          <CFormGroup variant="checkbox" className="checkbox">
                            <CInputCheckbox
                              checked={this.state.status}
                              onChange={this.handleChange}
                              value={this.state.status}
                              name="status"
                            />
                            <CLabel
                              variant="checkbox"
                              className="form-check-label"
                            >
                              Publié
                            </CLabel>
                          </CFormGroup>
                        </CCol>
                      </CRow>
                    </>
                  )}

                  <CRow>
                    <CCol xs="12" sm="3">
                      {!this.state.loading && (
                        <CButton color="primary" block type="submit">
                          Valider
                        </CButton>
                      )}
                      {this.state.loading && (
                        <CButton color="primary" block disabled>
                          <span>
                            <CSpinner color="default" size="sm" />
                          </span>

                          <span> Valider</span>
                        </CButton>
                      )}
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    );
  }
}

export default EditNews;

