import {
  CBadge,
    CAlert,
    CRow,
    CCol,
    CFormGroup,
    CLabel,
    CInput,
    CSelect,
    CButton,
    CSpinner,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CInputCheckbox
} from "@coreui/react";
import React, { useEffect, useState , useContext } from "react";
import axios from "axios";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import imageCompression from "browser-image-compression";
import { useHistory } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CIcon from "@coreui/icons-react";
import { IMAGES_BASE_URL, API_BASE_URL , tokenCookie , keyHeader , sizeAlert } from "src/config";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Basic , Scroll , Categori , Adresse , Contact , Extra , Imgs} from "src/components/myWidgets/formTitles";
import Loading from "src/components/myWidgets/loadingWidget";
import { getBadge , textBadge} from "src/actions";
import { addWatermark , addWatermarkToAlbum } from "src/actions";
import MemberContext from "../../members/MemberContext";
import { logoutLocal } from "src/components/middleware/auth";

const EditService = (props) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [price, setPrice] = useState(null);
    const [album, setAlbum] = useState([]);
    const [imgPrincipal, setImgPrincipal] = useState(null);
    const [success, setSuccess] = useState(false);
    const [description, setDescription] = useState("");
    const [loading, setLoading] = useState("");
    const [country, setCountry] = useState("");
    const [countries, setCountries] = useState([]);
    const [city, setCity] = useState("");
    const [cities, setCities] = useState([]);
    const [delegate, setDelegate] = useState("");
    const [delegates, setDelegates] = useState([]);
    const [locality, setLocality] = useState("");
    const [localities, setLocalities] = useState([]);
    const [types, setTypes] = useState([]);
    const [type, setType] = useState("");
    const [title, setTitle] = useState("");
    const [age, setAge] = useState("");
    const [category, setCategory] = useState("");
    const [categories, setCategories] = useState([]);
    const [annonce, setAnnonce] = useState(null);
  
    const [otherImages, setOtherImages] = useState([]);
    const [index, setIndex] = useState([]);
    const [item, setItem] = useState(null);
    const [modal, setModal] = useState(false);
    const [setErrorDel] = useState("");
    const [typeImage, setTypeImage] = useState(false);
    const [doneuploadingfile, setDoneuploadingfile] = useState(true);
    const [newPhone, setNewPhone] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [pImageThumb, setpImageThumb] = useState(null);
    const [oldImgP, setoldImgp] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [uploadingImage, setUploadingImage] = useState(false);
    const [id, setId] = useState(null);

    const [imgCount , setImgCount] = useState(0);
    const [imgCountA , setImgCountA] = useState(0);
    const [abortControllers, setAbortControllers] = useState({});
    const [removedfiles, setremovedfiles] = useState([]);
    const [hasChanged , sethasChanged] = useState(false);
    const {services , setServices} = useContext(MemberContext);

    useEffect(() => {

      if(annonce && !hasChanged &&
        (title != annonce.title || category!=annonce.categoryService.id || type!=annonce.typeService.id
      || country != annonce.country.id || city != annonce.city.id 
      || delegate != annonce.delegate.id || locality != annonce.locality.id
      || description != annonce.description || (!newPhone && phoneNumber=='' ) || (annonce.phoneNumber && phoneNumber != annonce.phoneNumber)
      || pImageThumb || imgCountA > 0  
      )
        )
      {
        sethasChanged(true);
        //console.log(' in use : ' , album.length)
      }

    },
   [title ,category , type , imgCountA , imgPrincipal , description  ,phoneNumber , newPhone , locality , delegate , city , country]
   );
   
    const history = useHistory();

      const handleSubmit = async (e) => {
          e.preventDefault();
         // //console.log('sethasChanged in submit : ' , hasChanged , album.length) ; return;
          setSubmitted(true);
          if (!doneuploadingfile) {
            alert("not done uploading files yet");
            setLoading(false);
      
            return false;
          } else {
        if (
            type  && category &&
            country && city && delegate && locality &&
            imgPrincipal &&
            (phoneNumber.length==0 || isValidPhoneNumber(phoneNumber))
           ) {
            setLoading(true)
            const watermarkedAlbum = await addWatermarkToAlbum(album);

            const formData = new FormData();
            formData.append("title", title);
   
            formData.append("description", description);
            formData.append("category", category);
       
            formData.append("locality", locality);
            formData.append("createdBy", tokenCookie);

            if(newPhone)  formData.append("phone", phoneNumber);
            if (pImageThumb) {
              const watermarkedP = await addWatermark(imgPrincipal);
              formData.append("imgPrincipal", watermarkedP);
              formData.append("newimgPrincipal", 'yes');
          }
          else{
            formData.append("newimgPrincipal", 'no');
          }
          for (let index = 0; index < watermarkedAlbum.length; index++) {
            formData.append("album[]", watermarkedAlbum[index]);       
            } 
      
            //console.log(formData.get("imgPrincipal"));
            axios
                .post(API_BASE_URL + "/annonce_services/edit/"+id, formData , keyHeader)
                .then((res) => {
                    //console.log(res);
                    if (res.data.status.toString() === "ok") {
                        setSuccess(true);
                        if(res.data.annonce) { 
                          const updatedAnnonces = [...services];
                          const elIndex = updatedAnnonces.findIndex(el => el.id === id);
                          if (elIndex !== -1) {
                            updatedAnnonces[elIndex] = res.data.annonce;
                            setServices(updatedAnnonces);
                          }
                        }
                        setTimeout(() => {
                          history.push("/members/profile/services");
                        }, 1500);
                    }
                    setLoading(false)
                })
                .catch((error) => {
                    //console.log(" errors : ", error);
                    setLoading(false)
                    if (error.response && error.response.status === 401){
                      logoutLocal();
                      window.location.replace("/members/login");
                    }
                });
        } else {
            window.scrollTo(0, 200);
        }



    }};

   // const { id } = props.match.params;
   useEffect(() => {

    if(props.location.state) {
    
      let result = props.location.state
      //console.log("--", result);
      
setId(result.id);
  //console.log('service', result)
  setTitle(result.title)
  if(result.phoneNumber)  setPhoneNumber(result.phoneNumber)
  setAge(result.age)
  setAnnonce(result)

  if(result.price){
  setPrice(result.price)
}
if(result.country){
  setCountry(result.country.id)
 // setCities(result.country.cities)
  setCity(result.city.id)
  //setDelegates(result.city.delegations)
  setDelegate(result.delegate.id)
 // setLocalities(result.delegate.localities)
  setLocality(result.locality.id)
}
setDescription(result.description)
if(result.typeService){
    setType(result.typeService.id)
   // setCategories(result.typeService.categoryServices)

}
if(result.categoryService){
    setCategory(result.categoryService.id)
}
       
  if (result.mainPicture) {
      setImgPrincipal(`${IMAGES_BASE_URL}/public/uploads/images/service/${result.mainPicture.name}`)
setoldImgp(true); // heeeeere   
    
  }
  if (result.imageServices) {
          setOtherImages(result.imageServices)
      }

      setIsLoaded(true);
    }  

      else {axios(`${API_BASE_URL}/service/show/${id}`)
          .then((res) => res.data)
          .then((result) => {
              //console.log('service', result)
              setTitle(result.title)
              if(result.phoneNumber)   setPhoneNumber(result.phoneNumber)
              setAge(result.age)
              setAnnonce(result)
              setIsLoaded(true);
              if(result.price){
              setPrice(result.price)
          }
            if(result.country){
              setCountry(result.country.id)
              //setCities(result.country.cities)
              setCity(result.city.id)
             // setDelegates(result.city.delegations)
              setDelegate(result.delegate.id)
             // setLocalities(result.delegate.localities)
              setLocality(result.locality.id)
            }
            setDescription(result.description)
            if(result.typeService){
                setType(result.typeService.id)
              //  setCategories(result.typeService.categoryServices)
   
            }
            if(result.categoryService){
                setCategory(result.categoryService.id)
            }
                   
              if (result.mainPicture) {
                  setImgPrincipal(`${IMAGES_BASE_URL}/public/uploads/images/service/${result.mainPicture.name}`)
        setoldImgp(true); // heeeeere   
                
              }
              if (result.imageServices) {
                      setOtherImages(result.imageServices)
                  }

          });}

  }, []);



    useEffect(() => {
        if (!localStorage.getItem("countries")) {
          axios(`${API_BASE_URL}/countries/all`)
            .then((res) => res.data)
            .then(
              (result) => {
                setCountries(result);
                localStorage.setItem("countries", JSON.stringify(result));
              },
              (error) => {}
            );
        } else {
          setCountries(JSON.parse(localStorage.getItem("countries")));

          if ( props.location.state.country.id !== "0") {
            var countriesF = [];
            countriesF = JSON.parse(localStorage.getItem("countries")).find((city) => city.id.toString() ==  props.location.state.country.id);
            setCities(countriesF.cities);
            if (props.location.state.city.id !== "0") {
              var citiesF = [];
              citiesF = countriesF.cities.find((delegate) => delegate.id.toString() == props.location.state.city.id);
              setDelegates(citiesF.delegations);
              //console.log('citiesF', citiesF);
            }
            if (props.location.state.delegate.id !== "0") {
              var delegatesF = [];
              delegatesF = citiesF.delegations.find((local) => local.id.toString() == props.location.state.delegate.id);
              setLocalities(delegatesF.localities);
              //console.log('delegatesF', delegatesF);
            }
          }
          
        }

      if (!localStorage.getItem("typeServices")) 
      {axios(`${API_BASE_URL}/typeServices/all`)
      .then((res) => res.data)
      .then(
        (result) => {
          //console.log('result555', result)
          setTypes(result);
          localStorage.setItem("typeServices", JSON.stringify(result));
        },
        (error) => {
          setError(error);
        }
      );
    } else {
      setTypes(JSON.parse(localStorage.getItem("typeServices")));

      var typesF = [];
      if (props.location.state.typeService.id != "0") {
        typesF = JSON.parse(localStorage.getItem("typeServices")).find((category) => category.id.toString() == props.location.state.typeService.id);
        setCategories(typesF.categoryServices);
  
        //console.log(typesF);
      }

    }
    }, []);

    const handleCountryChange = (e) => {
        setCountry(e.target.value);
        let id = e.target.value;
        var countriesF = [];
        //console.log("iddd", id)
        if (id !== "0") {
          countriesF = countries.find((city) => city.id.toString() === id);
          setCities(countriesF.cities);
          //console.log('countriesF', countriesF);
        } else {
          setCity([]);
          //  setModel("");
        }
      };
    
      const handleCityChange = (e) => {
        setCity(e.target.value);
        let id = e.target.value;
        var citiesF = [];
        //console.log("iddd", id)
        if (id !== "0") {
          citiesF = cities.find((delegate) => delegate.id.toString() === id);
          setDelegates(citiesF.delegations);
          //console.log('citiesF', citiesF);
        } else {
          setDelegate([]);
          //  setModel("");
        }
      };
      const handleDelegateChange = (e) => {
        setDelegate(e.target.value);
        let id = e.target.value;
        var delegatesF = [];
        //console.log("iddd", id)
        if (id !== "0") {
          delegatesF = delegates.find((local) => local.id.toString() === id);
          setLocalities(delegatesF.localities);
          //console.log('delegatesF', delegatesF);
        } else {
          setLocality([]);
          //  setModel("");
        }
      };
    


    const getUploadParams = ({ meta }) => {
        return { url: "https://httpbin.org/post" };
    };

    // called every time a file's `status` changes
    const handleChangeStatus = async ({ meta, file }, status) => {

      if (status === 'preparing' && meta.size > 5 * 1024 * 1024) {
        alert(sizeAlert);
        return;
    //file = null;
      }
      setDoneuploadingfile(false);
      let removedfilesx = removedfiles
  
      if (status === 'preparing' && !(meta.size > 5 * 1024 * 1024)) { setImgCountA(imgCountA+1)}     
      if (status.toString() === "done" && !(meta.size > 5 * 1024 * 1024)) {  
        let newalb = album;
        let options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
        };
       const abortController = new AbortController();
        const fileId = meta.id;
        const updatedAbortControllers = { ...abortControllers, [fileId]: abortController };
        setAbortControllers(updatedAbortControllers);
        options.signal = abortController.signal;
  
        try {
          file = await imageCompression(file, options);
        } catch (error) {
          //console.log('erro compress : ',error);
         // return;
        }
           if ((!removedfiles.includes(meta.id) && !removedfilesx.includes(meta.id)) && !(meta.size > 5 * 1024 * 1024)) 
           {
            newalb.push(file);
           // setAlbum(prevAlbum => [...prevAlbum, file]);
           setAlbum(newalb)
            setImgCount(prevCount => prevCount + 1);
      } else {
         //console.log("new album in done else");
      //console.log(newalb.length ,newalb);
     }
        setDoneuploadingfile(true);
      }
     if (status.toString() === "removed" && !(meta.size > 5 * 1024 * 1024)) {
      removedfilesx.push(meta.id);
      setremovedfiles(removedfiles)
    // setremovedfiles(prevA => [...prevA, meta.id]);
        const fileId = meta.id;
        if (abortControllers[fileId]) {
          abortControllers[fileId].abort();
          const updatedAbortControllers = { ...abortControllers };
          delete updatedAbortControllers[fileId];
          setAbortControllers(updatedAbortControllers);
        
        }
  
        let index = album.findIndex((obj) => obj.name === file.name);
        let newAlbum = album;
       { newAlbum.splice(index, 1);
       if(index>-1) {
       if(imgCount>0) setImgCount(newAlbum.length);}
        if(imgCountA>0) setImgCountA(imgCountA-1);
        setAlbum(newAlbum);
       }
        setDoneuploadingfile(true);
  
      } else  setDoneuploadingfile(true);
    };
        
    const handleFileSection = async ( event) => {
      setUploadingImage(true);
      
      let file = event.target.files[0];
      if (file.size > 5 * 1024 * 1024) {
        alert(sizeAlert);
        setUploadingImage(false);    
        event.target.value = '';
        return;
      }

      let options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
      };
      try {
        file = await imageCompression(file, options);
      } catch (error) {
        //console.log(error);
      }  
      setoldImgp(false) 
      setpImageThumb(URL.createObjectURL(file));
      setImgPrincipal(file);
      setUploadingImage(false);

    };

    const toggle = (id, type, i) => {
        setModal(!modal);
        setItem(id)
        setTypeImage(type)
        setIndex(i)
    };
    function deleteImageService(idIm, index) {
        //console.log("id", idIm, index);
        setDeleting(true); // heeeeere

        axios
            .delete(`${API_BASE_URL}/service_annonce/delete_image/` + idIm , keyHeader)
            .then((res) => {
                toggle();
                if (typeImage) {
                    setImgPrincipal(false);
          setoldImgp(false); // heeeeere

                }
                otherImages.splice(index, 1);
                setOtherImages(otherImages);
                if (res.data.status.toString() === "ok") {
                    setOtherImages((items) => items.filter((x) => x.idIm !== idIm));
                    //console.log('okkd', otherImages)
                }
                if (
                    res.data.status.toString() ===
                    "ForeignKeyConstraintViolationException"
                ) {
                    setErrorDel(
                        "Vous ne pouvez pas supprimer cette image"
                    );
                }
                setDeleting(false);
              })
              .catch((err) => {
                //console.log(err);
                setDeleting(false);})
    }

    const handleTypeChange = (e) => {
        setType(e.target.value);
    
        let id = e.target.value;
        var typesF = [];
        if (id !== "0") {
          typesF = types.find((category) => category.id.toString() === id);
          setCategories(typesF.categoryServices);
          
    
          //console.log(typesF);
        } else {
    
          setCategories([]);
          setCategory("");
    
    
        }
      };
      const handleCategoryChange = (e) => {
        setCategory(e.target.value);
    
      };

      const changePhoneNumber = (event) => {
        //console.log(event.target.checked);
        if(event.target.checked) {setNewPhone(false);setPhoneNumber("");}
        else {setNewPhone(true);
        }
      }    


    if (error) {
        return (
            <div className="container">
                <main className="l-main">
                    <div className="l-section l-section--archive">
                        <div>Erreur : {error.message}</div>
                    </div>
                </main>
            </div>
        );
    } else if (!isLoaded) {
        return (
   <Loading/>
        );
    } else {
        return (
          <div className="container addingPage editPage">
          <div className="information-title">
                <h2>modifier Service</h2> <CBadge color={getBadge(props.location.state.status)}>
                {textBadge(props.location.state.status)} 
                          </CBadge>
              <span className="snSpan snSpanE ">SN: {props.location.state.sn} </span> 
              </div>
               <form onSubmit={handleSubmit} id="form-add">

               <div className="advInfoSzctionCard" >
        <Basic/>
<CRow xs="12">
<CCol xs="6">
        <CFormGroup>
          <CLabel htmlFor="title">Titre</CLabel>
          <CInput
            type="text"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          ></CInput>
          {submitted && !title && (
            <span className="invalid-feedback error">
              Obligatoire
            </span>
          )}
        </CFormGroup>
      </CCol>

</CRow>
 <Scroll/>
         </div>

         <div className="advInfoSzctionCard" >
       <Categori/>
<CRow xs="12">



  <CCol >
    <CFormGroup>
      <CLabel htmlFor="type">Type </CLabel>
      <CSelect
        name="type"
        value={type}
        onChange={(e) => handleTypeChange(e)}
      >
        <option value="0"> Choisir le type</option>
        {types && types.map((type) => {
          return (
            <option key={type.id} value={type.id}>
              {type.label}
            </option>
          );
        })}
      </CSelect>


      {submitted && !type && (
        <span className="invalid-feedback error">
          Obligatoire
        </span>
      )}
    </CFormGroup>
  </CCol>

 

    <CCol >
      <CFormGroup>
        <CLabel htmlFor="category">Categorie</CLabel>
        <CSelect
          name="category"
          value={category}
          onChange={(e) => handleCategoryChange(e)}
        >
          <option value="0"> Choisir la categorie </option>
          {categories && categories.map((category) => {
            return (
              <option key={category.id} value={category.id}>
                {category.label}
              </option>
            );
          })}
        </CSelect>
        {submitted && !category && (
          <span className="invalid-feedback error">
            Obligatoire
          </span>
        )}
      </CFormGroup>
    </CCol>






</CRow>
 <Scroll/>
         </div>


         <div className="advInfoSzctionCard" >
          <Adresse/>
<CRow xs="12">
  <CCol xs="6">
    <CFormGroup>
      <CLabel htmlFor="country">Pays</CLabel>
      <CSelect
        name="country"
        value={country}
        onChange={(e) => handleCountryChange(e)}
      >
        <option value="0"> Choisissez un pays</option>
        {countries && countries.map((country) => {
          return (
            <option key={country.id} value={country.id}>
              {country.label}
            </option>
          );
        })}
      </CSelect>
      {submitted && !country && (
        <span className="invalid-feedback error">
          Obligatoire
        </span>
      )}
    </CFormGroup>
  </CCol>
  <CCol xs="6">
    <CFormGroup>
      <CLabel htmlFor="Région">Gouvernorat</CLabel>
      <CSelect
        name="city"
        value={city}
        onChange={(e) => handleCityChange(e)}
      >
        <option value="0"> Gouvernorat</option>
        {cities && cities.map((city) => {
          return (
            <option key={city.id} value={city.id}>
              {city.label}
            </option>
          );
        })}
      </CSelect>
      {submitted && !city && (
        <span className="invalid-feedback error">
          Obligatoire
        </span>
      )}
    </CFormGroup>
  </CCol>
</CRow>
<CRow xs="12">
  <CCol xs="6">
    <CFormGroup>
      <CLabel htmlFor="delegate">Délégation</CLabel>
      <CSelect
        name="delegate"
        value={delegate}
        onChange={(e) => handleDelegateChange(e)}
      >
        <option value="0"> Choisissez une délégation</option>
        {delegates && delegates.map((delegate) => {
          return (
            <option key={delegate.id} value={delegate.id}>
              {delegate.label}
            </option>
          );
        })}
      </CSelect>
      {submitted && !delegate && (
        <span className="invalid-feedback error">
          Obligatoire
        </span>
      )}
    </CFormGroup>
  </CCol>
  <CCol xs="6">
    <CFormGroup>
      <CLabel htmlFor="loclité">Localité</CLabel>
      <CSelect
        name="locality"
        value={locality}
        onChange={(e) => setLocality(e.target.value)}
      >
        <option value="0"> Localité</option>
        {localities && localities.map((local) => {
          return (
            <option key={local.id} value={local.id}>
              {local.label}
            </option>
          );
        })}
      </CSelect>
      {submitted && !locality && (
        <span className="invalid-feedback error">
          Obligatoire
        </span>
      )}
    </CFormGroup>
  </CCol>
</CRow>
 <Scroll/>
         </div>

         <div className="advInfoSzctionCard" >
       <Contact/>
<CRow xs="12">
            <CCol xs="6">
              {newPhone && <CFormGroup>
                <CLabel htmlFor="phoneNumber">Numéro téléphone</CLabel>
                <PhoneInput
                  type="text"
                  onChange={(value) => setPhoneNumber(value)}
                  placeholder="Entrez un numéro de téléphone"
                  defaultCountry="TN"
                  international
                  name="phoneNumber"
                  id="phoneNumber"
                  value={phoneNumber}
                />
                  {submitted && newPhone && phoneNumber.length>0 && !isValidPhoneNumber(phoneNumber) && (
                  <span className="invalid-feedback error">Ce numéro de téléphone n'est pas valide</span>
                )}
              </CFormGroup>}
              <CFormGroup variant="checkbox" className="checkbox">
                <CInputCheckbox
                  onChange={changePhoneNumber}                 
                />
                <CLabel variant="checkbox" className="form-check-label">
                Utilisez le numéro téléphone de votre compte
                </CLabel>
              </CFormGroup> 
            </CCol>
            </CRow>
            <Scroll/>
         </div>

         <div className="advInfoSzctionCard" >
     <Extra/>
<CRow>
  <CCol>
    <CFormGroup>
      <CKEditor
        editor={ClassicEditor}
        data={annonce.description}
        onChange={(event, editor) => {
          const data = editor.getData();
          setDescription(data)
        }}
      />


    </CFormGroup>
  </CCol>
</CRow>
   <Scroll/>
         </div>

         <div className="advInfoSzctionCard" >

<Imgs/>

          <CRow>
            <CCol>
            <CFormGroup style={{display:"inline-block"}}>
                <CLabel htmlFor="pImage">Principal image</CLabel>
                <input 
                          type="file"
                          name="pImage"
                          onChange={handleFileSection}
                          accept="image/*"
                           ></input>
            </CFormGroup>
            {uploadingImage ?
            <span style={{height:"fit-content",marginLeft:"10px",lineHeight:"90px"}}>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span>
       : !uploadingImage && oldImgP ? 
        <img style={{height: "85px", width: "auto"}}
          src={imgPrincipal}
          alt="preview"
          className="img-fluid img-thumbnail "
        />
       : !uploadingImage && pImageThumb ? 
        <img style={{height: "85px", width: "auto"}}
          src={pImageThumb}
          alt="preview"
          className="img-fluid img-thumbnail "
        /> : ''}
    
           {submitted && !imgPrincipal && (
                <span className="invalid-feedback error">
                  Veuillez uploader une image
                </span>
              )}
            </CCol>
          </CRow><br/>


          {otherImages.length > 0 && 
            <>
              <CLabel htmlFor="pImage">Autres images</CLabel>
                              {otherImages.map((other, i) => {

                                  return (

                                      <span key={i} className="img-thumbnail-others">
                                          {!other.principal ?
                                              <>

                                                  <img
                                                    src={`${IMAGES_BASE_URL}/public/uploads/images/service/${other.name}`}
                                                      width="100"
                                                      className="img-fluid img-thumbnail "
                                                      alt=""
                                                  />
                                                  <CButton onClick={(e) => toggle(other.id, false, i)}><CIcon name="cilTrash" /></CButton>

                                              </>

                                              : null}
                                      </span>

                                  );

                              })
                              }
                              </>
           }
      <br/><br/>
      {imgCountA>0 && <> <div className="imgCounter"> {imgCount} {imgCount > 1 ? ' images téléchargées sur ' :' image téléchargée sur ' }  {imgCountA} </div>
       <div className="loading-bar">
          <div className="loading-bar-fill" style={{ width: `${imgCountA==0?0 :(imgCount / imgCountA) * 100}%` }}></div>
        </div><br/></>}
          <CLabel >ajouter des images</CLabel> 
                      <CRow>
                          <CCol>
                              <Dropzone
                                 // getUploadParams={getUploadParams}
                                  onChangeStatus={handleChangeStatus}
                                  // onSubmit={handleSubmitF}
                                  accept="image/*"
                                  maxFiles={10 - otherImages.length}
                                  maxSizeBytes={5 * 1024 * 1024}
                              >
                                  <input />
                              </Dropzone>
                           </CCol>
                      </CRow>
                      <br />
                      </div>
                      {success && (
                          <CAlert
                              show={true}
                              // autohide="5000"
                              //autohide={false}
                              placement="top-end"
                              color="success"
                          >
                              {`L'article a été modifiée avec succès !`}
                          </CAlert>
                      )}

                      <CRow>
                          <CCol md="3" className="offset-9">
                          {
       ((!(doneuploadingfile && imgCountA == album.length)) || uploadingImage)?
               (
              <CButton color="primary" block disabled>
                <span>
                  {" "}
                  <CSpinner color="default" size="sm" />
                </span>

                <span> chargement des images</span>
              </CButton>
            )

       :  loading ? (
              <CButton color="primary" block disabled>
                <span>
                  {" "}
                  <CSpinner color="default" size="sm" />
                </span>

                <span> Valider</span>
              </CButton>
            )  
            :
              <CButton color="primary" block 
              disabled={!hasChanged}
               type="submit">
                Valider
              </CButton>
            
}

                          </CCol>
                      </CRow>
<br/>



</form>

                <CModal show={modal} onClose={toggle}>
                              <CModalHeader closeButton></CModalHeader>
                              <CModalBody>
                              voulez vous vraiment supprimer cette image?
                              
                              </CModalBody>
                              <CModalFooter>
                                <CButton
                                  color="primary"
                                  onClick={() => {
                                    deleteImageService(item,index);
                                  }}
                                >
                                  Oui
                                </CButton>

                                <CButton color="secondary" onClick={toggle}>
                                  Cancel
                                </CButton>
                              </CModalFooter>
                            </CModal>
            </div>
        );
    }
}
export default EditService
