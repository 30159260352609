import React, { useEffect, useState } from "react";

import { API_BASE_URL, IMAGES_BASE_URL , keyHeader , tokenCookie} from "../../../config";
import axios from "axios";
import Moment from 'react-moment';
import Loading from "src/components/myWidgets/loadingWidget";
import { Link } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CSpinner,
  CRow,
  CCol,
  CAlert,
  CTextarea,
  CFormGroup , CDataTable
} from "@coreui/react";
import { logoutLocal } from "src/components/middleware/auth";

function MyApps() {
  const [error, setError] = useState(null);
  const [erroru, setErroru] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalU, setModalU] = useState(false);
  const [success, setSuccess] = useState(false);
  const [item, setItem] = useState(null);
  const [errorDel, setErrorDel] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingu, setIsLoadingu] = useState(false);
  const [msg, setMsg] = useState('');

  const toggle = (id) => {
    setModal(!modal);
    setItem(id);
  };
  const toggleU = (id , m) => {
    setModalU(!modalU);
    setItem(id);
    setMsg(m);
    setSuccess(false);
    setErroru(false)
  };

  function deleteItem(id) {
    setIsLoading(true);

    axios
      .delete(`${API_BASE_URL}/job/delete/${id}`,keyHeader)
      .then((res) => {
        toggle();
        //console.log(res);
        if (res.data.status.toString() === "ok") {
          setItems((items) => items.filter((x) => x.id !== id));
          setIsLoading(false);
        }
        if (
          res.data.status.toString() ===
          "ForeignKeyConstraintViolationException"
        ) {
          setErrorDel("Vous ne pouvez pas supprimer ce animal");
          setIsLoading(false);
        }
      })
      .catch((err) =>{
        //console.log(err);
        setErrorDel("Vous ne pouvez pas supprimer cet bon plans maintenant");
      setIsLoading(false);} );
  }

  function updateApp() {
    setIsLoadingu(true);

    const formData = new FormData();
    formData.append("msg", msg);
    formData.append("id", item);
    formData.append("user", tokenCookie);
    axios
      .post(API_BASE_URL + "/job/updateJobApp", formData , keyHeader)
      .then((res) => {
        //console.log(res);
        if (res.data.status === "ok") {
          setIsLoadingu(false);
          setSuccess(true);
         let objIndex = items.findIndex(obj => obj.id == item);
         items[objIndex].message = msg ;
          setTimeout(() => {
            setModalU(false);
            setSuccess(false);
          }, 2000);
        } else {  setErroru(true)
          setIsLoadingu(false);}
      })
      .catch((err) =>{
        //console.log(err);
        setErroru(true)
      setIsLoadingu(false);
      if (error.response && error.response.status === 401){
        logoutLocal();
        window.location.replace("/members/login");
      }
    } );
  }

  useEffect(() => {

    const formData = new FormData();
    formData.append("key",tokenCookie );

        axios.post(`${API_BASE_URL}/getJobApplications`, formData, keyHeader)
        .then(result => {
          //console.log('res appsssss ',result);
          setIsLoaded(true);
          setItems(result.data);
         
        })
        .catch(error => {
          setIsLoaded(true);
          setError(error);
            //console.log('error --- ', error)
  
        });

  }, []);

  const fields = [
    {
      key: "Titre",
      label: "Titre",
    },
    {
      key: "Message",
      label: "Message",
    },
    {
      key: "Actions",
      label: "Actions",
    },
  ];
  
  if (error) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div>Erreur : {error.message}</div>
          </div>
        </main>
      </div>
    );
  } else if (!isLoaded) {
    return (
  <Loading/>
    );
  } else if (!items.length) {
    return (
      <div className="container">
      <main className="l-main">
        <div className="l-section l-section--archive">
          <div className="noItemsText">
          vous n'avez pas encore des candidatures<br/><br/>
          <Link to='/jobs/sectors'>
          <button className="ml-1 btn btn-info" type="button">
          commencez à parcourir les offres d'emploi disponibles
          </button></Link>
          </div>
        </div>
      </main>
    </div>

    )} 
  else {
    return (
        <>


              <CDataTable addTableClasses="table table-hover table-outline mb-0  d-sm-table tableWithHead"
                    items={items}
                    fields={fields}
                    itemsPerPageSelect
                    itemsPerPage={10}
                    hover
                    sorter
                    pagination
                    scopedSlots={{
                      Titre: (job) => (
                        <td>
                    <div className="profileAnnonceCardDetails">
                    <strong>
                    <div>
                    {job.jobOffer.title} 
                    </div>
                   </strong>
                    <div>
                     <strong>à {" "}</strong> 
                     <span><Moment format="DD/MM/YYYY">
                     {job.createdAt}
                      </Moment></span> 
                    </div>
                    </div>
                  </td>
                      ),
                      Message: (job) => (
                        <td  style={{verticalAlign:'middle'}}>
                   {job.message}    
                          
                   </td>
                      ),
                      Actions: (job) => (
                        <td>
                   
                      <CButton      onClick={(e) => toggleU(job.id,job.message)}>
                      <CIcon name="cilPencil" /> 
                      </CButton>
         
                    {/* <CButton
                      onClick={(e) => toggle(job.id)}
                    >
                      <CIcon name="cilTrash" />
                    </CButton> */}
                  </td>
                      )
                    }}
                  />

              <CModal show={modal} onClose={toggle}>
          <CModalHeader closeButton></CModalHeader>
          <CModalBody>Voulez-vous vraiment supprimer ce job?</CModalBody>
          <CModalFooter>
            {/* <CButton
                                  color="primary"
                                  onClick={() => {
                                    deleteAnimal(item);
                                  }}
                                >
                                  Oui
                                </CButton> */}

            {!isLoading && (
              <CButton
                color="primary"
                onClick={() => {
                  deleteItem(item);
                }}
              >
                Oui
              </CButton>
            )}
            {isLoading && (
              <CButton color="primary" disabled>
                <span>
                  <CSpinner color="default" size="sm" />
                </span>

                <span> Oui</span>
              </CButton>
            )}

            <CButton color="secondary" onClick={toggle}>
              Cancel
            </CButton>
          </CModalFooter>
        </CModal>

        <CModal show={modalU} onClose={toggleU}>
          <CModalHeader closeButton>Modifiez Votre Message</CModalHeader>
          <CModalBody>
                <CFormGroup row>
                  <CCol xs="12" md="12">
                    <CTextarea
                      name="message"
                      value={msg}
                      onChange={(e) => setMsg(e.target.value)}
                    />
                  </CCol>
                </CFormGroup>  
           

          </CModalBody>
          <CModalFooter>
          { !isLoadingu ? (
                      <CButton color="primary"  onClick={updateApp}>
                        Valider
                      </CButton>
                    )
                    :isLoadingu ? (
                      <CButton color="primary"  disabled>
                        <span>
                          {" "}
                          <CSpinner color="default" size="sm" />
                        </span>

                        <span> Valider</span>
                      </CButton>
                    )
                    : null
                    }

            <CButton color="secondary" onClick={toggleU}>
              Cancel
            </CButton>

            {success ? (
                    <CAlert
                      style={{
                        textAlign: "center",
                        width: "100%",
                        marginTop: "10px",
                      }}
                      color="success"
                      closeButton
                    >
                   Votre message a été modifié avec succès
                    </CAlert>
                  ) 
                  : erroru ? (<CAlert
                      style={{
                        textAlign: "center",
                        width: "100%",
                        marginTop: "10px",
                      }}
                      color="danger"
                      closeButton
                    >
                   Error !
                    </CAlert>)
                  :(
                    ""
                  )}

          </CModalFooter>
        </CModal>
            
        </>
    )
  }
}

export default MyApps
