import React, { useEffect, useState } from "react";
import { API_BASE_URL , IMAGES_BASE_URL , tokenU } from "../../../config";
import {
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CButton,
  CCollapse,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CAlert,
  CSpinner
} from "@coreui/react";
import Moment from "react-moment";
import axios from "axios";

const getBadge = (status) => {
  switch (status) {
    case "1":
      return "success";
    case "0":
      return "secondary";
    case "2":
      return "danger";
    default:
      return "primary";
  }
};

export default function Members() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const [errorDel, setErrorDel] = useState("");

  const toggle = () => {
    setModal(!modal);
  };
  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };

  function toggleUser(id , s) {
    setLoading(true);

  // return;
  
    const formData = new FormData();
    formData.append("token", tokenU); 
    formData.append("id", id); 
    axios
      .post(`${API_BASE_URL}/members/disable`, formData)
      .then((res) => {
        console.log('resss',res);
        if (res.data.status.toString() === "ok") {
          let temp = items;
          let index =  items.findIndex(obj => obj.id === id)
         temp[index].active = s ;
          setItems(temp);
          toggle();
          setLoading(false);
        }
        else {
          setErrorDel("Vous ne pouvez pas modifier ce membre");
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log('restfjgss',err);
        setErrorDel("Vous ne pouvez pas modifier ce membre");
        setLoading(false)});
  }

  const fields = [
    {
      key: "avatar",
      label: "avatar",
    },
    {
      key: "id",
      label: "id",
    },
    { key: "nom" },
    { key: "email" },
    { key: "phoneNumber" ,
    label: "téléphone"
    },
    { key: "accountType" ,
    label: "Type Compte"
    },
    { key: "createdAt" ,
    label: "date d'inscription"
    },
    { key: "active" ,
    label: "Statut"
    },
    {
      key: "Actions",
      label: "Actions",
      sorter: false,
      filter: false,
    },
  ];
  // Remarque : le tableau vide de dépendances [] indique
  // que useEffect ne s’exécutera qu’une fois, un peu comme
  // componentDidMount()
  useEffect(() => {

      const formData = new FormData();
      formData.append("token", tokenU); 
      axios
      .post(`${API_BASE_URL}/members`,formData)
      .then((res) => {
        console.log('resss',res);
        setIsLoaded(true);
         setItems(res.data);

      })
      .catch((err) =>
      { 
        console.log('tdfgyhj',err);
        setError(err)
       
      }
        );
  }, []);

  if (error) {
    return <div>Erreur : {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Chargement...</div>;
  } else {
    return (
      <div>
        <CRow className="annonceTableDashboard membersTableDashboard">
          <CCol xl={12}>
     

            <p></p>
            <CCard>
              <CCardHeader>Membres</CCardHeader>
              <CCardBody>
              {errorDel !== "" ? (
                  <CAlert color="danger" closeButton>
                    {errorDel}
                  </CAlert>
                ) : (
                  ""
                )}
               { items && <CDataTable
                  items={items}
                  fields={fields}
                  columnFilter
                  tableFilter
                  footer
                  itemsPerPageSelect
                  itemsPerPage={10}
                  hover
                  sorter
                  pagination
                  scopedSlots={{
                    avatar: (item) => (
                        <td className="titleTdNewsTableDashboard titleTdNewsTableDashboard1">
                        <img
                          width="100"
                         className="img-fluid img-thumbnail "
                         src={
                          item.path
                            ? `${IMAGES_BASE_URL}/public/uploads/members/${item.path}`
                            : `${IMAGES_BASE_URL}/public/uploads/members/default_avatar.jpg`
                        }
                        alt={item.prenom && item.prenom}
                        />
                        </td>
                      ),
                    nom: (item) => (
                      <td>
                          {item.prenom && item.prenom}  {item.nom && item.nom}
                      </td>
                      
                    ),
                    accountType: (item) => (
                      <td className="typeCompte">
                          {item.accountType.label.replace('compte','')}
                      </td>
                      
                    ),
                    createdAt: (item) => (
                        <td>
                      <Moment format="DD/MM/YYYY">
                        {item.createdAt}
                      </Moment>
                        </td>
                      ),
                    active: (item) => (
                      <td>
                        {item.active && <CBadge color={getBadge(item.active)}>
                          {item.active=='1'?'Active' :item.active=='2'?'Suspendu': 'en attente' }
                        </CBadge>}
                      </td>
                    ),
                    Actions: (item, index) => {
                      return (
                        <td className="py-2">
                          <CButton
                            color="primary"
                            variant="outline"
                            shape="square"
                            size="sm"
                            onClick={() => {
                              toggleDetails(index);
                            }}
                          >
                            {details.includes(index) ? "Hide" : "Show"}
                          </CButton>
                        </td>
                      );
                    },
                    details: (item, index) => {
                      return (
                        <CCollapse show={details.includes(index)}>
                          <CCardBody>
                            <h4 className="h4MemberTitle">{item.prenom && item.prenom}  {item.nom && item.nom}</h4>

                            <CButton size="sm" color="danger" className="ml-1" onClick={toggle}>
                           {item.active!='2'?'Désactiver' : 'Activer'} 
                            </CButton>
                            {item.accountType.id == 2 &&     
                            <div className="companyDetails">
                            <div>
                            <span>Les documents de la société : </span>
                               {item.others ? <a
                              href={`/uploads/candidatures/${item.others}`}
                    
                            >
                              {item.others}
                            </a> : ' -'}
                            </div>
                            <div> 
                            <span>Website :  </span>
                               {item.website ? <a
                              href={item.website}
                    
                            >
                              {item.website}
                            </a> : ' -'}
                            </div>
                            <div>
                            <span>Matricule :  </span>
                               {item.matricule ? 
                              item.matricule
                            : ' -'}
                            </div>
                            <div>
                            <span> Person in charge :  </span>
                               {item.person ? 
                              item.person
                            : ' -'}
                            </div>
                            <div>
                            <span> Adresse :  </span>
                               {item.adresse ? 
                              item.adresse
                            : ' -'}
                            </div>
                         
                            </div>
                           }
                            <CModal show={modal} onClose={toggle}>
                              <CModalHeader closeButton></CModalHeader>
                              <CModalBody>
                                Voulez vous vraiment désactiver cet utilisateur ?
                              </CModalBody>
                              <CModalFooter>
                                <CButton
                                  color="primary"
                                  disabled={loading}
                                  onClick={() => {
                                   const s = item.active =='2'?'1':'2';
                                    toggleUser(item.id , s );
                                    toggleDetails(index);
                                  }}
                                >
                                 {loading && <CSpinner size="sm"/>} Oui
                                </CButton>

                                <CButton color="secondary"   disabled={loading} onClick={toggle}>
                                {loading && <CSpinner size="sm"/>} Cancel
                                </CButton>
                              </CModalFooter>
                            </CModal>
                          </CCardBody>
                        </CCollapse>
                      );
                    },
                  }}
                />
                }
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </div>
    );
  }
}
