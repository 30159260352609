
import React, { useState, useEffect } from "react";
import {
  CAlert,
  CRow,
  CCol,
  CFormGroup,
  CLabel,
  CInput,
  CSelect,
  CButton,
  CInputRadio,
  CSpinner,
  CInputCheckbox
} from "@coreui/react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { API_BASE_URL, tokenCookie , keyHeader , sizeAlert } from "../../../../config";
import axios from "axios";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import imageCompression from "browser-image-compression";
import { useHistory } from "react-router-dom";
import { Basic , Scroll , Categori , Adresse , Contact , Extra , Imgs} from "src/components/myWidgets/formTitles";
import Loading from "src/components/myWidgets/loadingWidget";
import { addWatermark , addWatermarkToAlbum } from "src/actions";
import { logoutLocal } from "src/components/middleware/auth";

const BikeForm = () => {
  const [error, setError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoaded2, setIsLoaded2] = useState(false);
  const [isLoaded3, setIsLoaded3] = useState(false);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [brandsAll, setBrandsAll] = useState([]);
  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [createdBy] = tokenCookie;
  const [frame, setFrame] = useState("");
  const [frameHeight, setFrameHeight] = useState("");
  const [material, setMaterial] = useState("");
  const [wheelSize, setWheelSize] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [breaking, setBreaking] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [album, setAlbum] = useState([]);
  const [imgPrincipal, setImgPrincipal] = useState(null);
  const [success, setSuccess] = useState(false);
  const [year, setYear] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [delegate, setDelegate] = useState("");
  const [delegates, setDelegates] = useState([]);
  const [locality, setLocality] = useState("");
  const [localities, setLocalities] = useState([]);
  const [doneuploadingfile, setDoneuploadingfile] = useState(true);
  const [newPhone, setNewPhone] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [pImageThumb, setpImageThumb] = useState(null);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [warranty, setWarranty] = useState("");
  
  const [imgCount , setImgCount] = useState(0);
  const [imgCountA , setImgCountA] = useState(0);
  const [abortControllers, setAbortControllers] = useState({});
  const [removedfiles, setremovedfiles] = useState([]);

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
      if (!doneuploadingfile) {
        alert("not done uploading files yet");
        setLoading(false);
        return false;
      } else {
        setSubmitted(true);
        //console.log("album", album);
        if (brand && category && warranty && frame && price && delegate && locality && imgPrincipal &&            
          (phoneNumber.length==0 || isValidPhoneNumber(phoneNumber)))
           {
          setLoading(true);
          
          const watermarkedAlbum = await addWatermarkToAlbum(album);
          const watermarkedP = await addWatermark(imgPrincipal);
          const formData = new FormData();
          formData.append("state", status);
          formData.append("category", category);

          formData.append("brand", brand);
          formData.append("createdBy", tokenCookie);
          formData.append("year", year);
          formData.append("locality", locality);
          formData.append("warranty", warranty);

          formData.append("price", price);
          formData.append("braking", breaking);
          formData.append("frame", frame);
          formData.append("description", description);
          formData.append("material", material);

          formData.append("wheelSize", wheelSize);
          formData.append("frameHeight", frameHeight);
          if(newPhone)  formData.append("phone", phoneNumber);
          formData.append("imgPrincipal", watermarkedP);
          for (let index = 0; index < watermarkedAlbum.length; index++) {
            formData.append("album[]", watermarkedAlbum[index]);
          }
          //console.log("category", category);
          axios
            .post(API_BASE_URL + "/annonce_bike/add", formData , keyHeader)
            .then((res) => {
              setLoading(false);
              //console.log(res);
              if (res.data.status.toString() === "ok") {
                setSuccess(true);

                history.push( "/success/ok" , { from: 'add' } );
              } 
            })
            .catch((error) => {
              //console.log(" errors : ", error);
              setLoading(false);
              if (error.response && error.response.status === 401){
                logoutLocal();
                window.location.replace("/members/login");
              }
            });
        } else {
          window.scrollTo(0, 200);
          setLoading(false);
        }
      }
    
      
  };
  const getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = async ({ meta, file }, status) => {

    if (status === 'preparing' && meta.size > 5 * 1024 * 1024) {
      alert(sizeAlert);
      return;
  //file = null;
    }
    setDoneuploadingfile(false);
    let removedfilesx = removedfiles

    if (status === 'preparing' && !(meta.size > 5 * 1024 * 1024)) { setImgCountA(imgCountA+1)}     
    if (status.toString() === "done" && !(meta.size > 5 * 1024 * 1024)) {  
      let newalb = album;
      let options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
      };
     const abortController = new AbortController();
      const fileId = meta.id;
      const updatedAbortControllers = { ...abortControllers, [fileId]: abortController };
      setAbortControllers(updatedAbortControllers);
      options.signal = abortController.signal;

      try {
        file = await imageCompression(file, options);
      } catch (error) {
        //console.log('erro compress : ',error);
       // return;
      }
         if ((!removedfiles.includes(meta.id) && !removedfilesx.includes(meta.id)) && !(meta.size > 5 * 1024 * 1024)) 
         {
          newalb.push(file);
         // setAlbum(prevAlbum => [...prevAlbum, file]);
         setAlbum(newalb)
          setImgCount(prevCount => prevCount + 1);
    } else {
       //console.log("new album in done else");
    //console.log(newalb.length ,newalb);
   }
      setDoneuploadingfile(true);
    }
   if (status.toString() === "removed" && !(meta.size > 5 * 1024 * 1024)) {
    removedfilesx.push(meta.id);
    setremovedfiles(removedfiles)
  // setremovedfiles(prevA => [...prevA, meta.id]);
      const fileId = meta.id;
      if (abortControllers[fileId]) {
        abortControllers[fileId].abort();
        const updatedAbortControllers = { ...abortControllers };
        delete updatedAbortControllers[fileId];
        setAbortControllers(updatedAbortControllers);
      
      }

      let index = album.findIndex((obj) => obj.name === file.name);
      let newAlbum = album;
     { newAlbum.splice(index, 1);
     if(index>-1) {
     if(imgCount>0) setImgCount(newAlbum.length);}
      if(imgCountA>0) setImgCountA(imgCountA-1);
      setAlbum(newAlbum);
     }
      setDoneuploadingfile(true);

    } else  setDoneuploadingfile(true);
  };

  const handleFileSection = async ( event) => {
    setUploadingImage(true);
    let file = event.target.files[0];
    if (file.size > 5 * 1024 * 1024) {
      alert(sizeAlert);
      setpImageThumb(null);
      setImgPrincipal(null);
      setUploadingImage(false);    
      event.target.value = '';
      return;
    }

    let options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
    };
    try {
      file = await imageCompression(file, options);

    } catch (error) {
      //console.log(error);
    }  
    setpImageThumb(URL.createObjectURL(file));
    setImgPrincipal(file);
    setUploadingImage(false);   
  }; 

  useEffect(() => {
    
    if (!localStorage.getItem("bikeCategories")) {
      axios(`${API_BASE_URL}/bike/categories`)
      .then((res) => res.data)
      .then(
        (result) => {
          setIsLoaded(true);
          setCategories(result);
          //  console.log(result);
          localStorage.setItem("bikeCategories", JSON.stringify(result));
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  } else {
    setCategories(JSON.parse(localStorage.getItem("bikeCategories")));
    setIsLoaded(true);
  }

  if (!localStorage.getItem("bikeBrands")){ 
  axios(`${API_BASE_URL}/bike/brands`)
      .then((res) => res.data)
      .then(
        (result) => {
          setBrandsAll(result);
          setBrands(result);
          setIsLoaded2(true);
          // console.log(result);
          localStorage.setItem("bikeBrands", JSON.stringify(result));         
        },
        (error) => {
          setError(error);
          setIsLoaded2(true);
        }
      );
      } else {
        setBrandsAll(JSON.parse(localStorage.getItem("bikeBrands")));
        setBrands(JSON.parse(localStorage.getItem("bikeBrands")));
        setIsLoaded2(true);
      }

    if (!localStorage.getItem("countries")) {
      axios(`${API_BASE_URL}/countries/all`)
        .then((res) => res.data)
        .then(
          (result) => {
            // console.log("countries", result);
            setCountries(result);
            localStorage.setItem("countries", JSON.stringify(result));
          },
          (error) => {}
        );
    } else {
      // console.log("countris2222222");
      //  console.log(localStorage.getItem("countries"));
      setCountries(JSON.parse(localStorage.getItem("countries")));
    }
  }, [countries.id]);

  const getYear = () => {
    const years = [];
    for (let i = new Date().getFullYear(); i > 1950; i--) {
      years.push(
        <option value={i} key={i}>
          {" "}
          {i}
        </option>
      );
    }
    return years;
  };

  const handleCategoryChange = (i) => {
    setCategory(i);
    if(i == 12) {
      var brandsF = [];
      brandsF = brandsAll.filter((el) => el.category && el.category.id == 12);
      //console.log("brandsF in 12 <<<", brandsF);
      setBrands(brandsF);
      setBrand("");
    } 
    else {
      var brandsF = [];
      brandsF = brandsAll.filter((el) => !el.category );
      //console.log("brandsF <<<", brandsF);
      setBrands(brandsF);
      setBrand("");
    }
  }

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    let id = e.target.value;
    var countriesF = [];
    //console.log("iddd", id);
    if (id !== "0") {
      countriesF = countries.find((city) => city.id.toString() === id);
      setCities(countriesF.cities);
      //console.log("countriesF", countriesF);
    } else {
      setCity([]);
      //  setModel("");
    }
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
    let id = e.target.value;
    var citiesF = [];
    //console.log("iddd", id);
    if (id !== "0") {
      citiesF = cities.find((delegate) => delegate.id.toString() === id);
      setDelegates(citiesF.delegations);
      //console.log("citiesF", citiesF);
    } else {
      setDelegate([]);
      //  setModel("");
    }
  };

  const handleDelegateChange = (e) => {
    setDelegate(e.target.value);
    let id = e.target.value;
    var delegatesF = [];
    //console.log("iddd", id);
    if (id !== "0") {
      delegatesF = delegates.find((local) => local.id.toString() === id);
      setLocalities(delegatesF.localities);
      //console.log("delegatesF", delegatesF);
    } else {
      setLocality([]);
      //  setModel("");
    }
  };

  const changePhoneNumber = (event) => {
    //console.log(event.target.checked);
    if(event.target.checked) {setNewPhone(false);setPhoneNumber("");}
    else {setNewPhone(true);
    }
  }



  if (error) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div>Erreur : {error.message}</div>
          </div>
        </main>
      </div>
    );
  } else if (!(isLoaded && isLoaded2)) {
    return (
     <Loading/>
    );
  } else {
    return (
      <>
        <form onSubmit={handleSubmit} id="form-add">
          {/* <div className="information-title">
            <h2>Vélos</h2>
          </div> */}

          <div className="advInfoSzctionCard" >
        <Categori name='Vélos'/>
          <CRow className='stateCheckBoxForm'>
            <CCol>
              {" "}
              <CLabel>Etat </CLabel>
              <CFormGroup variant="checkbox" inline>
                <CInputRadio
                  className="form-check-input"
                  id="1"
                  name="status"
                  value="1"
                  onChange={(e) => setStatus(e.target.value)}
                />
                <CLabel variant="checkbox" htmlFor="neuf">
                  Neuf
                </CLabel>
              </CFormGroup>
              <CFormGroup variant="checkbox" inline>
                <CInputRadio
                  className="form-check-input"
                  id="0"
                  name="status"
                  value="0"
                  onChange={(e) => setStatus(e.target.value)}
                />
                <CLabel variant="checkbox" htmlFor="occasion">
                  Occasion
                </CLabel>
              </CFormGroup>
              {submitted && !status && (
                <span className="invalid-feedback error">
                  Veuillez choisir l'état de la voiture
                </span>
              )}
            </CCol>
          </CRow>  <br />
          <CRow>
            <CCol md="4">
              <CFormGroup>
                <CLabel htmlFor="category">Catégorie</CLabel>
                <CSelect
                  name="category"
                  value={category}
                  onChange={(e) => handleCategoryChange(e.target.value)}
                >
                  <option value=""> Choisir la catégorie</option>
                  {categories.map((category) => {
                    return (
                      <option key={category.id} value={category.id}>
                        {category.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !category && (
                  <span className="invalid-feedback error">
                    Veuillez choisir la catégorie du vélo
                  </span>
                )}
              </CFormGroup>
            </CCol>
            <CCol md="4">
              <CFormGroup>
                <CLabel htmlFor="model">Marque</CLabel>
                <CSelect
                  name="brand"
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                >
                  <option value="0"> Marque</option>
                  {brands.map((brand) => {
                    return (
                      <option key={brand.id} value={brand.id}>
                        {brand.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !brand && (
                  <span className="invalid-feedback error">
                    Veuillez choisir la marque du vélo
                  </span>
                )}
              </CFormGroup>
            </CCol>

            <CCol md="4">
              <CFormGroup>
                <CLabel htmlFor="year">Année</CLabel>
                <CSelect
                  value={year}
                  name="year"
                  onChange={(e) => setYear(e.target.value)}
                >
                  <option></option>
                  {getYear()}
                </CSelect>

                {submitted && !year && (
                  <span className="invalid-feedback error">
                    Veuillez entrer l'année de la voiture
                  </span>
                )}
              </CFormGroup>
            </CCol>
          </CRow>
          <Scroll/>
         </div>

         <div className="advInfoSzctionCard" >
        <Basic/>
          <CRow>
            <CCol md="6">
              <CFormGroup>
                <CLabel htmlFor="model">Taille du cadre</CLabel>
                <CSelect
                  name="frame"
                  value={frame}
                  onChange={(e) => setFrame(e.target.value)}
                >
                  <option value=""> Choisissez la taille</option>
                  <option value="XS"> XS</option>
                  <option value="S"> S</option>
                  <option value="M"> M</option>
                  <option value="L"> L</option>
                  <option value="XL"> XL</option>
                </CSelect>
                {submitted && !frame && (
                  <span className="invalid-feedback error">obligatoire</span>
                )}
              </CFormGroup>
            </CCol>
            <CCol md="6">
              <CFormGroup>
                <CLabel htmlFor="model">Hauteur de cadre</CLabel>
                <CInput
                  name="frameHeight"
                  value={frameHeight}
                  onChange={(e) => setFrameHeight(e.target.value)}
                />
                {submitted && !frameHeight && (
                  <span className="invalid-feedback error">obligatoire</span>
                )}
              </CFormGroup>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="6">
              <CFormGroup>
                <CLabel htmlFor="model">Matériel du cadre</CLabel>
                <CSelect
                  name="material"
                  value={material}
                  onChange={(e) => setMaterial(e.target.value)}
                >
                  <option value=""> </option>
                  <option value="Acier"> Acier</option>
                  <option value="Aluminium"> Aluminium</option>
                  <option value="Titane"> Titane</option>
                  <option value="Fibre de carbone"> Fibre de carbone</option>
                </CSelect>
                {submitted && !material && (
                  <span className="invalid-feedback error">obligatoire</span>
                )}
              </CFormGroup>
            </CCol>
            <CCol md="6">
              <CFormGroup>
                <CLabel htmlFor="wheelSize">Taille des pneus</CLabel>
                <CSelect
                  name="wheelSize"
                  value={wheelSize}
                  onChange={(e) => setWheelSize(e.target.value)}
                >
                  <option value="">Choisissez la taille des pneus </option>
                  <option value="16 pouces"> 16 pouces</option>
                  <option value="20 pouces"> 20 pouces</option>
                  <option value="24 pouces"> 24 pouces</option>
                  <option value="26 pouces"> 26 pouces</option>
                  <option value="27.5 pouces"> 27.5 pouces</option>
                  <option value="27.5 plus"> 27.5 plus</option>
                  <option value="29 pouces"> 29 pouces</option>
                  <option value="650"> 650</option>
                  <option value="700">700</option>
                </CSelect>
                {submitted && !material && (
                  <span className="invalid-feedback error">obligatoire</span>
                )}
              </CFormGroup>
            </CCol>
          </CRow>

          <CRow>
            <CCol md="6">
              <CFormGroup>
                <CLabel htmlFor="price">Prix en TND</CLabel>
                <CInput
                  name="price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
                {submitted && !price && (
                  <span className="invalid-feedback error">obligatoire</span>
                )}
              </CFormGroup>
            </CCol>
            <CCol md="6">
              <CFormGroup>
                <CLabel htmlFor="breaking">Freinage</CLabel>
                <CInput
                  name="breaking"
                  value={breaking}
                  onChange={(e) => setBreaking(e.target.value)}
                />
                {submitted && !breaking && (
                  <span className="invalid-feedback error">obligatoire</span>
                )}
              </CFormGroup>
            </CCol>
          </CRow>
          <CRow xs="12">
            <CCol xs="6">
              <CFormGroup>
                <CLabel>Garrantie </CLabel>
                <CSelect
                  name="warranty"
                  value={warranty}
                  onChange={(e) => setWarranty(e.target.value)}
                >
                  <option value=""></option>
                  <option value="0">0</option>
                  <option value="moins 1"> Moins d'une année</option>
                  <option value="1">1 an</option>
                  <option value="2">2 ans</option>
                </CSelect>
                {submitted && !warranty && (
                  <span className="invalid-feedback error">Obligatoire</span>
                )}
              </CFormGroup>
            </CCol>
          </CRow>
          <Scroll/>
         </div>
         <div className="advInfoSzctionCard" >
          <Adresse/>
          <CRow xs="12">
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="country">Pays</CLabel>
                <CSelect
                  name="country"
                  value={country}
                  onChange={(e) => handleCountryChange(e)}
                >
                  <option value="0"> Choisissez un pays</option>
                  {countries.map((country) => {
                    return (
                      <option key={country.id} value={country.id}>
                        {country.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !country && (
                  <span className="invalid-feedback error">Error</span>
                )}
              </CFormGroup>
            </CCol>
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="Région">Gouvernorat</CLabel>
                <CSelect
                  name="city"
                  value={city}
                  onChange={(e) => handleCityChange(e)}
                >
                  <option value="0"> Gouvernorat</option>
                  {cities.map((city) => {
                    return (
                      <option key={city.id} value={city.id}>
                        {city.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !city && (
                  <span className="invalid-feedback error">Error</span>
                )}
              </CFormGroup>
            </CCol>
          </CRow>

          <CRow xs="12">
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="delegate">Délégation</CLabel>
                <CSelect
                  name="delegate"
                  value={delegate}
                  onChange={(e) => handleDelegateChange(e)}
                >
                  <option value="0"> Choisissez une délégation</option>
                  {delegates.map((delegate) => {
                    return (
                      <option key={delegate.id} value={delegate.id}>
                        {delegate.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !delegate && (
                  <span className="invalid-feedback error">Error</span>
                )}
              </CFormGroup>
            </CCol>
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="loclité">Localité</CLabel>
                <CSelect
                  name="locality"
                  value={locality}
                  onChange={(e) => setLocality(e.target.value)}
                >
                  <option value="0"> Localité</option>
                  {localities.map((local) => {
                    return (
                      <option key={local.id} value={local.id}>
                        {local.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !locality && (
                  <span className="invalid-feedback error">Error</span>
                )}
              </CFormGroup>
            </CCol>
          </CRow>
          <Scroll/>
          </div>
          <div className="advInfoSzctionCard" >
       <Contact/>
          <CRow xs="12">
          <CCol xs="6">
              {newPhone && <CFormGroup className='formPhoneNumber'>
                <CLabel htmlFor="phoneNumber">Numéro téléphone</CLabel>
                <PhoneInput
                  type="text"
                  onChange={(value) => setPhoneNumber(value)}
                  placeholder="Entrez un numéro de téléphone"
                  defaultCountry="TN"
                  international
                  name="phoneNumber"
                  id="phoneNumber"
                  value={phoneNumber}
                />
                 {submitted && newPhone && phoneNumber.length>0 && !isValidPhoneNumber(phoneNumber) && (
                  <span className="invalid-feedback error">Ce numéro de téléphone n'est pas valide</span>
                )}
              </CFormGroup>}
              <CFormGroup variant="checkbox" className="checkbox">
                <CInputCheckbox
                  onChange={changePhoneNumber}                 
                />
                <CLabel variant="checkbox" className="form-check-label">
                Utilisez le numéro téléphone de votre compte
                </CLabel>
              </CFormGroup> 
            </CCol>
          </CRow>
          <Scroll/>
          </div>

          <div className="advInfoSzctionCard" >
     <Extra/>
          <CRow>
            <CCol>
              <CFormGroup>
                <CKEditor
                  editor={ClassicEditor}
                  data={description}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setDescription(data);
                  }}
                />
              </CFormGroup>
            </CCol>
          </CRow>
          <Scroll/>
          </div>

      <div className="advInfoSzctionCard" >
          <Imgs/>
          <CRow>
            <CCol>
            <CFormGroup style={{display:"inline-block"}}>
                <CLabel htmlFor="pImage">Principal image</CLabel>
                <input 
                          type="file"
                          name="pImage"
                          onChange={handleFileSection}
                          accept="image/*"
                           ></input>
            </CFormGroup>
            {uploadingImage &&<span style={{height:"fit-content",marginLeft:"10px",lineHeight:"90px"}}>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span>}
                               {!uploadingImage && pImageThumb &&  <img style={{height: "85px", width: "auto"}}
          src={pImageThumb}
          alt="preview"
          className="img-fluid img-thumbnail "
        />} 
           {submitted && !imgPrincipal && (
                <span className="invalid-feedback error">
                  Veuillez uploader une image
                </span>
              )}
            </CCol>
          </CRow><br/>
          {imgCountA>0 && <> <div className="imgCounter"> {imgCount} {imgCount > 1 ? ' images téléchargées sur ' :' image téléchargée sur ' }  {imgCountA} </div>
       <div className="loading-bar">
          <div className="loading-bar-fill" style={{ width: `${imgCountA==0?0 :(imgCount / imgCountA) * 100}%` }}></div>
        </div><br/></>}
          <CRow>
            <CCol>
            <CLabel >Autres images</CLabel> 
              <Dropzone
                //getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                // onSubmit={handleSubmitF}
                accept="image/*"
                maxFiles="9"
                maxSizeBytes={5 * 1024 * 1024}
              ></Dropzone>
            </CCol>
          </CRow>
          <br />
          </div>
          {success && (
            <CAlert show={true} placement="top-end" color="success">
              {`Le véhicule a été ajouté avec succès !`}
            </CAlert>
          )}
          <CRow>
          <CCol md="3" className="offset-9">
            {
  ((!(doneuploadingfile && imgCountA == album.length)) || uploadingImage)? 
  (
              <CButton color="primary" block disabled>
                <span>
                  {" "}
                  <CSpinner color="default" size="sm" />
                </span>

                <span> chargement des images</span>
              </CButton>
            )

       :  loading ? (
              <CButton color="primary" block disabled>
                <span>
                  {" "}
                  <CSpinner color="default" size="sm" />
                </span>

                <span> Valider</span>
              </CButton>
            )  
            :
              <CButton color="primary" block type="submit">
                Valider
              </CButton>
            
}
            </CCol>
          </CRow>
        </form>
      </>
    );
  }
};

export default BikeForm;
