import React, { Component } from "react";
import Navbar from "../../containers/Navbar";

import { Route, Switch, BrowserRouter, HashRouter } from "react-router-dom";

import MemberProvider from "./members/MemberProvider";
import Article from "../FrontOffice/news/Article";
import ErrorPage from "./ErrorPage";
import NewsByCat from "./news/NewsByCat";
import RegisterMember from "./members/Register";
import LoginMember from "./members/LoginMember";
import Forget from "./members/Forget";
import ConfirmAccount from "./members/Confirm";
import Footer from "src/containers/Footer";
import PrivateRoutes from "../hooks/PrivateRoutes";
import Profile from "./members/Profile";
import Terms from "./terms";
import Policy from "./policy";
import Success from "./addSuccess";
import Jobs from "./jobs/Jobs";
import JobDetail from "./jobs/JobDetail";
import JobsByCompany from "./jobs/JobsByCompany";
import AddJob from "./jobs/AddJob";
//import SearchAd from "./advert/SearchAd";
import SendApplication from "./jobs/SendApplication";
import SearchVehicle from "./vehicles/cars/SearchVehicle";
import SearchJob from "./jobs/SearchJob";
import Vehicles from "./vehicles/Vehicles";
import AddVehicle from "./vehicles/AddVehicle";
import Vehicle from "./vehicles/Vehicle";
import ResultSearch from "./vehicles/cars/ResultSearch";
import ResultTruck from "./vehicles/trucks/ResultTruck";
import ResultBike from "./vehicles/bikes/ResultBike";
import ResultMoto from "./vehicles/motos/ResultMoto";

import { HomePage } from "./HomePage";
import store from "./reducers/store";
import { Provider } from "react-redux";
import FavoriteProvider from "./FavoriteProvider";
import ScrollToTop from "../ScrollToTop";
import SearchRealEstate from "./realEstate/SearchRealEstate";
import AddRealEstate from "./realEstate/addRealEstate";
import AddArticles from "./OtherAnnonce/articles/addArticles";
import SearchArticles from "./OtherAnnonce/articles/searchArticles";
import AddAnimals from "./OtherAnnonce/animals/addAnimal";
import AddServices from "./OtherAnnonce/services/addService";
import ResultAnimal from "./OtherAnnonce/animals/resultAnimal";
import AnimalDetails from "./OtherAnnonce/animals/animalDetails";
import SearchAnimal from "./OtherAnnonce/animals/searchAnimal";
import SearchServices from "./OtherAnnonce/services/searchServices";
import ResultService from "./OtherAnnonce/services/resultService";
import ServiceDetails from "./OtherAnnonce/services/serviceDetails";
import AddBonPlans from "./bonPlans/addBonPlans";
import ResultBonPlans from "./bonPlans/resultBonPlans";
import BonPlansDetails from "./bonPlans/bonPlansDetails";
import SearchTempBonPlans from "./bonPlans/searchBonPlans";
import AllSearchResult  from "./SearchResult";
import articleDetails from "./OtherAnnonce/articles/articleDetails";
import ResultArticle from "./OtherAnnonce/articles/resultArticle";
import RealEstateDetails from "./realEstate/RealEstatedetails";
import ResultRealEstate from "./realEstate/ResultReallEstate";
import AnnoncerPage from "./annoncerPage";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);
export class IndexFront extends Component {
  render() {
    // const history = this.useHistory();
    return (
      <>
        <Provider store={store}>
          {/** <HashRouter>*/}
          <BrowserRouter 
        //  basename={"/20222508"}
          >
            <React.Suspense fallback={loading}>
            <FavoriteProvider>
              <ScrollToTop />
              <Navbar></Navbar>

              <Switch>
                <Route exact path="/" component={HomePage} />
                <Route
                  path="/news/:id-:catId/:slugCat/:slug"
                  component={Article}
                />
                <Route path="/news/:id/:catSlug" component={NewsByCat} />
                <Route path="/members/register" component={RegisterMember} />
                <Route path="/terms" component={Terms} />
                <Route path="/politiquedeConfidentialite" component={Policy} />
                <Route path="/success/:s" component={Success} />
                <Route path="/members/login" component={LoginMember} />
                <Route path="/members/forget" component={Forget} />
                <Route
                  path="/members/confirm-account"
                  component={ConfirmAccount}
                />

                <Route
                  path="/memberAnnonces/:id"
                  component={AnnoncerPage}
                  exact
                />

                <Route
                  path="/real-estates/details/:id"
                  component={RealEstateDetails}
                  exact
                />
                <Route path="/jobs/sectors" component={SearchJob} />
                <Route
                  path="/jobs/job-details/:id/:slugSector/:slug"
                  component={JobDetail}
                />
                <Route path="/jobs/jobs-by-company/:id" component={JobsByCompany} />
                <Route path="/jobs/:id/:slug" component={Jobs} />
                <Route path="/jobs/publish-job-offer" component={AddJob} />
                {/* <Route path="/search-advert" component={SearchAd} /> */}
                <Route path="/allSearchResult" component={AllSearchResult} />
                <Route
                  path="/jobs/send-job-application/:id"
                  component={SendApplication}
                />
                <Route exact path="/vehicles" component={SearchVehicle} />
                <Route
                  exact
                  path="/vehicles/add"
                  component={AddVehicle}
                />
                <Route path="/vehicles/by-brand/:slug/:id" component={Vehicles} exact />
                <Route
                  path="/vehicles/cars/details/:id"
                  component={Vehicle}
                  exact
                />
                <Route
                  path="/vehicles/camions/details/:id"
                  component={Vehicle}
                  exact
                />   
                 <Route
                  path="/vehicles/bikes/details/:id"
                  component={Vehicle}
                  exact
                />
                <Route
                  path="/vehicles/motos/details/:id"
                  component={Vehicle}
                  exact
                />
                <Route
                  path="/vehicles/cars/search-result"
                  component={ResultSearch}
                  exact
                />
                <Route
                  path="/vehicles/camions/search-result"
                  component={ResultTruck}
                  exact
                />
                <Route
                  path="/vehicles/bikes/search-result"
                  component={ResultBike}
                  exact
                />
                <Route
                  path="/vehicles/motos/search-result"
                  component={ResultMoto}
                  exact
                />
            

                <Route exact path="/real-estates" component={SearchRealEstate} />
                <Route
                  exact
                  path="/real-estates/add"
                  component={AddRealEstate}
                />



               <Route
                  path="/real-estates/details/:id"
                  component={RealEstateDetails}
                  exact
                />

               <Route
                  path="/real-estates/search-result"
                  component={ResultRealEstate}
                  exact
                />

                {/**              petite annonce           */}
                <Route path="/articles/add" component={AddArticles} exact />
                <Route path="/articles" component={SearchArticles} exact />    
                <Route
                  path="/articles/details/:id"
                  component={articleDetails}
                  exact
                />  
                <Route
                  path="/articles/search-result"
                  component={ResultArticle}
                  exact
                />


                <Route exact path="/animals/add" component={AddAnimals} />
                <Route
                  path="/animals/search-result"
                  component={ResultAnimal}
                  exact
                />
                <Route
                  path="/animals/details/:id"
                  component={AnimalDetails}
                  exact
                />
                <Route path="/animals" component={SearchAnimal} exact />

                <Route exact path="/services/add" component={AddServices} />
                <Route
                  path="/services/search-result"
                  component={ResultService}
                  exact
                />
                <Route
                  path="/services/details/:id"
                  component={ServiceDetails}
                  exact
                />
                <Route exact path="/services" component={SearchServices} />

                <Route exact path="/bon-plans/add" component={AddBonPlans} />
                <Route
                  path="/bon-plans/search-result"
                  component={ResultBonPlans}
                  exact
                />
                <Route
                  path="/bon-plans/details/:id"
                  component={BonPlansDetails}
                  exact
                />
                <Route exact path="/bon-plans" component={SearchTempBonPlans} />

                <MemberProvider>
                <PrivateRoutes
                  path="/members/profile"
                  component={Profile}
                  render={(props) => <Profile {...this.props} />}
                  roles={["ROLE_MEMBER"]}
                />
              </MemberProvider>
                <Route component={ErrorPage} />

                {/*<Redirect from="/" to="/5467854/index" /> */}
              </Switch>
              </FavoriteProvider>
            </React.Suspense>
            <Footer></Footer>
          </BrowserRouter>
          {/**    </HashRouter> */}
       
        </Provider>
      </>
    );
  }
}

export default IndexFront;
