import React, { Component, useEffect, useState } from "react";
import { API_BASE_URL , tokenU } from "../../config";

import axios from "axios";
import {
  CInputCheckbox,
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CLabel,
  CCol,
  CContainer,
  CForm,
  CInput,
  CRow,
  CFormGroup,
  CTextarea,
  CSpinner
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { Link } from "react-router-dom";

export class infobar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputsF: [""],
      inputsE: [""],
      inputsA: [""],
      useArabic : false,
      isloading: false,
      label: "",
      createdBy: localStorage.getItem("id"),
      errors: "",
      success: "",
    };
  }


  componentDidMount() {

    axios
    .get(API_BASE_URL + "/info",{
      params: { userId: tokenU,
               },
    })
      .then((res) => res.data)
      .then(
        (json) => {
        console.log("json: ",json);
        let tempa = json.arabic;
        let tempe = json.english;
        let tempf = json.french;
       if(tempa) tempa=tempa.split("@@");
       if(tempe) tempe=tempe.split("@@");
       if(tempf) tempf=tempf.split("@@");

        this.setState({
          inputsF:tempf,
          inputsE:tempe,
          inputsA:tempa,
          success: true,
          useArabic: json.usearabic
        });
      },
      (error) => {
        console.log('eeeeee',error);

      }
    );

  }

  addInput = (lang) => {
    if(lang=="f") this.setState((prevState) => ({inputsF: [...prevState.inputsF, ""],}));
    else if(lang=="e") this.setState((prevState) => ({inputsE: [...prevState.inputsE, ""],}));
    else if(lang=="a") this.setState((prevState) => ({inputsA: [...prevState.inputsA, ""],}));
  };

  handleInputChange = (index, event, lang) => {
    let newInputs;
    if(lang=="f"){
    newInputs = [...this.state.inputsF];
    newInputs[index] = event.target.value;
    this.setState({ inputsF: newInputs, label:newInputs });
  } else  if(lang=="e"){
    newInputs = [...this.state.inputsE];
    newInputs[index] = event.target.value;
    this.setState({ inputsE: newInputs });
  } else  if(lang=="a"){
    newInputs = [...this.state.inputsA];
    newInputs[index] = event.target.value;
    this.setState({ inputsA: newInputs });
  }
  
  };

  removeInput = (index,lang) => {
    let newInputs;
    if(lang=="f"){
     newInputs= [...this.state.inputsF];
    newInputs.splice(index, 1);
    this.setState({ inputsF: newInputs });
  }
  else if(lang=="e"){
    newInputs= [...this.state.inputsE];
   newInputs.splice(index, 1);
   this.setState({ inputsE: newInputs });
 } 
 else if(lang=="a"){
  newInputs= [...this.state.inputsA];
 newInputs.splice(index, 1);
 this.setState({ inputsA: newInputs });
}
  };

  // handleChange = (event) =>
  //   this.setState({
  //     [event.target.name]: event.target.value,
  //   });

  // handleValidation = () => {
  //   let fields = this.state.label;
  //   let errors = {};
  //   let formIsValid = true;
  //   if (!fields) {
  //     formIsValid = false;
  //     errors["label"] = "Le libéllé ne peut pas être vide";
  //   } else {
  //     errors["label"] = "";
  //   }
  //   this.setState({ errors: errors });
  //   return formIsValid;
  // };
  handleSubmit = (event) => {
    event.preventDefault();
    let tempa= this.state.inputsA.join("@@");
    let tempe= this.state.inputsE.join("@@");
    //let tempf= this.state.inputsF.join("@@");

    this.setState({
      isloading: true
    });
    //console.log("state: ", this.state);

    const formData = new FormData();
    formData.append("arabic", tempa);
    formData.append("english", tempe);
   // formData.append("french", tempf);
    formData.append("useArabic", this.state.useArabic);
    formData.append("createdBy", tokenU);

    //console.log('useArabic: ',formData.get('useArabic'));

    axios
      .post(API_BASE_URL + "/info/add", formData)
      .then((res) => {
        //console.log("reesssss");
        console.log(res);
        if(res.data=='ok'){
        this.setState({
          success: "Info Bar a été mis avec succès",
          isloading: false
        });
        window.scrollTo(0, 0);
      }
        else  {
           //console.log("not ok: ", res);
           this.setState({
            error: "un error occured !",
            isloading: false

          });

          }
      })
      .catch((error) => {
        console.log("errors : ", error);
        this.setState({
          isloading: false
        });
      });
  };
  render() {
    return (
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="12" lg="12" xl="12">
            <CCard className="mx-4">
              <CCardBody className="p-4">
                {this.state.success.length > 0 && (
                  <CAlert color="success" closeButton>
                    {this.state.success}
                  </CAlert>
                )}
                <CForm onSubmit={this.handleSubmit} id="form-add">
                  <h1>Modifier Info bar</h1><br/><br/>
                  {/* <CCol>
                  <p></p>
                </CCol>
                <p style={{fontSize:'18px',fontWeight:'bolder',color:'#22b955',width:'fit-content',borderBottomStyle:'double'}}>
                  Type @@ to show logo icon seperator</p>
                <CRow>
                  <CCol xs="12">
                    <CFormGroup>
                      <CTextarea style={{height: '120px'}}
                        id="label"
                        placeholder="Enter new text for info bar"
                        name="label"
                        value={this.state.label}
                        onChange={this.handleChange}
                      />
                    </CFormGroup>
                  </CCol>
                </CRow>
           */}
                 <CCol>
                  {this.state.errors.length > 0 && (
                    <p
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {this.state.errors}
                    </p>
                  )}
                </CCol>


                <CRow className="newsGroup">
                <CCol xs="12"> <br/>
                <CFormGroup> &nbsp; &nbsp;
                
                <CInputCheckbox
                  checked={this.state.useArabic}
                style={{width:'16px',height:'16px'}}
                  onChange={()=>this.setState({useArabic: !this.state.useArabic})}                 
                />
                <CLabel variant="checkbox" className="form-check-label" style={{display:"inline-block",fontSize:'18px'}}>
                Display Arabic News
                </CLabel>
                </CFormGroup>
                </CCol>
                  </CRow>
                  <br />
                  <br />


                  <CRow className="newsGroup" style={{display:'none'}}>
                  <CCol xs="12">
                    <CLabel>News</CLabel>
                    <CFormGroup>
                  <div>
                    {this.state.inputsF && this.state.inputsF.map((input, index) => (
                      <div key={index}>
                        <button className="removeButton" onClick={() => this.removeInput(index,'f')}>
                        <CIcon style={{verticalAlign:"sub"}} name="cil-trash" />
                        </button>
                        <CTextarea
                          style={{ height: "75px" }}
                          placeholder="Enter news text for info bar"
                          name={"label" + index}
                          value={input}
                          onChange={(e) => this.handleInputChange(index, e,'f')}
                        />   <br /> </div>
                    ))}
                
                    <CRow>
                      <CCol xs="12" sm="3">
                        <CButton color="success" block onClick={()=>this.addInput('f')}>
                          Ajouter un autre news 
                        </CButton>
                      </CCol>
                    </CRow>
                  </div>
                  </CFormGroup>
                  </CCol>
                  </CRow>

                  <CRow className="newsGroup" >
                  <CCol xs="12">
                  <CLabel>News</CLabel>
                    <CFormGroup>
                  <div>
                    {this.state.inputsE && this.state.inputsE.map((input, index) => (
                      <div key={index}>
                        <button className="removeButton" onClick={() => this.removeInput(index,'e')}>
                        <CIcon name="cil-trash" />
                        </button>
                        <CTextarea
                          style={{ height: "75px" }}
                          placeholder="Enter news text for info bar"
                          name={"label" + index}
                          value={input}
                          onChange={(e) => this.handleInputChange(index, e,'e')}
                        /> <br /> </div>
                    ))}
                    <br />
                    <CRow>
                      <CCol xs="12" sm="3">
                        <CButton color="success" block onClick={()=>this.addInput('e')}>
                          Add another English news
                        </CButton>
                      </CCol>
                    </CRow>
                  </div>
                  </CFormGroup>
                  </CCol>
                  <br />
                  <br /></CRow>
                 
                  <CRow className="newsGroup">
                  <CCol xs="12">
                  <CLabel>Arabic news</CLabel>
                    <CFormGroup>
                  <div>
                    {this.state.inputsA && this.state.inputsA.map((input, index) => (
                      <div key={index}>
                        <button className="removeButton" onClick={() => this.removeInput(index,'a')}>
                        <CIcon name="cil-trash" />
                        </button>
                        <CTextarea
                          style={{ height: "75px" }}
                          placeholder="Enter news text for info bar"
                          name={"label" + index}
                          value={input}
                          onChange={(e) => this.handleInputChange(index, e,'a')}
                        />  <br /> </div>
                    ))}
                    <br />
                    <CRow>
                      <CCol xs="12" sm="3">
                        <CButton color="success" block onClick={()=>this.addInput('a')}>
                          Add another Arabic news
                        </CButton>
                      </CCol>
                    </CRow>
                  </div>
                  </CFormGroup>
                  </CCol>
                  <br />
                  <br /></CRow>
                 
                  <CRow>
                    <CCol xs="12" sm="3"><br/>
                      <CButton color="success" block type="submit" disabled={this.state.isloading}>
                       {  this.state.isloading &&  <CSpinner color="default" size="sm" />} Valider
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
           
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    );
  }
}

export default infobar;
