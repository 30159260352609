import React, { useEffect, useState } from "react";
import { API_BASE_URL , IMAGES_BASE_URL , tokenU} from "../../../config";
import Moment from "react-moment";
import {
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CButton,
  CCollapse,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CAlert,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { Link } from "react-router-dom";
import axios from "axios";
import { getBadge , textBadge} from "src/actions";

export default function RealEstates() {
  const [modal, setModal] = useState(false);
  const [error, setError] = useState(null);
  const [errorDel, setErrorDel] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const [details, setDetails] = useState([]);
  const toggle = () => {
    setModal(!modal);
  };

  const fields = [
    {
      key: "mainPicture",
      label: "image",
    // _style: { width: "20%" },
    },
    {
      key: "sn",
      label: "Numéro de série",
      //_style: { width: "10%" },
    },
    {
      key: "createdBy",
      label: "Auteur",
      //_style: { width: "10%" },
    },
    {
      key: "title",
      label: "libellé",
      //_style: { width: "20%" },
    },
    {
      key: "type",
      label: "type",
      //_style: { width: "20%" },
    },
    {
      key: "price",
      label: "prix",
      //_style: { width: "20%" },
    },
    { key: "createdAt",
    // _style: { width: "20%" }, 
    label: "Date d'ajout" },
    { key: "status",
    // _style: { width: "20%" }, 
    label: "Statut" },

    {
      key: "show_details",
      label: "Actions",
      //_style: { width: "1%" },
      sorter: false,
      filter: false,
    },
  ];
  // Remarque : le tableau vide de dépendances [] indique
  // que useEffect ne s’exécutera qu’une fois, un peu comme
  // componentDidMount()
  useEffect(() => {
    axios
      .get(API_BASE_URL + "/real_estate/all", {
        params: { userId: tokenU,
                  src: 'dashboard'
                 },
      })
      .then((res) => res.data)
      .then(
        (result) => {
          setIsLoaded(true);
          console.log('result<<<',result);
          setItems(result);
         setLoaded(true);

        },
        // Remarque : il faut gérer les erreurs ici plutôt que dans
        // un bloc catch() afin que nous n’avalions pas les exceptions
        // dues à de véritables bugs dans les composants.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  if (error) {
    return <div>Erreur : {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Chargement...</div>;
  } else {
    return (
      <div className="annonceTableDashboard">
        <CRow>
          <CCol xl={12}>
            <CCard>
              <CCardHeader>{items && items.length} Annonces Immoblier
              <br /> {items.filter(annonce => annonce.status === 'pending').length} en attente
              {items.filter(annonce => annonce.status === 'editing').length >0 && <><br /> {items.filter(annonce => annonce.status === 'editing').length} en validation </> }
             </CCardHeader>
              <CCardBody>
                {errorDel !== "" ? (
                  <CAlert color="danger" closeButton>
                    {errorDel}
                  </CAlert>
                ) : (
                  ""
                )}
                {loaded && (
                  <CDataTable
                    items={items}
                    fields={fields}
                    columnFilter
                    tableFilter
                    footer
                    itemsPerPageSelect
                    itemsPerPage={10}
                    hover
                    sorter
                    pagination
                    scopedSlots={{
                      mainPicture: (item) => (
                        <td className="titleTdNewsTableDashboard titleTdNewsTableDashboard1">
                        <img
                          width="100"
                         className="img-fluid img-thumbnail "
                         src={
                          item.mainPicture
                            ? `${IMAGES_BASE_URL}/public/uploads/images/${item.type}/${item.mainPicture.name}`
                            : ""
                        }
                        alt={item.mainPicture.name}
                        />
                        </td>
                      ),
                      title: (item) => (
                        <td className="titleTdNewsTableDashboard">
                        {item.title}
                        </td>
                      ),                    
                      status: (item) => (
                        <td>
                          <CBadge color={getBadge(item.status , item.edits)}>
                            {textBadge(item.status ,item.edits )}
                           
                          </CBadge>
                        </td>
                      ),
                      createdAt: (item) => (
                        <td>
                      <Moment format="DD/MM/YYYY">
                        {item.createdAt}
                      </Moment>
                        </td>
                      ),
                      createdBy: (item) => (
                        <td>
                          {item.createdBy.prenom && item.createdBy.prenom} {item.createdBy.nom && item.createdBy.nom}
                        </td>
                      ),
                      show_details: (item, index) => {
                        return (
                          <td className="py-2">
                            <Link 
                              to={{ pathname:`/5467854/realEstates/details/${item.id}`,
                               state:item}}
                               >
                                <CButton size="sm" color="primary">
                                  Voir détail
                                </CButton>
                              </Link>
                          </td>
                        );
                      }
                    }}
                  />
                )}
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </div>
    );
  }
}
