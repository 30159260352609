import {
  CAlert,
  CRow,
  CCol,
  CFormGroup,
  CLabel,
  CInput,
  CButton,
  CSpinner,
  CSelect,
  CInputCheckbox,
  CInputFile

  // CSpinner,
} from "@coreui/react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import imageCompression from "browser-image-compression";
import { temp_sub_url } from "src/config";
//import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { useHistory } from "react-router-dom";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { API_BASE_URL , tokenCookie , keyHeader , sizeAlert} from "src/config";
import Loading from "src/components/myWidgets/loadingWidget";
import { Basic , Scroll , Categori , Adresse , Contact , Extra , Imgs, Eventt} from "src/components/myWidgets/formTitles";
import { addWatermark , addWatermarkToAlbum } from "src/actions";
import { logoutLocal } from "src/components/middleware/auth";

export default function BonPlansForm() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [price, setPrice] = useState("");
  const [album, setAlbum] = useState([]);
  const [imgPrincipal, setImgPrincipal] = useState(null);
  const [success, setSuccess] = useState(false);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState("");
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [delegate, setDelegate] = useState("");
  const [delegates, setDelegates] = useState([]);
  const [locality, setLocality] = useState("");
  const [localities, setLocalities] = useState([]);
  const [types, setTypes] = useState([]);
  const [type, setType] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState([]);
  const [nom, setNom] = useState([]);
  const [datev, setDatev] = useState(new Date());
  const [seance3, setSeance3] = useState();
  const [seance2, setSeance2] = useState();
  const [adresse, setAdresse] = useState([]);
  const [placesNom, setPlaceNom] = useState([]);
  const [video, setVideo] = useState([]);
  const [tickets, setTickets] = useState([{ type: "", price: "" }]);
  //const [ticketsType, setTicketsType] = useState([]);
 // const [ticketsPrice, setTicketsPrice] = useState([]);
  const [doneuploadingfile, setDoneuploadingfile] = useState(true);
  const [newPhone, setNewPhone] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [pImageThumb, setpImageThumb] = useState(null);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [menuThumb, setMenuThumb] = useState(null);
  const [menu, setMenu] = useState(null);
  const [imgCount , setImgCount] = useState(0);
  const [imgCountA , setImgCountA] = useState(0);
  const [abortControllers, setAbortControllers] = useState({});
  const [removedfiles, setremovedfiles] = useState([]);

  const history = useHistory();

  const addRow = () => {
    if (tickets.length < 4) {
      setTickets([...tickets, { type: "", price: "" }]);
    }
  };
  const handleInputChange = (index, field, value) => {
    const newTickets = [...tickets];
    newTickets[index][field] = value;
    setTickets(newTickets);

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    //console.log("album in submit");
    //console.log(album)

      if ((!(doneuploadingfile && imgCountA == album.length)) || uploadingImage) {
        alert("not done uploading files yet");
        setLoading(false);

        return false;
      } else {
        setSubmitted(true);
        
        let isvalidsubmit = false;
          if (
            type &&
            category &&
            country &&
            city &&
            delegate &&
            locality &&
            nom &&
            imgPrincipal &&            
            (phoneNumber.length==0 || isValidPhoneNumber(phoneNumber))
          ) {
            isvalidsubmit = true;
            //console.log("2222222222222");
          }
        
        //console.log("vvvvvvvvalllliid");
        //console.log(isvalidsubmit);
       

        if (isvalidsubmit) 
        //if(1)
        {
          const watermarkedAlbum = await addWatermarkToAlbum(album);
          const watermarkedP = await addWatermark(imgPrincipal);

          const formData = new FormData();
          const datef = datev.toISOString();
          let seance23 = "";
          if (seance2) {
            seance23 = seance2;
            if (seance3) seance23 = seance23 + "++" + seance3;
            //console.log(seance23);
          }

          formData.append("seance23", seance23);
          formData.append("nom", nom);
          formData.append("datef", datef);
          formData.append("adresse", adresse);
          formData.append("placesnom", placesNom);
          formData.append("video", video);
         if(tickets && tickets[0].type.length>0) formData.append("tickets", JSON.stringify(tickets));
        // if(ticketsPrice.length>0) formData.append("ticketsprice", JSON.stringify(ticketsPrice));
        // if(ticketsType.length>0) formData.append("ticketstype", JSON.stringify(ticketsType));
          formData.append("category", category);
          formData.append("locality", locality);
          formData.append("description", description);
          formData.append("price", price);          
          formData.append("createdBy", tokenCookie);   

         if(newPhone)  formData.append("phone", phoneNumber);
         if(menu) formData.append("menu", menu);
          formData.append("imgPrincipal", watermarkedP);
          for (let index = 0; index < watermarkedAlbum.length; index++) {
            formData.append("album[]", watermarkedAlbum[index]);
          }
          //console.log('tickets st', tickets);
          //console.log('tickets ', formData.get('tickets'));

        
           axios
            .post(API_BASE_URL + "/annonce_goodPlans/add", formData, keyHeader)
            .then((res) => {
              //console.log(res);
              if (res.data.status.toString() === "ok") {
                setSuccess(true);
                history.push( "/success/ok" , { from: 'add' } );
              } 
              setLoading(false);
            })
            .catch((error) => {
              //console.log(" errors : ", error);
              setLoading(false);
              if (error.response && error.response.status === 401){
                logoutLocal();
                window.location.replace("/members/login");
              }
            });
        } else {
          window.scrollTo(0, 200);
          //console.log("not valid");
          setLoading(false);
        }

        // alerttttt
      }

  };

  useEffect(() => {
    // axios(`${API_BASE_URL}/countries/all`)
    //   .then((res) => res.data)
    //   .then(
    //     (result) => {
    //       setIsLoaded(true);

    //       setCountries(result);
    //     },
    //     (error) => {
    //       setError(error);
    //     }
    //   );

    if (!localStorage.getItem("countries")) {
      axios(`${API_BASE_URL}/countries/all`)
        .then((res) => res.data)
        .then(
          (result) => {
            setCountries(result);
            localStorage.setItem("countries", JSON.stringify(result));
          },
          (error) => {
            setError(error);
          }
        );
    } else {
      setCountries(JSON.parse(localStorage.getItem("countries")));
    }

    if (!localStorage.getItem("typeGoodPlans")) 
{
    axios(`${API_BASE_URL}/goodPlans/type-goodPlans/all`)
      .then((res) => res.data)
      .then(
        (result) => {
          //console.log("result555", result);
          setTypes(result);
          setIsLoaded(true);
          localStorage.setItem("typeGoodPlans", JSON.stringify(result));
        },
        (error) => {
          setError(error);
        }
      );
  } else {
    setTypes(JSON.parse(localStorage.getItem("typeGoodPlans")));
    setIsLoaded(true);
  }
  }, [countries.id]);
  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    let id = e.target.value;
    var countriesF = [];
    //console.log("iddd", id);
    if (id !== "0") {
      countriesF = countries.find((city) => city.id.toString() === id);
      setCities(countriesF.cities);
      //console.log("countriesF", countriesF);
    } else {
      setCity([]);
      //  setModel("");
    }
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
    let id = e.target.value;
    var citiesF = [];
    //console.log("iddd", id);
    if (id !== "0") {
      citiesF = cities.find((delegate) => delegate.id.toString() === id);
      setDelegates(citiesF.delegations);
      //console.log("citiesF", citiesF);
    } else {
      setDelegate([]);
      //  setModel("");
    }
  };
  const handleDelegateChange = (e) => {
    setDelegate(e.target.value);
    let id = e.target.value;
    var delegatesF = [];
    //console.log("iddd", id);
    if (id !== "0") {
      delegatesF = delegates.find((local) => local.id.toString() === id);
      setLocalities(delegatesF.localities);
      //console.log("delegatesF", delegatesF);
    } else {
      setLocality([]);
      //  setModel("");
    }
  };
  const handleTypeChange = (e) => {
    setType(e.target.value);

    let id = e.target.value;
    var typesF = [];
    if (id !== "0") {
      typesF = types.find((category) => category.id.toString() === id);
      setCategories(typesF.categoryGoodPlans);
      //console.log(typesF);
    } else {
      setCategories([]);
      setCategory("");
    }
    //console.log(type);
  };
  const handleCategoryChange = (e) => {
    //console.log(type);
    setCategory(e.target.value);
  };

  const getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = async ({ meta, file }, status) => {

    if (status === 'preparing' && meta.size > 5 * 1024 * 1024) {
      alert(sizeAlert);
      return;
  //file = null;
    }
    setDoneuploadingfile(false);
    let removedfilesx = removedfiles

    if (status === 'preparing' && !(meta.size > 5 * 1024 * 1024)) { setImgCountA(imgCountA+1)}     
    if (status.toString() === "done" && !(meta.size > 5 * 1024 * 1024)) {  
      let newalb = album;
      let options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
      };
     const abortController = new AbortController();
      const fileId = meta.id;
      const updatedAbortControllers = { ...abortControllers, [fileId]: abortController };
      setAbortControllers(updatedAbortControllers);
      options.signal = abortController.signal;

      try {
        file = await imageCompression(file, options);
      } catch (error) {
        //console.log('erro compress : ',error);
       // return;
      }
         if ((!removedfiles.includes(meta.id) && !removedfilesx.includes(meta.id)) && !(meta.size > 5 * 1024 * 1024)) 
         {
          newalb.push(file);
         // setAlbum(prevAlbum => [...prevAlbum, file]);
         setAlbum(newalb)
          setImgCount(prevCount => prevCount + 1);
    } else {
       //console.log("new album in done else");
    //console.log(newalb.length ,newalb);
   }
      setDoneuploadingfile(true);
    }
   if (status.toString() === "removed" && !(meta.size > 5 * 1024 * 1024)) {
    removedfilesx.push(meta.id);
    setremovedfiles(removedfiles)
  // setremovedfiles(prevA => [...prevA, meta.id]);
      const fileId = meta.id;
      if (abortControllers[fileId]) {
        abortControllers[fileId].abort();
        const updatedAbortControllers = { ...abortControllers };
        delete updatedAbortControllers[fileId];
        setAbortControllers(updatedAbortControllers);
      
      }

      let index = album.findIndex((obj) => obj.name === file.name);
      let newAlbum = album;
     { newAlbum.splice(index, 1);
     if(index>-1) {
     if(imgCount>0) setImgCount(newAlbum.length);}
      if(imgCountA>0) setImgCountA(imgCountA-1);
      setAlbum(newAlbum);
     }
      setDoneuploadingfile(true);

    } else  setDoneuploadingfile(true);
  };

  const handleFileSection = async ( event) => {
    setUploadingImage(true);
    let file = event.target.files[0];
    if (file.size > 5 * 1024 * 1024) {
      alert(sizeAlert);
      setpImageThumb(null);
      setImgPrincipal(null);
      setUploadingImage(false);    
      event.target.value = '';
      return;
    }

    let options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
    };
    try {
      file = await imageCompression(file, options);

    } catch (error) {
      //console.log(error);
    }  
    setpImageThumb(URL.createObjectURL(file));
    setImgPrincipal(file);
    setUploadingImage(false);   
  }; 

const changePhoneNumber = (event) => {
  //console.log(event.target.checked);
  if(event.target.checked) {setNewPhone(false);setPhoneNumber("");}
  else {setNewPhone(true);
  }
}


  if (error) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div>Erreur : {error.message}</div>
          </div>
        </main>
      </div>
    );
  } else if (!isLoaded) {
    return (
      <Loading/>
    );
  } else {

    return (
      <>
        <form onSubmit={handleSubmit} id="form-add">
        <div className="advInfoSzctionCard" >
       <Categori/>
          <CRow xs="12">
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="type">Type </CLabel>
                <CSelect
                  name="type"
                  value={type}
                  onChange={(e) => handleTypeChange(e)}
                >
                  <option value="0"> Choisir le type</option>
                  {types.map((type) => {
                    return (
                      <option key={type.id} value={type.id}>
                        {type.label}
                      </option>
                    );
                  })}
                </CSelect>

                {submitted && !type.length && (
                  <span className="invalid-feedback error">
                    Veuillez choisir le type
                  </span>
                )}
              </CFormGroup>
            </CCol>
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="category">Categorie</CLabel>
                <CSelect
                  name="category"
                  value={category}
                  onChange={(e) => handleCategoryChange(e)}
                >
                  <option value="0"> Choisir la categorie </option>
                  {categories.map((category) => {
                    return (
                      <option key={category.id} value={category.id}>
                        {category.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !category.length && (
                  <span className="invalid-feedback error">
                    Veuillez choisir le categorie
                  </span>
                )}
              </CFormGroup>
            </CCol>
          </CRow> 
          <Scroll/>
         </div>
      

          <div className="advInfoSzctionCard" >
        <Basic/>
              <CRow xs="12">
                <CCol xs="6">
                  <CFormGroup>
                    <CLabel htmlFor="nom">Nom de la société ou de l'événement
</CLabel>
                    <CInput
                      type="text"
                      name="nom"
                      value={nom}
                      onChange={(e) => setNom(e.target.value)}
                    ></CInput>
                    {submitted && !nom.length && (
                      <span className="invalid-feedback error">
                        Obligatoire
                      </span>
                    )}
                  </CFormGroup>
                </CCol>

                {!(
            type == 3 || type == 4 || type == 5 || (type == 1 &&
            (category == 1 || category == 2 || category == 3 || category == 8))
          ) ?             
                <CCol xs="6">
                  <CFormGroup>
                    <CLabel htmlFor="price">Prix en TND</CLabel>
                    <CInput
                      type="number"
                      name="price"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    ></CInput>
                   
                  </CFormGroup>
                </CCol>             
            :   ''}

            </CRow> 
            <Scroll/>
         </div>

           
         {type == 1 &&
          (category == 1 ||
              category == 2 ||
              category == 3 ||
              category == 8 
              || category == 6 || category == 7) && 
              <div className="advInfoSzctionCard" >
          <Eventt/>
           {type == 1 &&
            (category == 1 ||
              category == 2 ||
              category == 3 ||
              category == 8) &&
              <CRow xs="12">
                <CCol xs="6">
                    <CFormGroup>
                      <CLabel htmlFor="promo">Lien de Promo</CLabel>
                      <CInput
                        type="text"
                        name="promo"
                        value={video}
                        onChange={(e) => setVideo(e.target.value)}
                      ></CInput>
                    </CFormGroup>
                  </CCol> 
                  </CRow>
           }

           

        {type == 1 && (category == 6 || category == 7) && (
            <>
              <CRow xs="12">
                <CCol xs="6">
                  <CFormGroup>
                    <CLabel htmlFor="placenom">Nombre de place a vendre</CLabel>
                    <CInput
                      type="number"
                      name="placenom"
                      value={placesNom}
                      onChange={(e) => {
                        setPlaceNom(e.target.value);
                        //console.log("inputssss");
                        //console.log(datev);
                      }}
                    ></CInput>
                    {submitted && !placesNom.length && (
                      <span className="invalid-feedback error">
                        Obligatoire
                      </span>
                    )}
                  </CFormGroup>
                </CCol>
                <CCol xs="6">
                  <CFormGroup className="timepickerform">
                    <CLabel htmlFor="datev">
                      Date et heure de l'événement{" "}
                    </CLabel>
                    <DateTimePicker value={datev} onChange={setDatev} />
                    {submitted && !datev && (
                      <span className="invalid-feedback error">
                        Obligatoire
                      </span>
                    )}
                  </CFormGroup>
                </CCol>
              </CRow>
            </>
          )}

          {type == 1 &&
            (category == 1 ||
              category == 2 ||
              category == 3 ||
              category == 8) && (
              <>
                <CRow xs="12">
                  <CCol xs="6">
                    <CFormGroup>
                      <CLabel htmlFor="placenom">
                        Nombre de place a vendre
                      </CLabel>
                      <CInput
                        type="number"
                        name="placenom"
                        value={placesNom}
                        onChange={(e) => {
                          setPlaceNom(e.target.value);
                          //console.log("inputssss");
                          //console.log(datev);
                        }}
                      ></CInput>
                      {submitted && !placesNom.length && (
                        <span className="invalid-feedback error">
                          Obligatoire
                        </span>
                      )}
                    </CFormGroup>
                  </CCol>
                  <CCol xs="6">
                    <CFormGroup className="timepickerform">
                      <CLabel htmlFor="datev">
                        Date et heure de l'événement{" "}
                      </CLabel>
                      <DateTimePicker value={datev} onChange={setDatev} />
                      {submitted && !datev && (
                        <span className="invalid-feedback error">
                          Obligatoire
                        </span>
                      )}
                    </CFormGroup>
                  </CCol>
                </CRow>
                {type == 1 && category == 1 && (
                  <>
                    <CRow xs="12">
                      <CCol xs="6">
                        <CFormGroup className="timepickerform">
                          <CLabel htmlFor="nom">Heure de Séance 2 </CLabel>
                          <TimePicker onChange={setSeance2} value={seance2} />
                        </CFormGroup>
                      </CCol>
                      <CCol xs="6">
                        <CFormGroup>
                          <CLabel htmlFor="adresse">Heure de Séance 3 </CLabel>
                          <TimePicker onChange={setSeance3} value={seance3} />
                        </CFormGroup>
                      </CCol>
                    </CRow>
                  </>
                )}

                
                {tickets.map((ticket, rowIndex) => (
                  <CRow xs="12" key={rowIndex}>
                    <CCol xs="6">
                      <CFormGroup>
                        <CLabel htmlFor="typet">Type de ticket</CLabel>
                        <CInput
                          type="text"
                          name="typet"
                          value={ticket.type}
                          onChange={(e) =>
                            handleInputChange(
                              rowIndex,
                              "type",
                              e.target.value
                            )
                          }
                        ></CInput>
                        {submitted && tickets[0].type.length < 1 && (
                          <span className="invalid-feedback error">
                            Obligatoire
                          </span>
                        )}
                      </CFormGroup>
                    </CCol>
                    <CCol xs="6">
                      <CFormGroup>
                        <CLabel htmlFor="prixt">Prix de ticket</CLabel>
                        <CInput
                          type="number"
                          name="prixt"
                          min={1}
                          value={ticket.price}
                          onChange={(e) =>
                            handleInputChange(
                              rowIndex,
                              "price",
                              e.target.value
                            )
                          }
                        ></CInput>
                        {submitted && tickets[0].price.length < 1 && (
                          <span className="invalid-feedback error">
                            Obligatoire
                          </span>
                        )}
                      </CFormGroup>
                    </CCol>
                  </CRow>
                ))}

                {tickets.length < 4 && (
                  <>
                    {" "}
                    <CButton color="primary" onClick={addRow}>
                      Add Ticket Type
                    </CButton>
                    <br />
                    <br />
                  </>
                )}
              </>
            )}
            <Scroll/>
          </div>}

            <div className="advInfoSzctionCard" >
          <Adresse/>
       
          <CRow xs="12">
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="country">Pays</CLabel>
                <CSelect
                  name="country"
                  value={country}
                  onChange={(e) => handleCountryChange(e)}
                >
                  <option value="0"> Choisissez un pays</option>
                  {countries.map((country) => {
                    return (
                      <option key={country.id} value={country.id}>
                        {country.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !country && (
                  <span className="invalid-feedback error">Error</span>
                )}
              </CFormGroup>
            </CCol>
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="Région">Gouvernorat</CLabel>
                <CSelect
                  name="city"
                  value={city}
                  onChange={(e) => handleCityChange(e)}
                >
                  <option value="0"> Gouvernorat</option>
                  {cities.map((city) => {
                    return (
                      <option key={city.id} value={city.id}>
                        {city.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !city && (
                  <span className="invalid-feedback error">Error</span>
                )}
              </CFormGroup>
            </CCol>
          </CRow>

          <CRow xs="12">
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="delegate">Délégation</CLabel>
                <CSelect
                  name="delegate"
                  value={delegate}
                  onChange={(e) => handleDelegateChange(e)}
                >
                  <option value="0"> Choisissez une délégation</option>
                  {delegates.map((delegate) => {
                    return (
                      <option key={delegate.id} value={delegate.id}>
                        {delegate.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !delegate && (
                  <span className="invalid-feedback error">Error</span>
                )}
              </CFormGroup>
            </CCol>
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="loclité">Localité</CLabel>
                <CSelect
                  name="locality"
                  value={locality}
                  onChange={(e) => setLocality(e.target.value)}
                >
                  <option value="0"> Localité</option>
                  {localities.map((local) => {
                    return (
                      <option key={local.id} value={local.id}>
                        {local.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !locality && (
                  <span className="invalid-feedback error">Error</span>
                )}
              </CFormGroup>
            </CCol>
          
          {!(type == 3 || type == 4 || type == 5 || (type == 1 && category == 4)) && 

<CCol xs="6">
    <CFormGroup>
      <CLabel htmlFor="adresse">Adresse de l'évenement </CLabel>
      <CInput
        type="text"
        name="adresse"
        value={adresse}
        onChange={(e) => setAdresse(e.target.value)}
      ></CInput>
      {submitted && !adresse.length && (
        <span className="invalid-feedback error">
          Obligatoire
        </span>
      )}
    </CFormGroup>
  </CCol>
}</CRow>
<Scroll/>
          </div>

          <div className="advInfoSzctionCard" >
       <Contact/>
          <CRow xs="12">
          <CCol xs="6">
              {newPhone && <CFormGroup className='formPhoneNumber'>
                <CLabel htmlFor="phoneNumber">Numéro téléphone</CLabel>
                <PhoneInput
                  type="text"
                  onChange={(value) => setPhoneNumber(value)}
                  placeholder="Entrez un numéro de téléphone"
                  defaultCountry="TN"
                  international
                  name="phoneNumber"
                  id="phoneNumber"
                  value={phoneNumber}
                />
                 {submitted && newPhone && phoneNumber.length>0 && !isValidPhoneNumber(phoneNumber) && (
                  <span className="invalid-feedback error">Ce numéro de téléphone n'est pas valide</span>
                )}
              </CFormGroup>}
              <CFormGroup variant="checkbox" className="checkbox">
                <CInputCheckbox
                  onChange={changePhoneNumber}                 
                />
                <CLabel variant="checkbox" className="form-check-label">
                Utilisez le numéro téléphone de votre compte
                </CLabel>
              </CFormGroup> 
            </CCol>       
          </CRow>
          <Scroll/>
         </div>

         <div className="advInfoSzctionCard" >
     <Extra/>
          <CRow>
            <CCol>
              <CFormGroup>
                <CKEditor
                  editor={ClassicEditor}
                  data={description}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setDescription(data);
                  }}
                />
              </CFormGroup>
            </CCol>
          </CRow><br/>
          {type != 1 && type != 2 && <CCol xs="6"> 
            <CFormGroup style={{display:"inline-block"}}>
            {type == 3 && <CLabel htmlFor="menu">Télécharger le fichier de menu</CLabel>}
            {type == 4 && <CLabel htmlFor="menu">Télécharger l'affiche des tarifs</CLabel>}
            {type == 5 && <CLabel htmlFor="menu">Télécharger le liste de tarifs</CLabel>}
                <input 
                          type="file"
                          name="menu"
                          onChange={handleFileSection}
                          accept=".jpg,.png,jpeg,.pdf"
                           ></input>
            </CFormGroup>
            {uploadingImage &&<span style={{height:"fit-content",marginLeft:"10px",lineHeight:"90px"}}>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span>}
                               {!uploadingImage && menuThumb &&  <img style={{height: "85px", width: "auto"}}
          src={menuThumb}
          alt="preview"
          className="img-fluid img-thumbnail "
        />} 
            </CCol>}
          <Scroll/>
         </div>
 

         <div className="advInfoSzctionCard" >

          <Imgs/>
          <CRow>
            <CCol>
            <CFormGroup style={{display:"inline-block"}}>
                <CLabel htmlFor="pImage">Principal image</CLabel>
                <input 
                          type="file"
                          name="pImage"
                          onChange={handleFileSection}
                          accept="image/*"
                           ></input>
            </CFormGroup>
            {uploadingImage &&<span style={{height:"fit-content",marginLeft:"10px",lineHeight:"90px"}}>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span>}
                               {!uploadingImage && pImageThumb && 
                                <img style={{height: "85px", width: "auto"}}
          src={pImageThumb}
          alt="preview"
          className="img-fluid img-thumbnail "
        />} 
           {submitted && !imgPrincipal && (
                <span className="invalid-feedback error">
                  Veuillez uploader une image
                </span>
              )}
            </CCol>
          </CRow><br/>
          <br/>
          {imgCountA>0 && <> <div className="imgCounter"> {imgCount} {imgCount > 1 ? ' images téléchargées sur ' :' image téléchargée sur ' }  {imgCountA} </div>
       <div className="loading-bar">
          <div className="loading-bar-fill" style={{ width: `${imgCountA==0?0 : imgCount<=imgCountA ?(imgCount / imgCountA) * 100 :'100'}%` }}></div>
        </div><br/></>}
          <CRow>
            <CCol>
            <CLabel >Autres images</CLabel>
              <Dropzone
                // getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                // onSubmit={handleSubmitF}
                accept="image/*"
                maxFiles="9"
                maxSizeBytes={5 * 1024 * 1024}
              >
                <input />
              </Dropzone>
           
            </CCol>
          </CRow>
          <br />
          </div>
          {success && (
            <CAlert show={true} placement="top-end" color="success">
              {`L'annonce a été ajoutée avec succès !`}
            </CAlert>
          )}

          <CRow>
            <CCol md="3" className="offset-9">

{((!(doneuploadingfile && imgCountA <= album.length)) || uploadingImage)

  ? (
                <CButton color="primary" block disabled>
                  <span>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span>

                  <span> chargement des images</span>
                </CButton>
              )

         :  loading ? (
                <CButton color="primary" block disabled>
                  <span>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span>

                  <span> Valider</span>
                </CButton>
              )  
              :
                <CButton color="primary" block type="submit">
                  Valider
                </CButton>
              
}


              {/* {!loading && (
                <CButton color="primary" block type="submit">
                  Valider
                </CButton>
              )}

              {loading && (
                <CButton color="primary" block disabled>
                  <span>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span>

                  <span> Valider</span>
                </CButton>
              )} */}
            </CCol>
          </CRow>
        </form>
      </>
    );
  }
}
