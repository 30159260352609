import React, { Component } from "react";
import { API_BASE_URL , cat_news , tokenU} from "../../../config";
import axios from "axios";
import "./styleCkEditor.css";
import imageCompression from "browser-image-compression";
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CLabel,
  CCol,
  CContainer,
  CForm,
  CInput,
  CRow,
  CFormGroup,
  CSelect,
  CTextarea,
  CSpinner
} from "@coreui/react";
//import { CKEditor } from "@ckeditor/ckeditor5-react";
//import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CIcon from "@coreui/icons-react";
import { Editor } from "@tinymce/tinymce-react";
// import '@tinymce/tinymce-react';
// import 'tinymce/tinymce';
 import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'; 


export class AddNews extends Component {
  constructor(props) {
    super(props);

    this.state = {
      autoSaved: false,
      newsId: null,
      thisuserid: localStorage.getItem("id"),
      allowedcats: [],
      isloading: false,
      title: null,
      lastTitle:null,
      content: null,
      lastContent:null,
      createdBy: localStorage.getItem("id"),
      category: null,
      typeNews: null,
      categories: [],
      typeNewsSelect: [],
      image: null,
      imagePreviewUrl: "",
      youtubeLink: "",
      values:[]|| [{content:"", image:null,position:-1},{content:"", image:null,position:-1},{content:"", image:null,position:-1}],
      thumbs: [],
      files: [],
      uploadingImage:false,
      uploadingImages:[false,false,false],
      bodyImage1:null,
      bodyImage2:null,
      bodyImage0:null,
      sectionsIDs:[],
      sections: [{},{},{}],
      // chargement:false,

      errors: {
        title: "",
        content: "",
        category: "",
        typeNews: "",
        image: "",
      },
      success: "",
    };

      this.autosaveInterval = setInterval(this.autoSaveFunction, 30000);
  }
  

  componentDidMount() {

  }

  componentWillUnmount() {
    clearInterval(this.autosaveInterval);
  }



  handleCategChange = (e) => {

    this.setState({ typeNews: e.target.value
      , category:"" });
    let id = e.target.value;

    //console.log("this.state", this.state);

      if (localStorage.getItem("roles") == "ROLE_JOURNALIST") {
        var typecats = cat_news.filter(obj => (obj.type_news_id == id && localStorage.getItem("allowedCats").includes(obj.label)));
        //console.log('typecats',typecats);
        this.setState({ categories: typecats, });

      } else {
        var typecats = cat_news.filter(obj => obj.type_news_id == id);
        //console.log('typecats',typecats);
        this.setState({  categories: typecats, });
      }

      //setModels(categF.models);
      // console.log(categF);
    
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };



  handleChangeSection(i, event,editor) {
    let valuesContent = [...this.state.values];

    //const { name } = event.target;
    valuesContent[i]['content'] = editor.getData();
    this.setState({ values: valuesContent });
  }

  
  handleFileSection = async ( event, index) => {
    let tempuploading= this.state.uploadingImages;
    tempuploading[index]=true;
    this.setState({uploadingImages:tempuploading});
    //console.log('images at begining',index,this.state.values,event.target.files[0]);
    
    let valuesImages = [...this.state.values];

  //  const { name } = event.target;
    let file = event.target.files[0];
    let tempThumbs = this.state.thumbs;
  
    //console.log("file before");
    //console.log(file);

    let options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
    };
    try {
      file = await imageCompression(file, options);
      //console.log("file after");
      //console.log(file);
      // this.setState({
      //   thumbs: tempThumbs,
      // });
    } catch (error) {
      //console.log(error);
    }
    let c='';
  tempThumbs[index]=URL.createObjectURL(file);
valuesImages[index]={content:c,image:file,position:index};
if(index==0) this.setState({bodyImage0:file});
if(index==1) this.setState({bodyImage1:file});
if(index==2) this.setState({bodyImage2:file});
    //valuesImages[index]['image'] = file;
    // console.log(valuesImages[index][name])

    // this.setState({ values: [...valuesImages] });

    this.setState({
      uploadingImages:[false,false,false],
      thumbs: tempThumbs,
      files: [...this.state.files, file],
      values: valuesImages,
      
    });
  };

  autoSaveFunction = () => {
    //console.log('interval');
    //console.log(this.state);
    let newContent = this.state.content;
    let newTitle = this.state.title;
    let tabImage = [];
    if ((newTitle && newTitle.length>1 && newTitle!=this.state.lastTitle)||(newContent && newContent.length>1 && newContent!=this.state.lastContent) ) 
    {
    console.log("need to autosave");
    this.setState({ isloading:true });
      if(this.state.autoSaved && this.state.newsId)
       {
        console.log('00000');
        const formData = new FormData();           
        if(this.state.title) formData.append("title", this.state.title);
        if(this.state.content) formData.append("content", encodeURIComponent(this.state.content));
        formData.append("createdBy", tokenU);
        formData.append("category", this.state.category);
        formData.append("typeNews", this.state.typeNews);
        formData.append("youtubeLink", this.state.youtubeLink);
        //formData.append("status", this.state.status);
        //formData.append("alaUne", this.state.aLaUne);
        //formData.append("deux", this.state.deux);
     
        formData.append("autoSave", true);
     
         //console.log('content----- ', formData.get('content'));
    
        axios
          .post(`${API_BASE_URL}/news/edit/${this.state.newsId}`, formData)
          .then((res) => {
           // console.log(res);
            if (res.data.status.toString() === "ok") {
              this.setState({
                success: "autoSaved",
                isloading: false,
                lastContent:newContent,
                lastTitle:newTitle
              });
              setTimeout(() => {
                this.setState({
                  success: "",
                });
              }, 1500);
            } else {
       
             this.setState({ isloading: false });
  
            }
          })
          .catch((error) => {
            console.log(" errorsss : ", error);
            this.setState({ isloading: false });
  
          });
      


    } else {
      console.log('111111111');

      const formData = new FormData();

      //formData.append("dataFile", this.state.image /*, this.state.image.name*/);
      if(this.state.title) formData.append("title", this.state.title);
      if(this.state.content) formData.append("content",encodeURIComponent((this.state.content.replaceAll("'","\'"))));
      formData.append("createdBy", tokenU);
      formData.append("category", this.state.category);
      formData.append("typeNews", this.state.typeNews);
      formData.append("youtubeLink", this.state.youtubeLink);
      formData.append("autoSave", true);

      //console.log( "content ----- ");
      //console.log( formData.get("content"));

      axios
        .post(API_BASE_URL + "/news/add", formData)
        .then((res) => {
          //console.log('reeees',res);
          if (res.data.status.toString() === "ok") {
            this.setState({
              success: "autoSaved",
              autoSaved:true,      
              newsId:res.data.newsId,
              isloading: false,
            });
            setTimeout(() => {
              this.setState({
                success: "",
              });
            }, 1500);
          }
          
        })
        .catch((error) => {
          console.log(" errors : ", error);
          this.setState({
            isloading: false,
          });
          //console.log(this.state.isloading);
        });
    }
  } else {
    console.log("not need to autosave");

    }

  }

  handleSubmit = (event) => {
    event.preventDefault();
    //console.log(this.state);
    let tabImage = [];
    if (this.handleValidation()) {
     
      if(this.state.autoSaved) {
        console.log('222222');
          const formData = new FormData();
      
          if (this.state.image !== null) {
            formData.append("dataFile", this.state.image, this.state.image.name);
          }
          formData.append("title", this.state.title);
          formData.append("content", encodeURIComponent(this.state.content));
          formData.append("createdBy", tokenU);
          formData.append("category", this.state.category);
          formData.append("typeNews", this.state.typeNews);
          formData.append("youtubeLink", this.state.youtubeLink);
          if(this.state.bodyImage0) formData.append("bodyImage0", this.state.bodyImage0);
          if(this.state.bodyImage1) formData.append("bodyImage1", this.state.bodyImage1);
          if(this.state.bodyImage2) formData.append("bodyImage2", this.state.bodyImage2);        
       
          formData.append("autoSave", false);
       
    
          axios
            .post(`${API_BASE_URL}/news/edit/${this.state.newsId}`, formData)
            .then((res) => {
              console.log(res);
              if (res.data.status.toString() === "ok") {
                this.setState({
                  success: "L'actualité a été modifiée avec succès",
                  isloading: false,
                });
                window.scrollTo(0, 0);
                setTimeout(() => {
                  this.props.history.push("/5467854/news");
                }, 1500);
              } else {
                //console.log("not ok ",res);
               this.setState({ isloading: false });
    
              }
            })
            .catch((error) => {
              //console.log(" errorsss : ", error);
              this.setState({ isloading: false });
    
            });
        


      } else {
        console.log('333333');
      const formData = new FormData();

      formData.append("dataFile", this.state.image /*, this.state.image.name*/);
      formData.append("title", this.state.title);
      if(this.state.content) formData.append("content",encodeURIComponent((this.state.content.replaceAll("'","\'"))));
      formData.append("createdBy", tokenU);
      formData.append("category", this.state.category);
      formData.append("typeNews", this.state.typeNews);
      formData.append("youtubeLink", this.state.youtubeLink);
      formData.append("sections", JSON.stringify(this.state.values));
      formData.append("files", this.state.files);
      formData.append("autoSave", false);

      for (let index = 0; index < this.state.values.length; index++) {
        formData.append("images[]", this.state.values[index]["image"]);
        //  tabImage.push(this.state.values[index]['image'])
      }
      formData.append("tabImages", tabImage);
      //  //console.log(this.state.values[0]['image'])
      //console.log( "formData.get(images[])");
      //console.log( formData.get("images[]"));
      //console.log( "content ----- ");
      //console.log( formData.get("content"));

      axios
        .post(API_BASE_URL + "/news/add", formData)
        .then((res) => {
          console.log(res);
          if (res.data.status.toString() === "ok") {
            this.setState({
              success: "L'actualité a été ajoutée avec succès",
              title: "",
              content: "",
              category: "",
              typeNews: "",
              image: null,
              youtubeLink: "",
            });
            window.scrollTo(0, 0);
            setTimeout(() => {
              this.props.history.push("/5467854/news");
            }, 1500);
          }
          // this.props.history.push("/5467854/index");
          this.setState({
            isloading: false,
          });
        })
        .catch((error) => {
          console.log(" errors : ", error);
          this.setState({
            isloading: false,
          });
        });
      }
    } else {  
      this.setState({
          isloading: false
        });
    }
  };

  handleValidation =  () => {
    this.setState({
      isloading: true,
    });
    //console.log("vvv11111validation1");
    //console.log(this.state.isloading);
    let fieldTitle = this.state.title;
    let fieldContent = this.state.content;

    // let fieldCategory = this.state.category;
    let errors = {};
    let formIsValid = true;
    if (!fieldTitle) {
      formIsValid = false;
      errors.title = "Le titre de l'actualité ne peut pas être vide";
    } else {
      errors.title = "";
    }
    if (!fieldContent) {
      formIsValid = false;
      errors.content = "Le contenu de l'actualité ne peut pas être vide";
    } else {
      errors.content = "";
    }
    if (this.state.category === "") {
      formIsValid = false;
      errors.category = "Veuillez choisir la catégorie de l'actualité";
    } else {
      errors.category = "";
    }
    if (this.state.typeNews === "") {
      formIsValid = false;
      errors.typeNews = "Veuillez choisir le type de l'actualité";
    } else {
      errors.typeNews = "";
    }
    // if (this.state.image === null) {
    //   errors.image = "";
    //   // formIsValid = false;
    //   // errors.image = "Veuillez ajouter une image";
    // } else {
    //   errors.image = "";
    // }
    this.setState({ errors: errors });
    return formIsValid;
  };

  handleFile = async (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];
    //console.log(file);
    this.setState({uploadingImage:true});

    // new Compressor(file, {
    //   quality: 0.8,
    //   success: (compressedResult) => {
    // console.log(compressedResult);
    // file=compressedResult;
    // console.log(file.size);
    //   },
    // });
    let options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
    };
    try {
      file = await imageCompression(file, options);
      //console.log(file);
    } catch (error) {
      //console.log(error);
    }

    reader.onloadend = () => {
      this.setState({
        uploadingImage:false,
        image: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
    /* this.setState({
      image: event.target.files[0],
    });*/
  };

// handleFileSection = async ( event, index) => {
  //   console.log('images at begining',index,this.state.values,event.target.files[0]);
  // }

  // handleFileSection = async (index, event) => {
  //   let valuesImages = [...this.state.values];

  //   const { name } = event.target;
  //   let file = event.target.files[0];
  //   console.log("file before");
  //   console.log(file);

  //   let options = {
  //     maxSizeMB: 1,
  //     maxWidthOrHeight: 1920,
  //   };
  //   try {
  //     file = await imageCompression(file, options);
  //     console.log("file after");
  //     console.log(file);
  //   } catch (error) {
  //     console.log(error);
  //   }

  //   valuesImages[index][name] = file;
  //   // console.log(valuesImages[index][name])

  //   // this.setState({ values: [...valuesImages] });

  //   this.setState({
  //     files: [...this.state.files, file],
  //     values: valuesImages,
  //   });
  //   setTimeout(() => {
  //     console.log("state");
  //     console.log(this.state);
  //   }, 300);
  // };


  //   createSection() {
//     return this.state.values.map((el, i) => (
//       <div key={i} className="card card-accent-info">
//         <div className="card-header">
//           <CButton
//             type="button"
//             value="remove"
//             className="btn btn-outline-danger btn-sm"
//             onClick={this.removeClick.bind(this, i)}
//           >
//              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" viewBox="0 0 128 128">
// <path d="M 49 1 C 47.34 1 46 2.34 46 4 C 46 5.66 47.34 7 49 7 L 79 7 C 80.66 7 82 5.66 82 4 C 82 2.34 80.66 1 79 1 L 49 1 z M 24 15 C 16.83 15 11 20.83 11 28 C 11 35.17 16.83 41 24 41 L 101 41 L 101 104 C 101 113.37 93.37 121 84 121 L 44 121 C 34.63 121 27 113.37 27 104 L 27 52 C 27 50.34 25.66 49 24 49 C 22.34 49 21 50.34 21 52 L 21 104 C 21 116.68 31.32 127 44 127 L 84 127 C 96.68 127 107 116.68 107 104 L 107 40.640625 C 112.72 39.280625 117 34.14 117 28 C 117 20.83 111.17 15 104 15 L 24 15 z M 24 21 L 104 21 C 107.86 21 111 24.14 111 28 C 111 31.86 107.86 35 104 35 L 24 35 C 20.14 35 17 31.86 17 28 C 17 24.14 20.14 21 24 21 z M 50 55 C 48.34 55 47 56.34 47 58 L 47 104 C 47 105.66 48.34 107 50 107 C 51.66 107 53 105.66 53 104 L 53 58 C 53 56.34 51.66 55 50 55 z M 78 55 C 76.34 55 75 56.34 75 58 L 75 104 C 75 105.66 76.34 107 78 107 C 79.66 107 81 105.66 81 104 L 81 58 C 81 56.34 79.66 55 78 55 z"></path>
// </svg>
//                              <strong> Supprimer la section</strong>
//           </CButton>
//         </div>

//         <div xs="6" key={i} className="card-body">
//           <CFormGroup>
//             <CLabel
//             //  htmlFor="content"
//             >Contenu section</CLabel>
//             <ReactQuill theme="snow" value={el.content} 
//                           onChange={(content) => {
//                             let valuesContent = [...this.state.values];
//                             valuesContent[i]['content'] = content;
//                             this.setState({ values: valuesContent });}
//                           }
//                           modules={ {
//                             toolbar: [
//                               [{ 'header': '1' }, { 'header': '2' }],
//                               [{ 'list': 'ordered' }, { 'list': 'bullet' }],
//                               ['bold', 'italic', 'underline', 'strike'],
//                               ['blockquote', 'code-block'],
//                               [{ 'color': [] }, { 'background': [] }],
//                               [{ 'align': [] }],
//                              // [{ 'table': 'true' }],
//                               ['link'],
//                               ['clean']
//                             ],
//                            // table:true,
//                           }}
//                           formats={[
//                             'header', 'list', 'bullet', 'bold', 'italic', 'underline', 'strike',
//                             'blockquote', 'code-block', 'color', 'background', 'align', 'link'
//                           ]}
//                           /> 
//                            {/* const data = editor.getData();
//                             this.setState({
//                               content: data,
//                             });
//                           */}
//                                 {/* {(this.state.values[i].image || this.state.values[i].image==null ) &&   <CKEditor
//                           editor={ClassicEditor}
//                           data={this.state.values[i]}
//                           onChange={(event, editor) => {
//                             let valuesContent = [...this.state.values];
//                             valuesContent[i]['content'] = editor.getData();
//                             this.setState({ values: valuesContent });
//                           }}
//                           config={{
//                             toolbar: [
//                               'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList',
//                               '|', 'indent', 'outdent', '|', 'imageUpload', 'blockQuote', 'insertTable',
//                                'undo', 'redo'
//                             ],
//                           }}
//                         /> } */}
// {/* 
//               <Editor
//               id={i}
//                       apiKey="hf1jaliai3snptyx6rfxy0eyb120vcqrvtvzvuenn82j1xdj"
//                       onEditorChange={ (content, editor) => {
//                         let valuesContent = [...this.state.values];
//                         valuesContent[i]['content'] = content;
//                         this.setState({ values: valuesContent });
//                       }}
//                       init={{
//                         height: 300,
//                         menubar: false,
//                         plugins: [
//                           'advlist', 'autolink', 'lists', 'link' , 'charmap', 'preview',
//                           'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
//                           'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
//                         ],
//                         toolbar: 'undo redo | ' +
//                           'formatselect | ' +
//                           'bold italic underline strikethrough | ' +
//                           'alignleft aligncenter alignright alignjustify | ' +
//                           'bullist numlist outdent indent | ' +
//                           'blockquote subscript superscript removeformat | ' +
//                           'link media table charmap emoticons hr pagebreak | ' +
//                           'searchreplace visualblocks code fullscreen | ' +
//                           'insertdatetime | ' +
//                           'forecolor backcolor | ' +
//                           'help',
//                         content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
//                       }}                                
//                      /> */}

//           </CFormGroup>
//           <CFormGroup>
//             <CLabel htmlFor="image">Image</CLabel>
//             <CInput
//               type="file"
//               name="image"
//               onChange={this.handleFileSection.bind(this, i)}
//             />
//           </CFormGroup>
//         </div>
//       </div>
//     ));
//   }

  // addClick() {
  //   this.setState((prevState) => ({
  //     values: [...prevState.values, { content: "", image: null }],
  //   }));
  // }

  // removeClick(i) {
  //   let values = [...this.state.values];
  //   values.splice(i, 1);
  //   this.setState({ values });
  //   let files = [...this.state.files];
  //   files.splice(i, 1);
  //   this.setState({ files });
  //   setTimeout(() => {
  //     console.log(this.state);
  //   }, 50);
  // }

  render() {
    let errors = this.state.errors;
    this.uploadInput = this.uploadInput || [];
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = (
        <img
          src={imagePreviewUrl}
          alt="preview"
          width="150"
          className="img-fluid img-thumbnail "
        />
      );
    }
    return (
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="12" lg="12" xl="12">
            <CCard className="mx-4">
              <CCardBody className="p-4">
                {this.state.success !== "" ? (
                  <CAlert color="success" closeButton>
                    {this.state.success}
                  </CAlert>
                ) : (
                  ""
                )}
                <CForm onSubmit={this.handleSubmit} id="form-add">
                  <h1>Ajouter un Article</h1>
                  <CCol>
                    <p></p>
                  </CCol>

                  <CRow>
                    <CCol xs="8">
                      <CFormGroup>
                        <CLabel htmlFor="title">Titre</CLabel>
                        <CInput
                          id="title"
                          placeholder="Entrer le titre d'actualité"
                          name="title"
                          value={this.state.title}
                          onChange={this.handleChange}
                        />
                      </CFormGroup>
                    </CCol>
                  </CRow>
                  <CCol>
                    {errors.title.length > 0 && (
                      <p
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.title}
                      </p>
                    )}
                  </CCol>
                  <CRow>
                    <CCol xs="8">
                      <CFormGroup>
                        <CLabel htmlFor="content">Type de l'actualité</CLabel>
                        <CSelect
                          name="typeNews"
                          value={this.state.typeNews}
                          onChange={this.handleCategChange}
                        >
                          <option value="">--------------------</option>
                         
                              <option
                                id={1}
                                value="1"
                              >
                                Highlight
                              </option>
                              <option
                                id={2}
                                value="2"
                              >
                               12 M conso
                              </option>
                            
                     
                        </CSelect>
                      </CFormGroup>
                    </CCol>
                  </CRow>
                  <CCol>
                    {errors.typeNews.length > 0 && (
                      <p
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.typeNews}
                      </p>
                    )}
                  </CCol>
                  <CRow>
                    <CCol xs="8">
                      <CFormGroup>
                        <CLabel htmlFor="content">
                          Catégorie de l'actualité
                        </CLabel>
                        <CSelect
                          name="category"
                          value={this.state.category}
                          onChange={this.handleChange}
                        >
                          <option value="">--------------------</option>
                          {this.state.categories.map((category) => {
                            return (
                              <option
                                key={category.id}
                                id={category.id}
                                value={category.id}
                              >
                                {category.label}
                              </option>
                            );
                          })}
                        </CSelect>
                      </CFormGroup>
                    </CCol>
                  </CRow>
                  <CCol>
                    {errors.category.length > 0 && (
                      <p
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.category}
                      </p>
                    )}
                  </CCol>
                  <CRow>
                    <CCol xs="8">
                      <CFormGroup>
                        <CLabel htmlFor="content">News main Image</CLabel>
                        <CInput
                          type="file"
                          name="image"
                          onChange={this.handleFile}
                          accept=".jpg,.png,jpeg"
                        />
                      </CFormGroup>
                    </CCol>
                    {this.state.uploadingImage && <span style={{height:"fit-content",marginLeft:"10px",lineHeight:"90px"}}>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span>}
                    {$imagePreview}
                  </CRow><br/>
                  <CRow>
                    <CCol xs="12">
                      <CFormGroup>
                        <CLabel htmlFor="content">News Contenu</CLabel>
                        {/* <ReactQuill
        //value={editorHtml}
        //onChange={}
        modules={{
          toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            ['link', 'image', 'video', 'formula', 'clean'],
            [{ 'align': [] }, { 'direction': 'rtl' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            ['code-block'],
            [{ 'color': [] }, { 'background': [] }],
            ['font'],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            ['header'],
            ['blockquote'],
          ],
        }}
/> */}
<p className="sectionEndImage" onClick={()=>  {
  let temp = this.state.content;
 // temp+='<p style="background: #2eb85c;padding: 4px;border-radius: 5px;color: white;width: fit-content;">new section image here</p><br/>';
temp+='<img src="image-reference-label.png"/>'
  this.setState({
  content: temp
});
}}>Add section image reference</p>

                        <Editor
                      value={this.state.content}
                      apiKey="hf1jaliai3snptyx6rfxy0eyb120vcqrvtvzvuenn82j1xdj"
                      onEditorChange={ (content, editor) => {
                        this.setState({
                          content: content,
                        });
                      }}
                      init={{
                        height: 300,
                        menubar:false,
                        plugins: [
                          'advlist', 'autolink', 'lists', 'link' , 'charmap', 'preview',
                          'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                          'insertdatetime', 'table', 'code', 'help', 'wordcount'
                        ],
                        toolbar: 'undo redo | ' +
                          'formatselect | ' +
                          'bold italic underline strikethrough | ' +
                          'alignleft aligncenter alignright alignjustify | ' +
                          'bullist numlist outdent indent | ' +
                          'blockquote subscript superscript removeformat | ' +
                          'link table charmap emoticons hr pagebreak | ' +
                          'searchreplace visualblocks code fullscreen | ' +
                          'insertdatetime | ' +
                          'forecolor backcolor | ' +
                          'help'
                      }}                                
                     />

                        {/* <CKEditor
                          editor={ClassicEditor}
                          data={this.state.content}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            this.setState({
                              content: data,
                            });
                          }}
                          config={{
                            toolbar: [
                              'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList',
                              '|', 'indent', 'outdent', '|', 'imageUpload', 'blockQuote', 'insertTable',
                              'mediaEmbed', 'undo', 'redo'
                            ],
                          }}
                        /> */}
                      </CFormGroup>
                    </CCol>
                  </CRow>
                  <CCol>
                    {errors.content.length > 0 && (
                      <p
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.content}
                      </p>
                    )}
                  </CCol>
             
                 
                  <CRow>
                    <CCol xs="8">
                      <CFormGroup>
                        <CLabel htmlFor="content">Body Image 1</CLabel>
                        <CInput
                          type="file"
                          name="image"
                          onChange={(event)=>this.handleFileSection(event, 0)}
                          accept=".jpg,.png,jpeg"
                        />
                      </CFormGroup>
                    </CCol>
                    {this.state.uploadingImages[0] && <span style={{height:"fit-content",marginLeft:"10px",lineHeight:"90px"}}>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span>}
                    {this.state.thumbs[0] && this.state.thumbs[0].length>0 && <img
          src={this.state.thumbs[0]}
          alt="preview"
          width="150"
          className="img-fluid img-thumbnail "
        />}
                    
                  </CRow>

                  <CRow>
                    <CCol xs="8">
                      <CFormGroup>
                        <CLabel htmlFor="content">Body Image 2</CLabel>
                        <CInput
                          type="file"
                          name="image"
                          onChange={(event)=>this.handleFileSection(event, 1)}
                          accept=".jpg,.png,jpeg"
                        />
                      </CFormGroup>
                    </CCol>
                    {this.state.uploadingImages[1] && <span style={{height:"fit-content",marginLeft:"10px",lineHeight:"90px"}}>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span>}
                    {this.state.thumbs[1] && this.state.thumbs[1].length>0 && <img
          src={this.state.thumbs[1]}
          alt="preview"
          width="150"
          className="img-fluid img-thumbnail "
        />}
                  </CRow>

                  <CRow>
                    <CCol xs="8">
                      <CFormGroup>
                        <CLabel htmlFor="content">Body Image 3</CLabel>
                        <CInput
                          type="file"
                          name="image"
                          onChange={(event)=>this.handleFileSection(event, 2)}
                          accept=".jpg,.png,jpeg"
                        />
                      </CFormGroup>
                    </CCol>
                    {this.state.uploadingImages[2] && <span style={{height:"fit-content",marginLeft:"10px",lineHeight:"90px"}}>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span>}
                    {this.state.thumbs[2] && this.state.thumbs[2].length>0 && <img
          src={this.state.thumbs[2]}
          alt="preview"
          width="150"
          className="img-fluid img-thumbnail "
        />}
                  </CRow>

                  <CRow>
                    <CCol xs="8">
                      <CFormGroup>
                        <CLabel htmlFor="content">Lien youTube</CLabel>
                        <CInput
                          type="text"
                          name="youtubeLink"
                          onChange={this.handleChange}
                        />
                      </CFormGroup>
                    </CCol>
                  </CRow>



{/* 
                  <CCol>
                    {errors.image.length > 0 && (
                      <p
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.image}
                      </p>
                    )}
                  </CCol> */}

                  {/* {this.createSection()}
                  <CCol>
                    <CFormGroup>
                      <CButton
                        className="btn btn-outline-info btn-sm"
                        type="button"
                        value="add more"
                        onClick={this.addClick.bind(this)}
                      >
                        <CIcon name="cilPlus" /> Ajouter une section
                      </CButton>
                    </CFormGroup>
                  </CCol> */}

                  <CRow>
                    <CCol xs="12" sm="3">
                      {!this.state.isloading && (
                <CButton color="success" block type="submit">
                  <span>Valider</span>
                </CButton>
              )}

              {this.state.isloading && (
                <CButton color="success" block disabled>
                  <span>
                    <CSpinner color="default" size="sm" />
                  </span>
                  <span> Valider</span>
                </CButton>
              )}
                    </CCol>
                  </CRow>
                </CForm><br/>
                {this.state.success !== "" ? (
                  <CAlert color="success" closeButton>
                    {this.state.success}
                  </CAlert>
                ) : (
                  ""
                )}
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    );
  }
}

export default AddNews;
