import React, { useEffect, useState , useContext} from "react";
import { tokenCookie  , keyHeader ,API_BASE_URL , IMAGES_BASE_URL} from "../../../config";
import axios from "axios";
import Moment from "react-moment";
import Loading from "src/components/myWidgets/loadingWidget";
import { Link } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import { getBadge , textBadge } from "src/actions";
import {
  CBadge,
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CSpinner, CDataTable
} from "@coreui/react";
import MemberContext from "./MemberContext";

function MyVehicles(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [modal, setModal] = useState(false);
  const [item, setItem] = useState(null);
  const [errorDel, setErrorDel] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const {vehicles,loadedVehicles , setVehicles} = useContext(MemberContext);


  const toggle = (id) => {
    console.log("id vehicule ", id);
    setModal(!modal);
    setItem(id);
  };
  function deleteCatNews(id) {
    setIsLoading(true);

    axios
      .delete(`${API_BASE_URL}/vehicle/delete/${id}`,keyHeader)
      .then((res) => {
        toggle();
        console.log(res);
        if (res.data.status.toString() === "ok") {
          setItems((items) => items.filter((x) => x.id !== id));
          setVehicles((items) => items.filter((x) => x.id !== id));
          setIsLoading(false);
        }
        if (
          res.data.status.toString() ===
          "ForeignKeyConstraintViolationException"
        ) {
          setErrorDel("Vous ne pouvez pas supprimer cette véhicule");
          setIsLoading(false);
        }
      })
      .catch((err) =>{
        //console.log(err);
        setErrorDel("Vous ne pouvez pas supprimer cet bon plans maintenant");
      setIsLoading(false);} );
  }
  useEffect(() => {
    if(loadedVehicles) 
    {setItems(vehicles);
      setIsLoaded(true)
      //console.log('props vehicles 2 <<<<<< ', vehicles.length);   
    }

  //   if (props.location.state) {
  //     setIsLoaded(true);
  //     setItems(props.location.state);
  //   }
  //     else {
  // const formData = new FormData();
  // formData.append("key",tokenCookie );
  // axios.post(`${API_BASE_URL}/vehicle/By_member`, formData, keyHeader)
  //     .then(response => {
  //       //console.log('response --- ', response);
  //       setIsLoaded(true);
  //       setItems(response.data.items);
       
  //     })
  //     .catch(error => {
  //       setIsLoaded(true);
  //       setError(error);
  //         console.log('error --- ', error)

  //     });
  //   }

    // axios(`${API_BASE_URL}/vehicle/By_member/${localStorage.getItem("id")}`)
    //   .then((res) => res.data)
    //   .then(
    //     (result) => {
    //       setIsLoaded(true);
    //       setItems(result);
    //       console.log(result);
    //     },
    //     (error) => {
    //       setIsLoaded(true);
    //       setError(error);
    //     }
    //   );
  }, [vehicles,loadedVehicles]);

  const fields = [
    {
      key: "thumb",
      label: "thumb",
    },
    {
      key: "Actions",
      label: "Actions",
    },
  ];

  if (error) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div>Erreur : {error.message}</div>
          </div>
        </main>
      </div>
    );
  } else if (!isLoaded) {
    return (
      <Loading/>
    );
  } else if (!items.length) {
    return (
      <div className="container">
      <main className="l-main">
        <div className="l-section l-section--archive">
          <div className="noItemsText">
          Il n'y a pas encore d'annonces ici <br/><br/>
          <Link to='/vehicles/add'>
          <button className="ml-1 btn btn-info" type="button">
          commencez à ajouter des annonces maintenant
          </button></Link>
          </div>
        </div>
      </main>
    </div>

    )}
  else {
    return (
      <>


        <CDataTable addTableClasses="table table-hover table-outline mb-0  d-sm-table"
                    items={items}
                    fields={fields}
                    itemsPerPageSelect
                    itemsPerPage={10}
                    hover
                    pagination
                    scopedSlots={{
                      thumb: (vehicle) => (
                        <td>
                    <div className="vehicle-thumbnail">
                    <img
                          src={
                            vehicle.mainPicture
                              ? `${IMAGES_BASE_URL}/public/uploads/images/${vehicle.type}/${vehicle.mainPicture.name}`
                              : ""
                          }
                          alt=""
                        />
                    </div>
                    <div className="profileAnnonceCardDetails">
                    <strong>
                      <div>
                        {vehicle.brand && vehicle.brand.label} - {" "}
                        {vehicle.model && vehicle.model.label ? vehicle.model.label
                        :vehicle.model ? vehicle.model
                        :vehicle.category ? vehicle.category.label
                        :''} <CBadge color={getBadge(vehicle.status)}>
                            {textBadge(vehicle.status)}
                          </CBadge>
                      </div>
           
                  </strong>
                  <div>{vehicle.price} TND</div>
                  <div>
                     <strong>Numéro de série :</strong> 
                     <span> {vehicle.sn}</span> 
                    </div>
                    <div>
                     <strong>Date d'ajout :{" "}</strong> 
                     <span><Moment format="DD-MM-YYYY">{vehicle.createdAt}</Moment></span> 
                    </div>
                    </div>
                        </td>
                      ),
                      Actions: (vehicle) => (
                        <td>
                  <Link to= {{ pathname:`/members/profile/myItemDetails/vehicle`,
                         state:vehicle}}
                  >
                  <CButton>
              <img src="assets/icons/eye.svg" />
            </CButton></Link>
                    <Link to={{ pathname:'/members/profile/vehicules-edit',
                         state:vehicle}}
                    >
                      <CButton>
                      <CIcon name="cilPencil" />                     
                      </CButton>
                    </Link>
                    <CButton
                      onClick={(e) => toggle(vehicle.id)}
                    >
                      <CIcon name="cilTrash" />
                    </CButton>
                  
                       </td>
                      )
                    }}
                  />

        <CModal show={modal} onClose={toggle}>
          <CModalHeader closeButton></CModalHeader>
          <CModalBody>Voulez-vous vraiment supprimer ce véhicule ?</CModalBody>
          <CModalFooter>
            {/* <CButton
                                  color="primary"
                                  onClick={() => {
                                    deleteCatNews(item);
                                  }}
                                >
                                  Oui
                                </CButton> */}

            {!isLoading && (
              <CButton
                color="primary"
                onClick={() => {
                  deleteCatNews(item);
                }}
              >
                Oui
              </CButton>
            )}
            {isLoading && (
              <CButton color="primary" disabled>
                <span>
                  <CSpinner color="default" size="sm" />
                </span>

                <span> Oui</span>
              </CButton>
            )}

            <CButton color="secondary" onClick={toggle}>
              Cancel
            </CButton>
          </CModalFooter>
        </CModal>
      </>
    );
  }
}

export default MyVehicles;
