import React, { Component } from "react";
import { API_BASE_URL } from "../../../config";
import axios from "axios";
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CLabel,
  CCol,
  CContainer,
  CForm,
  CInput,
  CRow,
  CFormGroup,
} from "@coreui/react";


export class AddTypeNews extends Component {
  constructor(props) {
    super(props);

    this.state = {
      label: "",
      createdBy: localStorage.getItem("id"),
      errors: {
        label: "",
      },
      success: "",
    };
  }

  handleChange = (event) =>
    this.setState({
      [event.target.name]: event.target.value,
    });

  handleValidation = () => {
    let fields = this.state.label;
    let errors = {};
    let formIsValid = true;
    if (!fields) {
      formIsValid = false;
      errors["label"] = "Le libéllé ne peut pas être vide";
    } else {
      errors["label"] = "";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };
  handleSubmit = (event) => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.setState({
        createdBy: localStorage.getItem("id"),
      });
      
      axios
        .post(API_BASE_URL + "/type-news/add", this.state)
        .then((res) => {
          if (res.data.status.toString() === "Ce type d'actualité existe déjà") {
            this.setState({
              errors: {
                label: "Ce type d'actualité existe déjà",
              },
            });
          }
          if (res.data.status.toString() === "ok") {
            this.setState({
              success: "Le type d'actualité été ajouté avec succès",
              label:'',
            });
            
           
          }
          // this.props.history.push("/5467854/index");
        })
        .catch((error) => {
          console.log("login errors : ", error);
        });
    }
  };
  render() {
    let errors = this.state.errors;
    return (
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="12" lg="12" xl="12">
            <CCard className="mx-4">
              <CCardBody className="p-4">
              {this.state.success !== "" ? (
                    <CAlert color="success" closeButton>
                      {this.state.success}
                    </CAlert>
                  ) : (
                    ""
                  )}
                <CForm onSubmit={this.handleSubmit} id="form-add">
                  <h1>Ajouter un nouveau type d'actualité</h1>
                  <CCol>
                    <p></p>
                  </CCol>
                  
                  <CRow>
                    <CCol xs="6">
                      <CFormGroup>
                        <CLabel htmlFor="label">Type d'actualité</CLabel>
                        <CInput
                          id="label"
                          placeholder="Entrer le libellé de type d'actualité"
                          name="label"
                          value={this.state.label}
                          onChange={this.handleChange}
                        />
                      </CFormGroup>
                    </CCol>
                  </CRow>
                  <CCol>
                    {errors.label.length > 0 && (
                      <p
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.label}
                      </p>
                    )}
                  </CCol>
                  <CRow>
                    <CCol xs="12" sm="3">
                      <CButton color="success" block type="submit">
                        Valider
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    );
  }
}

export default AddTypeNews;
