import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../../../config";
import {
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CButton,
  CCollapse,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CAlert,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { Link } from "react-router-dom";
import axios from "axios";


export default function IndexEquipement() {
  const [modal, setModal] = useState(false);
  const [error, setError] = useState(null);
  const [errorDel, setErrorDel] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [details, setDetails] = useState([]);


  const toggle = () => {
    setModal(!modal);
  };

  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let equipementDetails = details.slice();
    if (position !== -1) {
      equipementDetails.splice(position, 1);
    } else {
     // newDetails = [...details, index];
     equipementDetails = [details, index];
    }
    setDetails(equipementDetails);
  };
  function deleteEquipement(id) {
    //const { id } = this.props.match.params;
    toggle();
    axios
      .delete(`${API_BASE_URL}/equipement/delete/${id}`)
      .then((res) => {
        if (res.data.status.toString() === "ok") {
          setItems((items) => items.filter((x) => x.id !== id));
        }
        if (
          res.data.status.toString() ===
          "ForeignKeyConstraintViolationException"
        ) {
          setErrorDel("Vous ne pouvez pas supprimer cette equipement");
        }
      })
      .catch((err) => console.log(err));
  }

  function publishNews(id,statusP){
   const statut = {status: statusP}
  
    axios
    .post(`${API_BASE_URL}/news/publish/${id}`,statut)
    .then((res) => {
      if (res.data.status.toString() === "ok") {
       // window.location.reload(false);
     setItems((items) =>{
      axios.get(API_BASE_URL + "/news",{params:{userId:localStorage.getItem('id')}})
      .then((res) => res.data)
      .then(
        (result) => {
          setIsLoaded(true);

          setItems(result);
        },
        // Remarque : il faut gérer les erreurs ici plutôt que dans
        // un bloc catch() afin que nous n’avalions pas les exceptions
        // dues à de véritables bugs dans les composants.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
      });
       
      
      }
      if (
        res.data.status.toString() ===
        "ForeignKeyConstraintViolationException"
      ) {
        setErrorDel("Vous ne pouvez pas supprimer cette actualité");
      }
    })
    .catch((err) => console.log(err));
  }

  const fields = [
    {
      key: "label",
      label: "libellé",
      _style: { width: "20%" },
    },
    {
      key: "type",
      label: "type",
      _style: { width: "20%" },
    },
    { key: "type_cannonce", _style: { width: "20%" }, label: "Type annonce" },

    {
      key: "show_details",
      label: "Actions",
      _style: { width: "1%" },
      sorter: false,
      filter: false,
    },
  ];
  // Remarque : le tableau vide de dépendances [] indique
  // que useEffect ne s’exécutera qu’une fois, un peu comme
  // componentDidMount()
  useEffect(() => {
    axios.get(API_BASE_URL + "/equipements/all")
      .then((res) => res.data)
      .then(
        (result) => {
          setIsLoaded(true);

          setItems(result);
        },
        // Remarque : il faut gérer les erreurs ici plutôt que dans
        // un bloc catch() afin que nous n’avalions pas les exceptions
        // dues à de véritables bugs dans les composants.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  if (error) {
    return <div>Erreur : {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Chargement...</div>;
  } else {
    return (
      <div>
        <CRow>
          <CCol xl={12}>
            <Link
              className="btn btn-outline-primary btn-lg "
              to="/5467854/setting/add-equipement"
            >
              {" "}
              <CIcon name="cilNotes" /> Ajouter un équipement
            </Link>
            <p></p>
            <CCard>
              <CCardHeader>Équipements</CCardHeader>
              <CCardBody>
                {errorDel !== "" ? (
                  <CAlert color="danger" closeButton>
                    {errorDel}
                  </CAlert>
                ) : (
                  ""
                )}
                <CDataTable
                  items={items}
                  fields={fields}
                  columnFilter
                  tableFilter
                  footer
                  itemsPerPageSelect
                  itemsPerPage={10}
                  hover
                  sorter
                  pagination
                  scopedSlots={{
                    label: (item) => (
                      <td>
                        {item.label} 

                      </td>
                      
                    ),
                    type: (item) => (
                      <td>
                        {item.type.label} 

                      </td>
                      
                    ),
                    type_cannonce: (item) => (
                      <td>
                        
                        {item.type_cannonce} 
                        

                      </td>
                      
                    ),
                    show_details: (item, index) => {
                      return (
                        <td className="py-2">
                          <CButton
                            color="primary"
                            variant="outline"
                            shape="square"
                            size="sm"
                            onClick={() => {
                              toggleDetails(index);
                            }}
                          >
                            {details.includes(index) ? "Hide" : "Show"}
                          </CButton>
                        </td>
                      );
                    },
                    details: (item, index) => {
                      return (
                        <CCollapse show={details.includes(index)}>
                          <CCardBody>
                            <h4>{item.label}</h4>
                            <Link to={`/5467854/equipement/show/${item.id}`}>
                              <CButton size="sm" color="primary">
                                Voir détail
                              </CButton>
                            </Link>
                           
                            <Link to={`/5467854/equipement/edit/${item.id}`}>
                              <CButton size="sm" color="info" className="ml-1">
                                Modifier
                              </CButton>
                            </Link>
                            <CButton
                              size="sm"
                              color="danger"
                              className="ml-1"
                              onClick={toggle}
                            >
                              Supprimer
                            </CButton>
                            <CModal show={modal} onClose={toggle}>
                              <CModalHeader closeButton></CModalHeader>
                              <CModalBody>
                                Voulez vous vraiment supprimer ce type
                                d'actualité ?
                              </CModalBody>
                              <CModalFooter>
                                <CButton
                                  color="primary"
                                  onClick={() => {
                                    deleteEquipement(item.id);
                                    toggleDetails(index);
                                  }}
                                >
                                  Oui
                                </CButton>

                                <CButton color="secondary" onClick={toggle}>
                                  Cancel
                                </CButton>
                              </CModalFooter>
                            </CModal>
                          </CCardBody>
                        </CCollapse>
                      );
                    },
                  }}
                />
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </div>
    );
  }
}
