import React, { useEffect, useState , useContext } from "react";
import MemberContext from "./MemberContext";

import { tokenCookie , keyHeader , API_BASE_URL , IMAGES_BASE_URL} from "../../../config";
import { getBadge , textBadge } from "src/actions";
import axios from "axios";
import Moment from "react-moment";
import Loading from "src/components/myWidgets/loadingWidget";
import { Link } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import {
  CBadge,
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CSpinner, CDataTable
} from "@coreui/react";
function MyServices(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [modal, setModal] = useState(false);
  const [item, setItem] = useState(null);
  const [errorDel, setErrorDel] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const {services,loadedServices , setServices} = useContext(MemberContext);

  const toggle = (id) => {
    setModal(!modal);
    setItem(id);
  };
  function deleteService(id) {
    setIsLoading(true);
    axios
      .delete(`${API_BASE_URL}/service/delete/${id}`,keyHeader)
      .then((res) => {
        toggle();
        //console.log(res);
        if (res.data.status.toString() === "ok") {
          setItems((items) => items.filter((x) => x.id !== id));
          setServices((items) => items.filter((x) => x.id !== id));
          setIsLoading(false);
        }
        if (
          res.data.status.toString() ===
          "ForeignKeyConstraintViolationException"
        ) {
          setErrorDel("Vous ne pouvez pas supprimer cet service");
          setIsLoading(false);
        }
      })
      .catch((err) =>{
        //console.log(err);
        setErrorDel("Vous ne pouvez pas supprimer cet bon plans maintenant");
      setIsLoading(false);} );
  }
  useEffect(() => {

    if(loadedServices) 
    {setItems(services);
      setIsLoaded(true)
      //console.log('props services 2 <<<<<< ', services.length);   
    }
  //   if (props.location.state) {
  //     setIsLoaded(true);
  //     setItems(props.location.state);
  //   }
  //     else {
  // const formData = new FormData();
  // formData.append("key",tokenCookie );
  // axios.post(`${API_BASE_URL}/services/By_member`, formData, keyHeader)
  //     .then(response => {
  //       //console.log('response --- ', response);
  //       setIsLoaded(true);
  //       setItems(response.data.items);
       
  //     })
  //     .catch(error => {
  //       setIsLoaded(true);
  //       setError(error);
  //         console.log('error --- ', error)

  //     });
  //   }
    // axios(`${API_BASE_URL}/services/By_member/${localStorage.getItem("id")}`)
    //   .then((res) => res.data)
    //   .then(
    //     (result) => {
    //       console.log(result);
    //       setIsLoaded(true);
    //       setItems(result);
    //     },
    //     (error) => {
    //       setIsLoaded(true);
    //       setError(error);
    //     }
    //   );
  }, [services,loadedServices]);

  const fields = [
    {
      key: "thumb",
      label: "thumb",
    },
    {
      key: "Actions",
      label: "Actions",
    },
  ];

  if (error) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div>Erreur : {error.message}</div>
          </div>
        </main>
      </div>
    );
  } else if (!isLoaded) {
    return (
      <Loading/>
       );
     } else if (!items.length) {
       return (
         <div className="container">
         <main className="l-main">
           <div className="l-section l-section--archive">
             <div className="noItemsText">
             Il n'y a pas encore d'annonces ici <br/><br/>
             <Link to='/services/add'>
             <button className="ml-1 btn btn-info" type="button">
             commencez à ajouter des annonces maintenant
             </button></Link>
             </div>
           </div>
         </main>
       </div>
   
       )}
      else {
    return (
      <>
        {/* <table className="table table-hover table-outline mb-0  d-sm-table">
          <thead className="thead-light">
            <tr>
              <th></th>

              <th className="text-center"></th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {items.map((service, index) => {
              return (
                <tr key={index}>
                  <td>
                    <div className="vehicle-thumbnail">
                      <img
                        src={
                          service.mainPicture
                            ? `${IMAGES_BASE_URL}/public/uploads/images/service/${service.mainPicture.name}`
                            : ""
                        }
                        alt=""
                      />
                    </div>
                    <div className="profileAnnonceCardDetails">
                     <strong>
                 
                    <div>
                      {service.title} <CBadge color={getBadge(service.status)}>
                            {textBadge(service.status)}
                          </CBadge>
                    </div>
                    <div>
                      {service.typeService.label} - {" "}
                      {service.categoryService.label}
                    </div>
                   </strong>
                   <div>
                     <strong>Numéro de série :</strong> 
                     <span> {service.sn}</span> 
                    </div>
                   <div>
                     <strong>Date d'ajout :{" "}</strong> 
                     <span><Moment format="DD/MM/YYYY">
                        {service.createdAt}
                      </Moment></span> 
                    </div>
                    </div>
                  </td>

                  <td className="text-center"></td>
                  <td>
                  <Link to= {{ pathname:`/members/profile/myItemDetails/service`,
                         state:service}}
                  >
                  <CButton>
              <img src="assets/icons/eye.svg" />
            </CButton></Link>
                    <Link to = {{ pathname:'/members/profile/service-edit',
                         state:service}}
                    >
                      <CButton >
                      <CIcon name="cilPencil" />    
                      </CButton>
                    </Link>
                    <CButton
                      onClick={(e) => toggle(service.id)}
                    >
                      <CIcon name="cilTrash" />
                    </CButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table> */}
      
        <CDataTable addTableClasses="table table-hover table-outline mb-0  d-sm-table"
                    items={items}
                    fields={fields}
                    itemsPerPageSelect
                    itemsPerPage={10}
                    hover
                    pagination
                    scopedSlots={{
                      thumb: (item) => (
                        <td>
                    <div className="vehicle-thumbnail">
                      <img
                        src={
                          item.mainPicture
                            ? `${IMAGES_BASE_URL}/public/uploads/images/service/${item.mainPicture.name}`
                            : ""
                        }
                        alt=""
                      />
                    </div>
                    <div className="profileAnnonceCardDetails">
                     <strong>
                 
                    <div>
                      {item.title} <CBadge color={getBadge(item.status)}>
                            {textBadge(item.status)}
                          </CBadge>
                    </div>
                    <div>
                      {item.typeService.label} - {" "}
                      {item.categoryService.label}
                    </div>
                   </strong>
                   <div>
                     <strong>Numéro de série :</strong> 
                     <span> {item.sn}</span> 
                    </div>
                   <div>
                     <strong>Date d'ajout :{" "}</strong> 
                     <span><Moment format="DD/MM/YYYY">
                        {item.createdAt}
                      </Moment></span> 
                    </div>
                    </div>
                  </td>
                      ),
                      Actions: (item) => (
                        <td className="actionsTdInMyProfile">
                  <Link to= {{ pathname:`/members/profile/myItemDetails/service`,
                         state:item}}
                  >
                  <CButton>
              <img src="assets/icons/eye.svg" />
            </CButton></Link>
                    <Link to = {{ pathname:'/members/profile/service-edit',
                         state:item}}
                    >
                      <CButton >
                      <CIcon name="cilPencil" />    
                      </CButton>
                    </Link>
                    <CButton
                      onClick={(e) => toggle(item.id)}
                    >
                      <CIcon name="cilTrash" />
                    </CButton>
                  </td>
                      )
                    }}
                  />

        <CModal show={modal} onClose={toggle}>
          <CModalHeader closeButton></CModalHeader>
          <CModalBody>Voulez-vous vraiment supprimer ce service ?</CModalBody>
          <CModalFooter>
            {/* <CButton
                                  color="primary"
                                  onClick={() => {
                                    deleteService(item);
                                  }}
                                >
                                  Oui
                                </CButton> */}

            {!isLoading && (
              <CButton
                color="primary"
                onClick={() => {
                  deleteService(item);
                }}
              >
                Oui
              </CButton>
            )}
            {isLoading && (
              <CButton color="primary" disabled>
                <span>
                  <CSpinner color="default" size="sm" />
                </span>

                <span> Oui</span>
              </CButton>
            )}

            <CButton color="secondary" onClick={toggle}>
              Cancel
            </CButton>
          </CModalFooter>
        </CModal>
      </>
    );
  }
}

export default MyServices;
