import React, { Component } from "react";
import { API_BASE_URL , cat_news , tokenU } from "../../../config";
import axios from "axios";

import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CLabel,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CFormGroup,
  //CInputCheckbox,
  CSelect,
  CSpinner,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

const validEmailRegex = RegExp(
  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
);
const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};
export class EditUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      lang: "francais",
      typepass: false,
      newscat: [],
      newscatReportage: [],
      newscat2Mconso:[],
      access: "",
      nom: "",
      prenom: "",
      email: "",
      password: "",
      password_confirmation: "",
      rolesSelect: [
        {
          id: 0,
          label: "Journaliste",
          value: "ROLE_JOURNALIST",
        },
        {
          id: 1,
          label: "Rédacteur en chef",
          value: "ROLE_EDITOR_IN_CHIEF",
        },
        {
          id: 2,
          label: "Administrateur",
          value: "ROLE_SUPER_ADMIN",
        },
        {
          id: 3,
          label: "Manager",
          value: "ROLE_MANAGER",
        },
      ],
      roles: "ROLE_JOURNALIST",
      // isChecked: false,
      emailErrors: "",
      errors: {
        nom: "",
        prenom: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
      success: "",
    };
  }

  handleChangepass = (event) => {
    //console.log(event.target.checked);

    this.setState({
      typepass: event.target.checked,
      password: "",
      password_confirmation: "",
    });

  };
  generatepassword = () => {
    if (this.state.typepass) {
    } else {
      var pwdChars =
        "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz@#$^*()-_+{}[]<>?,;:.=";
      var pwdLen = 10;
      var randPassword = new Array(pwdLen)
        .fill(0)
        .map((x) =>
          (function (chars) {
            let umax = Math.pow(2, 32),
              r = new Uint32Array(1),
              max = umax - (umax % chars.length);
            do {
              crypto.getRandomValues(r);
            } while (r[0] > max);
            return chars[r[0] % chars.length];
          })(pwdChars)
        )
        .join("");
      //console.log("passss");
      //console.log(randPassword);
      //console.log(this.state);
      this.setState({
        password: randPassword,
        password_confirmation: randPassword,
      });
    }
  };

  handleChange = (event) => {
    if (event.target.name == "access") {
      if (this.state.access.includes(event.target.value)) {
        this.setState({
          access: this.state.access.replace(event.target.value, ""),
        });
      } else {
        this.setState({
          access: this.state.access + "," + event.target.value,
        });
      }
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }

  };
  handleKeyUp = (event) => {
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case "nom":
        errors.nom = value.length < 1 ? "Le champs nom est requis" : "";
        break;
      case "prenom":
        errors.prenom = value.length < 1 ? "Le champs prénom est requis" : "";
        break;
      case "email":
        errors.email = validEmailRegex.test(value) ? "" : "Email is not valid!";
        break;
      case "password":
        errors.password =
          value.length < 8
            ? "Le mot de passe doit contenir au moins 8 caractères"
            : "";
        break;
      case "password_confirmation":
        errors.password_confirmation =
          value !== this.state.password
            ? "La confirmation  ne correspond pas au mot de passe"
            : "";
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });
  };

  handleRole = (event) => {

    let nCheckbox = this.state.roles.slice(); // create a new copy of state value
    if (this.isValueExist(nCheckbox, event)) {
      // check if the same value is preexisted in the array
      const index = nCheckbox.indexOf(event.target.value);
      nCheckbox.splice(index, 1); // removing the preexciting value
    } else {
      nCheckbox.push(event.target.value); // inserting the value of checkbox in the array
    }
    this.setState({
      roles: nCheckbox,
    });
  };
  isValueExist(data, event) {
    if (data.length === 0) {
      return false;
    }

    for (let i = 0; i <= data.length; i++) {
      if (event.target.value === data[i]) {
        return true;
      }
    }
    return false;
  }

  componentDidMount() {

    const { id } = this.props.match.params;
    axios(`${API_BASE_URL}/users/profile/${id}`,{
      params: { userId: tokenU,
                src: 'dashboard'
               },
    })
      .then((res) => res.data)
      .then((result) => {
        //console.log("prrrrrrrr");
        console.log(result);
        // setIsLoaded(true);
        this.setState({
          lang: result.lang,
          access: result.access,
          nom: result.nom,
          prenom: result.prenom,
          email: result.email,
          roles: result.roles[0],
          password: "",
          password_confirmation: "",
        });

      });
  }

  handleSubmit = (event) => {
    // console.log(validateForm(this.state.errors));

    const { id } = this.props.match.params;
    if (validateForm(this.state.errors)) {
      this.setState({ loading: true });
      const formData = new FormData();

      formData.append("userId", tokenU);
      formData.append("nom", this.state.nom);
      formData.append("prenom", this.state.prenom );
      formData.append("lang", this.state.lang );
      formData.append("email", this.state.email);
      formData.append("password", this.state.password);
      formData.append("password_confirmation", this.state.password_confirmation);
      formData.append("roles",this.state.roles);
      formData.append("selectednewscat",this.state.selectednewscat);

      axios
        .post(`${API_BASE_URL}/users/edit/${id}`, formData)
        .then((res) => {
          console.log('reshjgjkj',res);
          if (res.data.status.toString() === "Email not valid") {
            this.setState({
              emailErrors: "Veuillez Entrer une adresse email valide ",
              loading: false,
            });
          }
          if (
            res.data.status.toString() ===
            "The email provided already has an account!"
          ) {
            this.setState({
              emailErrors:
                "Cet email est déjà utilisé! Veuillez choisir un autre",
              loading: false,
            });
          } else {
            this.setState({
              emailErrors: "",
            });
          }
          if (res.data.status.toString() === "ok") {
            this.setState({
              success: "L'utlisateur a été modifié avec succès",
            });
            // document.getElementById("form-add").reset();
            setTimeout(() => {
              this.props.history.push("/5467854/users");
            }, 1500);
          }
          // this.props.history.push("/5467854/index");
        })
        .catch((error) => {
          console.log(" errors : ", error);
        });
    } else {
      //console.log(this.state.errors);
    }

    event.preventDefault();

  };

  render() {
    const generatepasstyle = {
      cursor: "pointer",
      padding: "10px",
      height: "45px",
      fontWeight: "600",
      backgroundColor: "#ebedef",
      border: "solid 1px #d1d3d5",
      borderRadius: "5px",
    };
    const randpassstyle = {
      marginTop: "0px",
      marginLeft: "20px",
      padding: "10px",
      height: "45px",
      fontWeight: "600",
      font: "14px",
      letterSpacing: "1px",
      border: "solid 1px #d1d3d5",
      borderRadius: "5px",
    };
    const { errors } = this.state;
    return (
      <div className="c-app c-default-layout flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md="12" lg="12" xl="12">
              <CCard className="mx-4">
                <CCardBody className="p-4">
                  <CForm onSubmit={this.handleSubmit} id="form-add">
                    <h1>modifier un utilisateur</h1>

                    {this.state.success !== "" ? (
                      <CAlert color="success" closeButton>
                        {this.state.success}
                      </CAlert>
                    ) : (
                      ""
                    )}

                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type="text"
                        placeholder="Nom"
                        name="nom"
                        value={this.state.nom}
                        onChange={this.handleChange}
                        onBlur={this.handleKeyUp}
                      />
                    </CInputGroup>
                    <CCol>
                      {errors.nom.length > 0 && (
                        <p
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.nom}
                        </p>
                      )}
                    </CCol>
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          {" "}
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type="text"
                        placeholder="Prénom"
                        name="prenom"
                        value={this.state.prenom}
                        onChange={this.handleChange}
                        onBlur={this.handleKeyUp}
                      />
                    </CInputGroup>
                    <CCol>
                      {errors.prenom.length > 0 && (
                        <p
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.prenom}
                        </p>
                      )}
                    </CCol>
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>@</CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type="text"
                        placeholder="Email"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        onBlur={this.handleKeyUp}
                      />
                    </CInputGroup>
                    <CCol>
                      {errors.email.length > 0 && (
                        <p
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.email}
                        </p>
                      )}
                      <p
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {this.state.emailErrors}
                      </p>
                    </CCol>

                    <CInputGroup className="mb-3">
                      <div style={generatepasstyle}>
                        <svg
                          style={{ marginRight: "10px" }}
                          fill="#768192"
                          height="15px"
                          width="15px"
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 203.096 203.096"
                          xmlSpace="preserve"
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <g>
                              {" "}
                              <path d="M153.976,73.236h-3.308V49.115C150.669,22.033,128.634,0,101.549,0C74.465,0,52.43,22.033,52.43,49.115v24.121H49.12 c-9.649,0-17.5,7.851-17.5,17.5v94.859c0,9.649,7.851,17.5,17.5,17.5h104.856c9.649,0,17.5-7.851,17.5-17.5V90.736 C171.476,81.087,163.626,73.236,153.976,73.236z M67.43,49.115C67.43,30.304,82.736,15,101.549,15 c18.813,0,34.119,15.304,34.119,34.115v24.121H67.43V49.115z M156.476,185.596c0,1.355-1.145,2.5-2.5,2.5H49.12 c-1.355,0-2.5-1.145-2.5-2.5V90.736c0-1.355,1.145-2.5,2.5-2.5H59.93h83.238h10.808c1.355,0,2.5,1.145,2.5,2.5V185.596z"></path>{" "}
                              <path d="M101.547,116.309c-4.142,0-7.5,3.357-7.5,7.5v28.715c0,4.143,3.358,7.5,7.5,7.5c4.142,0,7.5-3.357,7.5-7.5v-28.715 C109.047,119.666,105.689,116.309,101.547,116.309z"></path>{" "}
                            </g>{" "}
                          </g>
                        </svg>
                        <p
                          style={{ display: "contents" }}
                          onClick={this.generatepassword}
                        >
                          Generate random password
                        </p>
                      </div>
                      {!this.state.typepass &&
                        this.state.password.length > 0 && (
                          <p id="randpass" style={randpassstyle}>
                            {this.state.password}
                          </p>
                        )}
                    </CInputGroup>
                    <div>
                      <input
                        style={{ cursor: "pointer" }}
                        type="checkbox"
                        name="changepasstype"
                        id="changepasstype"
                        onChange={this.handleChangepass}
                      />
                      <label
                        htmlFor="changepasstype"
                        style={{ display: "inline-block", cursor: "pointer" }}
                      >
                        Or type your own password
                      </label>
                    </div>
                    <br />
                    <br />

                    {this.state.typepass && (
                      <div>
                        <CInputGroup className="mb-3">
                          <CInputGroupPrepend>
                            <CInputGroupText>
                              <CIcon name="cil-lock-locked" />
                            </CInputGroupText>
                          </CInputGroupPrepend>
                          <CInput
                            type="password"
                            placeholder="Mot de passe"
                            name="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                            onBlur={this.handleKeyUp}
                          />
                        </CInputGroup>
                        <CCol>
                          {errors.password.length > 0 && (
                            <p
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.password}
                            </p>
                          )}
                        </CCol>
                        <CInputGroup className="mb-4">
                          <CInputGroupPrepend>
                            <CInputGroupText>
                              <CIcon name="cil-lock-locked" />
                            </CInputGroupText>
                          </CInputGroupPrepend>
                          <CInput
                            type="password"
                            placeholder="Confirmer le mot de passe"
                            name="password_confirmation"
                            value={this.state.confirmerPassword}
                            onChange={this.handleChange}
                            onBlur={this.handleKeyUp}
                          />
                        </CInputGroup>
                        <CCol>
                          {errors.password_confirmation.length > 0 && (
                            <p
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.password_confirmation}
                            </p>
                          )}
                        </CCol>
                      </div>
                    )}
                    <CFormGroup row>
                      <CCol md="2">
                        <CLabel>Rôles</CLabel>
                      </CCol>
                      <CCol md="9">
                        <CSelect
                          name="roles"
                          onChange={this.handleChange}
                          value={this.state.roles}
                        >
                          {this.state.rolesSelect.map((roles) => {
                            return (
                              /*   <CFormGroup variant="checkbox" className="checkbox"  key={roles.id} >
                                                <CInputCheckbox 
                                                    id={roles.id}                                         
                                                    value={roles.value}
                                                    
                                                    onChange={this.handleRole}/>
                                                <CLabel variant="checkbox" className="form-check-label"  >{roles.label}</CLabel>
                                            </CFormGroup>*/
                              <option key={roles.id} value={roles.value}>
                                {roles.label}
                              </option>
                            );
                          })}
                        </CSelect>
                      </CCol>
                    </CFormGroup>
                    {this.state.roles == "ROLE_JOURNALIST" && (
                      <CFormGroup row>
                        <CCol md="2" style={{ marginTop: "20px" }}>
                          <CLabel>Journaliste Language:</CLabel>
                        </CCol>
                        <CCol md="9" style={{ marginTop: "20px" }}>
                          <CSelect
                            name="lang"
                            onChange={this.handleChange}
                            value={this.state.lang}
                          >
                            <option value="francais">Français</option>
                            <option value="english">English</option>
                            <option value="arabic">Arabic</option>
                          </CSelect>
                        </CCol>
                        <CCol md="12" style={{ marginTop: "30px" }}>
                          <CLabel>
                            This journaliste can write in the following
                            categories:
                          </CLabel>
                        </CCol>
                        <CCol md="12">
                        <CLabel>Highlight:</CLabel>
                        {cat_news.map((newscat) => {
                              return (
                                newscat.type_news_id ==1 ?
                                <div key={newscat.id}>
                                  <input
                                    type="checkbox"
                                    value={newscat.slug}
                                    name="selectednewscat"
                                    onChange={this.handleChange}
                                  />
                                  <label style={{ display: "inline-block" }}>
                                    {newscat.slug}
                                  </label>
                                </div> : null
                              );
                            })}
                          <br/> <CLabel>2Mconso:</CLabel>
                          {cat_news.map((newscat) => {
                              return (
                                newscat.type_news_id ==2 ?
                                <div key={newscat.id}>
                                  <input
                                    type="checkbox"
                                    value={newscat.slug}
                                    name="selectednewscat"
                                    onChange={this.handleChange}
                                  />
                                  <label style={{ display: "inline-block" }}>
                                    {newscat.slug}
                                  </label>
                                </div> : null
                              );
                            })}
                        </CCol>
                      </CFormGroup>
                    )}

                    <CRow>
                      <CCol xs="12" sm="6">
                        {/* <CButton color="success" block type="submit">
                          Valider
                        </CButton> */}
                        {!this.state.loading && (
                          <CButton color="primary" block type="submit">
                            Valider
                          </CButton>
                        )}
                        {this.state.loading && (
                          <CButton color="primary" block disabled>
                            <span>
                              <CSpinner color="default" size="sm" />
                            </span>

                            <span> Valider</span>
                          </CButton>
                        )}
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    );
  }
}

export default EditUser;
