import {
  CAlert,
  CRow,
  CCol,
  CFormGroup,
  CLabel,
  CInput,
  CSelect,
  CInputCheckbox,
  CButton,
  CInputRadio,
  CSpinner,
} from "@coreui/react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import React, { useEffect, useState } from "react";
import { API_BASE_URL , tokenCookie , keyHeader , sizeAlert} from "../../../../config";
import axios from "axios";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import imageCompression from "browser-image-compression";
import { useHistory } from "react-router-dom";
import { Basic , Scroll , Categori , Adresse , Contact , Extra , Imgs , Equip} from "src/components/myWidgets/formTitles";
import Loading from "src/components/myWidgets/loadingWidget";

//import { temp_sub_url } from "../../../../config";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { addWatermark , addWatermarkToAlbum } from "src/actions";
import { logoutLocal } from "src/components/middleware/auth";

export default function CarForm() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [status, setStatus] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [year, setYear] = useState("");
  const [mileage, setMileage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [price, setPrice] = useState("");
  const [bodyStyle, setBodyStyle] = useState("");
  const [fuel, setFuel] = useState("");
  const [boite, setBoite] = useState("");
  const [nbrSeat, setNbrSeat] = useState("");
  const [warranty, setWarranty] = useState("");
  const [fiscalPower, setFiscalPower] = useState("");
  const [color, setColor] = useState("");
  const [extEquipments, setExtEquipments] = useState([]);
  const [intEquipments, setInEquipments] = useState([]);
  const [secureEquipments, setSecureEquipements] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [transmission, setTransmission] = useState("");
  const [album, setAlbum] = useState([]);
  const [imgPrincipal, setImgPrincipal] = useState(null);
  const [success, setSuccess] = useState(false);
  const [description, setDescription] = useState("");
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [delegate, setDelegate] = useState("");
  const [delegates, setDelegates] = useState([]);
  const [locality, setLocality] = useState("");
  const [localities, setLocalities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [doneuploadingfile, setDoneuploadingfile] = useState(true);
  const [newPhone, setNewPhone] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [pImageThumb, setpImageThumb] = useState(null);
  const [uploadingImage, setUploadingImage] = useState(false); 

  const [imgCount , setImgCount] = useState(0);
  const [imgCountA , setImgCountA] = useState(0);
  const [abortControllers, setAbortControllers] = useState({});
  const [removedfiles, setremovedfiles] = useState([]);

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

      if (!doneuploadingfile) {
        alert("not done uploading files yet");
        setLoading(false);
        return false;
      } else {
        setSubmitted(true);
        // setImgPrincipal(album[0]);
        //console.log("equipments", equipments);
        if (
          status &&
          make &&
          model &&
          year &&
          price > 1000 &&
          bodyStyle &&
          fuel &&
          boite &&
          nbrSeat &&
          warranty &&
          fiscalPower &&
          transmission &&
          color &&
          country &&
          city &&
          delegate &&
          locality
          && imgPrincipal &&            
          (phoneNumber.length==0 || isValidPhoneNumber(phoneNumber))) {
            
          const watermarkedAlbum = await addWatermarkToAlbum(album);
          const watermarkedP = await addWatermark(imgPrincipal);
          const formData = new FormData();
          formData.append("brand", make);
          formData.append("model", model);
          formData.append("createdBy", tokenCookie);
          formData.append("bodyStyle", bodyStyle);
          formData.append("year", year);
          formData.append("fuel", fuel);
          formData.append("mileage", mileage);
          formData.append("boite", boite);
          formData.append("price", price);
          formData.append("warranty", warranty);
          formData.append("locality", locality);
          formData.append("fiscalPower", fiscalPower);
          formData.append("equipement", equipments);
          formData.append("state", status);
          formData.append("color", color);
          formData.append("nbSeat", nbrSeat);
          formData.append("district", "district");
          formData.append("transmissionType", transmission);
          formData.append("description", description);
          if(newPhone)  formData.append("phone", phoneNumber);
          formData.append("imgPrincipal", watermarkedP);
          for (let index = 0; index < watermarkedAlbum.length; index++) {
            formData.append("album[]", watermarkedAlbum[index]);
          } 
          //console.log(formData.get("imgPrincipal"));
          axios
            .post(API_BASE_URL + "/annonce_car/add", formData , keyHeader)
            .then((res) => {
              //console.log(res);
              if (res.data.status.toString() === "ok") {
                setSuccess(true);
                if (res.data.id) {
                  history.push( "/success/ok" , { from: 'add' } );
                }
              }
              setLoading(false);
            })
            .catch((error) => {
              //console.log(" errors : ", error);
              setLoading(false);
              if (error.response && error.response.status === 401){
                logoutLocal();
                window.location.replace("/members/login");
              }
            });
        } else {
          window.scrollTo(0, 200);
          setLoading(false);
        }
      }
 
      
  };

  const handleMakeChange = (e) => {
    setMake(e.target.value);
    let id = e.target.value;
    var makesF = [];
    if (id !== "0") {
      makesF = makes.find((model) => model.id.toString() === id);
      setModels(makesF.models);
      //console.log(makesF);
    } else {
      setModels([]);
      setModel("");
    }
  };
  const onSelectAll = (event, i) => {
    //console.log(event.target.checked);
    //console.log(equipments);
    //console.log(extEquipments);
    //console.log(intEquipments);
    //console.log(secureEquipments);
    let nCheckbox = equipments.slice();

    if (i == 1) {
      if (event.target.checked) {
        extEquipments.forEach((element) => {
          if (nCheckbox.includes(element.id.toString())) {
          } else nCheckbox.push(element.id.toString());
        });
      } else {
        extEquipments.forEach((element) => {
          if (nCheckbox.includes(element.id.toString())) {
            const index = nCheckbox.indexOf(element.id.toString());
            nCheckbox.splice(index, 1);
          } else {
          }
        });
      }
    }
    if (i == 2) {
      if (event.target.checked) {
        intEquipments.forEach((element) => {
          if (nCheckbox.includes(element.id.toString())) {
          } else nCheckbox.push(element.id.toString());
        });
      } else {
        intEquipments.forEach((element) => {
          if (nCheckbox.includes(element.id.toString())) {
            const index = nCheckbox.indexOf(element.id.toString());
            nCheckbox.splice(index, 1);
          } else {
          }
        });
      }
    }
    if (i == 3) {
      if (event.target.checked) {
        secureEquipments.forEach((element) => {
          if (nCheckbox.includes(element.id.toString())) {
          } else nCheckbox.push(element.id.toString());
        });
      } else {
        secureEquipments.forEach((element) => {
          if (nCheckbox.includes(element.id.toString())) {
            const index = nCheckbox.indexOf(element.id.toString());
            nCheckbox.splice(index, 1);
          } else {
          }
        });
      }
    }
    setTimeout(() => {
      setEquipments(nCheckbox);
      //console.log("nch-" + nCheckbox);
      //console.log("eq-" + equipments);
    }, 100);
  };

  const onChangeExt = (event) => {
    let nCheckbox = equipments.slice(); // create a new copy of state value
    if (isValueExist(nCheckbox, event)) {
      // check if the same value is preexisted in the array
      const index = nCheckbox.indexOf(event.target.value);
      nCheckbox.splice(index, 1); // removing the preexciting value
    } else {
      nCheckbox.push(event.target.value); // inserting the value of checkbox in the array
    }
    setEquipments(nCheckbox);
  };
  function isValueExist(data, event) {
    if (data.length === 0) {
      return false;
    }

    for (let i = 0; i <= data.length; i++) {
      if (event.target.value === data[i]) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    if (!localStorage.getItem("carBrands")) 
   { axios(`${API_BASE_URL}/vehicles/car-brands`)
      .then((res) => res.data)
      .then(
        (result) => {
          setIsLoaded(true);
          setMakes(result);
          //  console.log(result);
    localStorage.setItem("carBrands", JSON.stringify(result));

        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  } else {
    setMakes(JSON.parse(localStorage.getItem("carBrands")));
    setIsLoaded(true);
  }

  if (!localStorage.getItem("extEquipments")) 
    {axios(`${API_BASE_URL}/equipements/exterior`)
      .then((res) => res.data)
      .then(
        (result) => {
          setExtEquipments(result);
          // console.log(result);
     localStorage.setItem("extEquipments", JSON.stringify(result));
        },
        (error) => {
          setError(error);
        }
      );
  } else {
    setExtEquipments(JSON.parse(localStorage.getItem("extEquipments")));
  }

  if (!localStorage.getItem("inEquipments")) 
   { axios(`${API_BASE_URL}/equipements/interior`)
      .then((res) => res.data)
      .then(
        (result) => {
          setInEquipments(result);
          // console.log(result);
     localStorage.setItem("inEquipments", JSON.stringify(result));

        },
        (error) => {
          setError(error);
        }
      );
  } else {
    setInEquipments(JSON.parse(localStorage.getItem("inEquipments")));

  }

  if (!localStorage.getItem("secureEquipements")) 
    { axios(`${API_BASE_URL}/equipements/security`)
      .then((res) => res.data)
      .then(
        (result) => {
          setSecureEquipements(result);
          // console.log(result);
     localStorage.setItem("secureEquipements", JSON.stringify(result));

        },
        (error) => {
          setError(error);
        }
      );
  } else {
    setSecureEquipements(JSON.parse(localStorage.getItem("secureEquipements")));
  }

    if (!localStorage.getItem("countries")) 
    {
      axios(`${API_BASE_URL}/countries/all`)
        .then((res) => res.data)
        .then(
          (result) => {
            setCountries(result);
            localStorage.setItem("countries", JSON.stringify(result));
          },
          (error) => {}
        );
    } else {
      setCountries(JSON.parse(localStorage.getItem("countries")));
    }
  }, [countries.id]);

  const getYear = () => {
    const years = [];
    for (let i = new Date().getFullYear(); i > 1950; i--) {
      years.push(
        <option value={i} key={i}>
          {" "}
          {i}
        </option>
      );
    }
    return years;
  };

  const getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = async ({ meta, file }, status) => {

    if (status === 'preparing' && meta.size > 5 * 1024 * 1024) {
      alert(sizeAlert);
      return;
  //file = null;
    }
    setDoneuploadingfile(false);
    let removedfilesx = removedfiles

    if (status === 'preparing' && !(meta.size > 5 * 1024 * 1024)) { setImgCountA(imgCountA+1)}     
    if (status.toString() === "done" && !(meta.size > 5 * 1024 * 1024)) {  
      let newalb = album;
      let options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
      };
     const abortController = new AbortController();
      const fileId = meta.id;
      const updatedAbortControllers = { ...abortControllers, [fileId]: abortController };
      setAbortControllers(updatedAbortControllers);
      options.signal = abortController.signal;

      try {
        file = await imageCompression(file, options);
      } catch (error) {
        //console.log('erro compress : ',error);
       // return;
      }
         if ((!removedfiles.includes(meta.id) && !removedfilesx.includes(meta.id)) && !(meta.size > 5 * 1024 * 1024)) 
         {
          newalb.push(file);
         // setAlbum(prevAlbum => [...prevAlbum, file]);
         setAlbum(newalb)
          setImgCount(prevCount => prevCount + 1);
    } else {
       //console.log("new album in done else");
    //console.log(newalb.length ,newalb);
   }
      setDoneuploadingfile(true);
    }
   if (status.toString() === "removed" && !(meta.size > 5 * 1024 * 1024)) {
    removedfilesx.push(meta.id);
    setremovedfiles(removedfiles)
  // setremovedfiles(prevA => [...prevA, meta.id]);
      const fileId = meta.id;
      if (abortControllers[fileId]) {
        abortControllers[fileId].abort();
        const updatedAbortControllers = { ...abortControllers };
        delete updatedAbortControllers[fileId];
        setAbortControllers(updatedAbortControllers);
      
      }

      let index = album.findIndex((obj) => obj.name === file.name);
      let newAlbum = album;
     { newAlbum.splice(index, 1);
     if(index>-1) {
     if(imgCount>0) setImgCount(newAlbum.length);}
      if(imgCountA>0) setImgCountA(imgCountA-1);
      setAlbum(newAlbum);
     }
      setDoneuploadingfile(true);

    } else  setDoneuploadingfile(true);
  };

  const handleFileSection = async ( event) => {
    setUploadingImage(true);
    let file = event.target.files[0];
    if (file.size > 5 * 1024 * 1024) {
      alert(sizeAlert);
      setpImageThumb(null);
      setImgPrincipal(null);
      setUploadingImage(false);    
      event.target.value = '';
      return;
    }

    let options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
    };
    try {
      file = await imageCompression(file, options);

    } catch (error) {
      //console.log(error);
    }  
    setpImageThumb(URL.createObjectURL(file));
    setImgPrincipal(file);
    setUploadingImage(false);   
  }; 

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    let id = e.target.value;
    var countriesF = [];
    //console.log("iddd", id);
    if (id !== "0") {
      countriesF = countries.find((city) => city.id.toString() === id);
      setCities(countriesF.cities);
      //console.log("countriesF", countriesF);
    } else {
      setCity([]);
      //  setModel("");
    }
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
    let id = e.target.value;
    var citiesF = [];
    //console.log("iddd", id);
    if (id !== "0") {
      citiesF = cities.find((delegate) => delegate.id.toString() === id);
      setDelegates(citiesF.delegations);
      //console.log("citiesF", citiesF);
    } else {
      setDelegate([]);
      //  setModel("");
    }
  };
  const handleDelegateChange = (e) => {
    setDelegate(e.target.value);
    let id = e.target.value;
    var delegatesF = [];
    //console.log("iddd", id);
    if (id !== "0") {
      delegatesF = delegates.find((local) => local.id.toString() === id);
      setLocalities(delegatesF.localities);
      //console.log("delegatesF", delegatesF);
    } else {
      setLocality([]);
      //  setModel("");
    }
  };

  const changePhoneNumber = (event) => {
    //console.log(event.target.checked);
    if(event.target.checked) {setNewPhone(false);setPhoneNumber("");}
    else {setNewPhone(true);
    }
  }


  if (error) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div>Erreur : {error.message}</div>
          </div>
        </main>
      </div>
    );
  } else if (!isLoaded) {
    return (
     <Loading/>
    );
  } else {
    return (
      <>
        <form onSubmit={handleSubmit} id="form-add">
          {/* <div className="information-title">
            <h2>Voiture</h2>
          </div> */}
          <div className="advInfoSzctionCard" >
       <Categori name='Voiture' />
          <CRow className='stateCheckBoxForm'>
            <CCol>
              {" "}
              <CLabel>Etat </CLabel>
              <CFormGroup variant="checkbox" inline>
                <CInputRadio
                  className="form-check-input"
                  id="1"
                  name="status"
                  value="1"
                  onChange={(e) => setStatus(e.target.value)}
                />
                <CLabel variant="checkbox" htmlFor="neuf">
                  Neuf
                </CLabel>
              </CFormGroup>
              <CFormGroup variant="checkbox" inline>
                <CInputRadio
                  className="form-check-input"
                  id="0"
                  name="status"
                  value="0"
                  onChange={(e) => setStatus(e.target.value)}
                />
                <CLabel variant="checkbox" htmlFor="occasion">
                  Occasion
                </CLabel>
              </CFormGroup>
              {submitted && !status && (
                <span className="invalid-feedback error">
                  Veuillez choisir l'état de la voiture
                </span>
              )}
            </CCol>
          </CRow> <br />
          <CRow xs="12">
            <CCol xs="4">
              <CFormGroup>
                <CLabel htmlFor="make">Marque</CLabel>
                <CSelect
                  name="sector"
                  value={make}
                  onChange={(e) => handleMakeChange(e)}
                >
                  <option value="0"> Choisir la marque</option>
                  <optgroup label="Top Marques">
        {makes.slice(0, 23).map((make) => {
                    return (
                      <option key={make.id} value={make.id}>
                        {make.label}
                      </option>
                    );
                  })}
      </optgroup>
      <optgroup label="Autres Marques">
        {makes.slice(23).map((make) =>
          {
                    return (
                      <option key={make.id} value={make.id}>
                        {make.label}
                      </option>
                    );
                  }
        )}
      </optgroup>
                </CSelect>
                {submitted && !make && (
                  <span className="invalid-feedback error">
                    Veuillez choisir la marque de la voiture
                  </span>
                )}
              </CFormGroup>
            </CCol>
            <CCol xs="4">
              <CFormGroup>
                <CLabel htmlFor="model">Modèle</CLabel>
                <CSelect
                  name="model"
                  value={model}
                  onChange={(e) => setModel(e.target.value)}
                >
                  <option value="0"> Modèle</option>
                  {models.map((model) => {
                    return (
                      <option key={model.id} value={model.id}>
                        {model.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !model && (
                  <span className="invalid-feedback error">
                    Veuillez choisir le modèle de la voiture
                  </span>
                )}
              </CFormGroup>
            </CCol>
           <CCol xs="4">
              <CFormGroup>
                <CLabel htmlFor="year">Année</CLabel>
                <CSelect
                  value={year}
                  name="year"
                  onChange={(e) => setYear(e.target.value)}
                >
                  <option></option>
                  {getYear()}
                </CSelect>

                {submitted && !year && (
                  <span className="invalid-feedback error">
                    Veuillez entrer l'année de la voiture
                  </span>
                )}
              </CFormGroup>
            </CCol> 
            </CRow>
            <Scroll/>
         </div>

         <div className="advInfoSzctionCard" >
        <Basic/>
          <CRow xs="12">
          
            <CCol xs="6">
              <CFormGroup>
                <CLabel>Kilométrage </CLabel>
                <CInput
                  type="number"
                  name="mileage"
                  value={mileage}
                  onChange={(e) => setMileage(e.target.value)}
                ></CInput>
                {submitted && !mileage && (
                  <span className="invalid-feedback error">
                    Veuillez entrer le kilométrage de la voiture
                  </span>
                )}
              </CFormGroup>
            </CCol>
   
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="price">Prix en TND</CLabel>
                <CInput
                  type="number"
                  name="price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                ></CInput>
                {submitted && price < 1000 && (
                  <span className="invalid-feedback error">
                    Veuillez entrer le prix de la voiture
                  </span>
                )}
              </CFormGroup>
            </CCol>
            <CCol xs="6">
              <CFormGroup>
                <CLabel>Carrosserie </CLabel>
                <CSelect
                  name="bodyStyle"
                  value={bodyStyle}
                  onChange={(e) => setBodyStyle(e.target.value)}
                >
                  <option value=""></option>
                  <option value="Citadine">Citadine</option>
                  <option value="Compacte">Compacte</option>
                  <option value="Berline">Berline</option>
                  <option value="Coupé">Coupé</option>
                  <option value="Cabriolet">Cabriolet</option>
                  <option value="4x4">4x4</option>
                  <option value="Monospace">Monospace</option>
                  <option value="Utilitaire">Utilitaire</option>
                  <option value="Pick up">Pick Up</option>
                  <option value="SUV">SUV</option>
                  <option value="Autres">Autres</option>
                </CSelect>
                {submitted && !bodyStyle && (
                  <span className="invalid-feedback error">
                    Veuillez choisir la carrosserie
                  </span>
                )}
              </CFormGroup>
            </CCol>
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="fuel">Carburant</CLabel>
                <CSelect
                  name="fuel"
                  value={fuel}
                  onChange={(e) => setFuel(e.target.value)}
                >
                  <option value=""></option>
                  <option value="Essence">Essence</option>
                  <option value="Diesel">Diesel</option>
                  <option value="Hybrid">Hybrid</option>
                  <option value="électrique">électrique</option>
                </CSelect>
                {submitted && !fuel && (
                  <span className="invalid-feedback error">obligatoire</span>
                )}
              </CFormGroup>
            </CCol>
            <CCol xs="6">
              <CFormGroup>
                <CLabel>Boite </CLabel>
                <CSelect
                  name="boite"
                  value={boite}
                  onChange={(e) => setBoite(e.target.value)}
                >
                  <option value=""></option>
                  <option value="Manuelle"> Manuelle</option>
                  <option value="Automatique">Automatique</option>
                </CSelect>
                {submitted && !boite && (
                  <span className="invalid-feedback error">Obligatoire</span>
                )}
              </CFormGroup>
            </CCol>
         
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="nbrSeat">Nombre de sièges</CLabel>
                <CSelect
                  name="nbrSeat"
                  value={nbrSeat}
                  onChange={(e) => setNbrSeat(e.target.value)}
                >
                  <option value=""></option>
                  <option value="5">5 sièges</option>
                  <option value="7">7 sièges</option>
                  <option value="9">9 sièges</option>
                </CSelect>
                {submitted && !nbrSeat && (
                  <span className="invalid-feedback error">obligatoire</span>
                )}
              </CFormGroup>
            </CCol>
            <CCol xs="6">
              <CFormGroup>
                <CLabel>Garrantie </CLabel>
                <CSelect
                  name="warranty"
                  value={warranty}
                  onChange={(e) => setWarranty(e.target.value)}
                >
                  <option value=""></option>
                  <option value="0">0</option>
                  <option value="moins 1"> Moins d'une année</option>
                  <option value="1">1 an</option>
                  <option value="2">2 ans</option>
                </CSelect>
                {submitted && !warranty && (
                  <span className="invalid-feedback error">Obligatoire</span>
                )}
              </CFormGroup>
            </CCol>
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="fiscalPower">Puissance fiscale</CLabel>
                <CSelect
                  name="fiscalPower"
                  value={fiscalPower}
                  onChange={(e) => setFiscalPower(e.target.value)}
                >
                  <option value=""></option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="autres">Autres</option>
                </CSelect>
                {submitted && !fiscalPower && (
                  <span className="invalid-feedback error">obligatoire</span>
                )}
              </CFormGroup>
            </CCol>
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="transmission">Transmission</CLabel>
                <CSelect
                  name="transmission"
                  value={transmission}
                  onChange={(e) => setTransmission(e.target.value)}
                >
                  <option value=""></option>
                  <option value="traction intégrale">Traction intégrale</option>
                  <option value="traction avant"> Traction avant</option>
                  <option value="propulsion arrière">Propulsion arrière</option>
                  <option value="à quatre roues motrices">
                    À quatre roues motrices
                  </option>
                </CSelect>
                {submitted && !transmission && (
                  <span className="invalid-feedback error">obligatoire</span>
                )}
              </CFormGroup>
            </CCol>
            <CCol xs="6">
              <CFormGroup>
                <CLabel>Couleur </CLabel>
                <CSelect
                  name="color"
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                >
                  <option value=""></option>
                  <option value="Noir">Noir</option>
                  <option value="Gris argent">Gris argent</option>
                  <option value="Gris anthracite">Gris anthracite</option>
                  <option value="Gris Shark">Gris Shark</option>
                  <option value="Blanc">Blanc</option>
                  <option value="Rouge">Rouge</option>
                  <option value="Bleu">Bleu</option>
                  <option value="Beige">Beige</option>
                  <option value="Jaune">Jaune</option>
                  <option value="Orange">Orange</option>
                  <option value="Vert">Vert</option>
                  <option value="Aubergine">Aubergine</option>
                  <option value="Marron">Marron</option>
                  <option value="Autre">Autre</option>
                </CSelect>
                {submitted && !color && (
                  <span className="invalid-feedback error">Obligatoire</span>
                )}
              </CFormGroup>
            </CCol>
          </CRow>
          <Scroll/>
         </div>

         <div className="advInfoSzctionCard" >
        <Adresse/>
          <CRow xs="12">
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="country">Pays</CLabel>
                <CSelect
                  name="country"
                  value={country}
                  onChange={(e) => handleCountryChange(e)}
                >
                  <option value="0"> Choisissez un pays</option>
                  {countries.map((country) => {
                    return (
                      <option key={country.id} value={country.id}>
                        {country.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !country && (
                  <span className="invalid-feedback error">Error</span>
                )}
              </CFormGroup>
            </CCol>
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="Région">Gouvernorat</CLabel>
                <CSelect
                  name="city"
                  value={city}
                  onChange={(e) => handleCityChange(e)}
                >
                  <option value="0"> Gouvernorat</option>
                  {cities.map((city) => {
                    return (
                      <option key={city.id} value={city.id}>
                        {city.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !city && (
                  <span className="invalid-feedback error">Error</span>
                )}
              </CFormGroup>
            </CCol>
          </CRow>

          <CRow xs="12">
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="delegate">Délégation</CLabel>
                <CSelect
                  name="delegate"
                  value={delegate}
                  onChange={(e) => handleDelegateChange(e)}
                >
                  <option value="0"> Choisissez une délégation</option>
                  {delegates.map((delegate) => {
                    return (
                      <option key={delegate.id} value={delegate.id}>
                        {delegate.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !delegate && (
                  <span className="invalid-feedback error">Error</span>
                )}
              </CFormGroup>
            </CCol>
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="loclité">Localité</CLabel>
                <CSelect
                  name="locality"
                  value={locality}
                  onChange={(e) => setLocality(e.target.value)}
                >
                  <option value="0"> Localité</option>
                  {localities.map((local) => {
                    return (
                      <option key={local.id} value={local.id}>
                        {local.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !locality && (
                  <span className="invalid-feedback error">Error</span>
                )}
              </CFormGroup>
            </CCol>
          </CRow>
          <Scroll/>
         </div>

          {/* <div className="information-title">
            <h2>Equipements extérieures</h2>
          </div> */}
          <div className="advInfoSzctionCard" >
        <Equip name='extérieures' />
          <CRow className="toutesOptions">
            <CCol xs="12">
              <CFormGroup variant="checkbox" className="checkbox">
                <CInputCheckbox
                  id="0"
                  name="0"
                  value="0"
                  onChange={(e) => onSelectAll(e, 1)}
                />
                <label htmlFor="0">Toutes options</label>
              </CFormGroup>
            </CCol>
          </CRow>
          <CRow>
            {extEquipments.map((extEquipment, index) => {
              return (
                <CCol xs="3" key={index}>
                  <CFormGroup variant="checkbox" className="checkbox">
                    <CInputCheckbox
                      checked={equipments.includes(extEquipment.id.toString())}
                      id={`custom-checkbox-${index}`}
                      name={extEquipment.id}
                      value={extEquipment.id}
                      onChange={(e) => onChangeExt(e)}
                      selected={extEquipments.includes(extEquipment.label)}
                    />
                    <label htmlFor={`custom-checkbox-${index}`}>
                      {extEquipment.label}
                    </label>
                  </CFormGroup>
                </CCol>
              );
            })}
          </CRow>
          <Scroll/>
         </div>

          {/* <div className="information-title">
            <h2>Equipements intérieurs</h2>
          </div> */}
          <div className="advInfoSzctionCard" >
        <Equip name='intérieurs' />
          <CRow className="toutesOptions">
            <CCol xs="12">
              <CFormGroup variant="checkbox" className="checkbox">
                <CInputCheckbox
                  id="0"
                  name="0"
                  value="0"
                  onChange={(e) => onSelectAll(e, 2)}
                />
                <label htmlFor="0">Toutes options</label>
              </CFormGroup>
            </CCol>
          </CRow>
          <CRow>
            {intEquipments.map((intEquipment, index) => {
              return (
                <CCol xs="3" key={index}>
                  <CFormGroup variant="checkbox" className="checkbox">
                    <CInputCheckbox
                      checked={equipments.includes(intEquipment.id.toString())}
                      id={`custom-checkbox-${index}`}
                      name={intEquipment.id}
                      value={intEquipment.id}
                      onChange={(e) => onChangeExt(e)}
                      selected={extEquipments.includes(intEquipment.label)}
                    />
                    <label htmlFor={`custom-checkbox-${index}`}>
                      {intEquipment.label}
                    </label>
                  </CFormGroup>
                </CCol>
              );
            })}
          </CRow>
          <Scroll/>
         </div>

          {/* <div className="information-title">
            <h2>Equipements de sécurité</h2>
          </div> */}
          <div className="advInfoSzctionCard" >
        <Equip name='de sécurité' />
          <CRow className="toutesOptions">
            <CCol xs="12">
              <CFormGroup variant="checkbox" className="checkbox">
                <CInputCheckbox
                  id="0"
                  name="0"
                  value="0"
                  onChange={(e) => onSelectAll(e, 3)}
                />
                <label htmlFor="0">Toutes options</label>
              </CFormGroup>
            </CCol>
          </CRow>
          <CRow>
            {secureEquipments.map((secureEquipment, index) => {
              return (
                <CCol xs="3" key={index}>
                  <CFormGroup variant="checkbox" className="checkbox">
                    <CInputCheckbox
                      checked={equipments.includes(
                        secureEquipment.id.toString()
                      )}
                      id={`custom-checkbox-${index}`}
                      name={secureEquipment.id}
                      value={secureEquipment.id}
                      onChange={(e) => onChangeExt(e)}
                      selected={extEquipments.includes(secureEquipment.label)}
                    />
                    <label htmlFor={`custom-checkbox-${index}`}>
                      {secureEquipment.label}
                    </label>
                  </CFormGroup>
                </CCol>
              );
            })}
          </CRow>
          {submitted && !equipments.length > 0 && (
            <span className="invalid-feedback error">
              Veuillez choisir au moins un équipement
            </span>
          )}
          <br/>
          <Scroll/>
         </div>

         <div className="advInfoSzctionCard" >
       <Contact/>
          <CRow xs="12">
          <CCol xs="6">
              {newPhone && <CFormGroup className='formPhoneNumber'>
                <CLabel htmlFor="phoneNumber">Numéro téléphone</CLabel>
                <PhoneInput
                  type="text"
                  onChange={(value) => setPhoneNumber(value)}
                  placeholder="Entrez un numéro de téléphone"
                  defaultCountry="TN"
                  international
                  name="phoneNumber"
                  id="phoneNumber"
                  value={phoneNumber}
                />
                 {submitted && newPhone && phoneNumber.length>0 && !isValidPhoneNumber(phoneNumber) && (
                  <span className="invalid-feedback error">Ce numéro de téléphone n'est pas valide</span>
                )}
              </CFormGroup>}
              <CFormGroup variant="checkbox" className="checkbox">
                <CInputCheckbox
                  onChange={changePhoneNumber}                 
                />
                <CLabel variant="checkbox" className="form-check-label">
                Utilisez le numéro téléphone de votre compte
                </CLabel>
              </CFormGroup> 
            </CCol>
          </CRow>
          <Scroll/>
         </div>

         <div className="advInfoSzctionCard" >
     <Extra/>
      <CRow>
            <CCol>
              <CFormGroup>
                <CKEditor
                  editor={ClassicEditor}
                  data={description}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setDescription(data);
                  }}
                />
              </CFormGroup>
            </CCol>
          </CRow>
          <Scroll/>
         </div>

         <div className="advInfoSzctionCard" >
          <Imgs/>
          <CRow>
            <CCol>
            <CFormGroup style={{display:"inline-block"}}>
                <CLabel htmlFor="pImage">Principal image</CLabel>
                <input 
                          type="file"
                          name="pImage"
                          onChange={handleFileSection}
                          accept="image/*"
                           ></input>
            </CFormGroup>
            {uploadingImage &&<span style={{height:"fit-content",marginLeft:"10px",lineHeight:"90px"}}>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span>}
                               {!uploadingImage && pImageThumb &&  <img style={{height: "85px", width: "auto"}}
          src={pImageThumb}
          alt="preview"
          className="img-fluid img-thumbnail "
        />} 
           {submitted && !imgPrincipal && (
                <span className="invalid-feedback error">
                  Veuillez uploader une image
                </span>
              )}
            </CCol>
          </CRow><br/>
          {imgCountA>0 && <> <div className="imgCounter"> {imgCount} {imgCount > 1 ? ' images téléchargées sur ' :' image téléchargée sur ' }  {imgCountA} </div>
       <div className="loading-bar">
          <div className="loading-bar-fill" style={{ width: `${imgCountA==0?0 :(imgCount / imgCountA) * 100}%` }}></div>
        </div><br/></>}
          <CRow>
            <CCol>
            <CLabel >Autres images</CLabel> 
              <Dropzone
                // getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                // onSubmit={handleSubmitF}
                accept="image/*"
                maxFiles="9"
                maxSizeBytes={5 * 1024 * 1024}
              >
                <input />
              </Dropzone>
            </CCol>
          </CRow>
          <br />
          </div>
          {success && (
            <CAlert
              show={true}
              // autohide="5000"
              // autohide={false}
              placement="top-end"
              color="success"
            >
              {`La voiture a été ajoutée avec succès !`}
            </CAlert>
          )}

          <CRow>
          <CCol md="3" className="offset-9">
            {
  ((!(doneuploadingfile && imgCountA == album.length)) || uploadingImage)? 
  (
              <CButton color="primary" block disabled>
                <span>
                  {" "}
                  <CSpinner color="default" size="sm" />
                </span>

                <span> chargement des images</span>
              </CButton>
            )

       :  loading ? (
              <CButton color="primary" block disabled>
                <span>
                  {" "}
                  <CSpinner color="default" size="sm" />
                </span>

                <span> Valider</span>
              </CButton>
            )  
            :
              <CButton color="primary" block type="submit">
                Valider
              </CButton>
            
}
            </CCol>
          </CRow>
        </form>
      </>
    );
  }
}
