
import {
    CCol,
    CNav,
    CNavItem,
    CNavLink,
    CTabContent,
    CTabPane,
    CTabs,
    CAlert,
    CRow,
    CFormGroup,
    CLabel,
    CInput,
    CButton,
    CSpinner,
    CSelect,
  CInputCheckbox,
   CInputRadio
  } from "@coreui/react";
  import React, { useEffect, useState } from "react";
  import axios from "axios";
  import Dropzone from "react-dropzone-uploader";
  import "react-dropzone-uploader/dist/styles.css";
  import imageCompression from "browser-image-compression";
  import { useHistory } from "react-router-dom";
  import Loading from "src/components/myWidgets/loadingWidget";
  import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
  import "react-phone-number-input/style.css";
  
  import { CKEditor } from "@ckeditor/ckeditor5-react";
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  import { API_BASE_URL ,tokenCookie ,keyHeader , sizeAlert} from "src/config";

import LoginMember from "../../members/LoginMember";
import { isLogin } from "src/components/middleware/auth";
import { Basic , Scroll , Categori , Adresse , Contact , Extra , Imgs, Eventt} from "src/components/myWidgets/formTitles";
import { addWatermark , addWatermarkToAlbum } from "src/actions";
import Breadcrumb from "../../Breadcrumb";
import { logoutLocal } from "src/components/middleware/auth";

export default function AddArticles(props) {
  localStorage.setItem("prevUrl", props.location.pathname);


  const [section, setSection] = useState(1);
  const [sectionLabel, setSectionLabel] = useState("Animalerie");
  const [isloaded, setIsloaded] = useState(false);

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [price, setPrice] = useState("");
  const [album, setAlbum] = useState([]);
  const [imgPrincipal, setImgPrincipal] = useState(null);
  const [success, setSuccess] = useState(false);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState("");
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [delegate, setDelegate] = useState("");
  const [delegates, setDelegates] = useState([]);
  const [locality, setLocality] = useState("");
  const [localities, setLocalities] = useState([]);
  const [typesAll, setTypesAll] = useState([]);
  const [types, setTypes] = useState([]);
  const [type, setType] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState([]);
  const [doneuploadingfile, setDoneuploadingfile] = useState(true);
  const [newPhone, setNewPhone] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [pImageThumb, setpImageThumb] = useState(null);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [status, setStatus] = useState("");
  const [nom, setNom] = useState("");
  const [typeChosen, setTypeChosen] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [subSubCategories, setSubSubCategories] = useState([]);
  const [subSubCategory, setSubSubCategory] = useState([]);

  const [imgCount , setImgCount] = useState(0);
  const [imgCountA , setImgCountA] = useState(0);
  const [abortControllers, setAbortControllers] = useState({});
  const [removedfiles, setremovedfiles] = useState([]);

  
  const history = useHistory();



  const handleSubmitX = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("imgPrincipal", imgPrincipal);

    const watermarkedAlbum = await addWatermarkToAlbum(album);

    // Now you have the watermarked album ready to be used
    for (let index = 0; index < watermarkedAlbum.length; index++) {
        formData.append("album[]", watermarkedAlbum[index]);
    }

    //console.log(formData.get("imgPrincipal"));
    //console.log(formData.get("album[]"));
    axios
      .post(
        API_BASE_URL + "/article/img",
        formData , keyHeader
      )
      .then((res) => {
        //console.log(res);
        setLoading(false);
      })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);


      if (!doneuploadingfile) {
        alert("not done uploading files yet");
        setLoading(false);

        return false;
      } else {
        setSubmitted(true);
        if (status &&
          type &&
          price &&
          country &&
          city &&
          delegate &&
          locality && imgPrincipal &&            
          (phoneNumber.length==0 || isValidPhoneNumber(phoneNumber))) {
          setLoading(true);
          const watermarkedAlbum = await addWatermarkToAlbum(album);
          const watermarkedP = await addWatermark(imgPrincipal);

          const formData = new FormData();
          if(category) formData.append("category", category);
          formData.append("type", type);
          formData.append("locality", locality);
          formData.append("description", description);
          formData.append("price", price);
          formData.append("createdBy", tokenCookie);
          formData.append("state", status);
          formData.append("nom", nom);
          formData.append("section", section);
          if(subCategory) formData.append("subCategory", subCategory);
          if(subSubCategory) formData.append("subSubCategory", subSubCategory);
          if(newPhone)  formData.append("phone", phoneNumber);
          
          formData.append("imgPrincipal", watermarkedP);
          // Now you have the watermarked album ready to be used
          for (let index = 0; index < watermarkedAlbum.length; index++) {
              formData.append("album[]", watermarkedAlbum[index]);
          }

          //console.log(formData.get("imgPrincipal"));
          axios
            .post(
              API_BASE_URL + "/article/add",
              formData , keyHeader
            )
            .then((res) => {
              //console.log(res);
              if (res.data.status.toString() === "ok") {
                setSuccess(true);
                if (res.data.id) {
                  history.push( "/success/ok" , { from: 'add' });
                }
              }
              setLoading(false);
            })
            .catch((error) => {
              //console.log(" errors : ", error);
              setLoading(false);
              if (error.response && error.response.status === 401){
                logoutLocal();
                window.location.replace("/members/login");
              }
            });
        } else {
          window.scrollTo(0, 200);
          setLoading(false);
        }
      }
    
  };

  useEffect(() => {
    if (!localStorage.getItem("countries")) {
      axios(`${API_BASE_URL}/countries/all`)
        .then((res) => res.data)
        .then(
          (result) => {
            setCountries(result);
            localStorage.setItem("countries", JSON.stringify(result));
          },
          (error) => {}
        );
    } else {
      setCountries(JSON.parse(localStorage.getItem("countries")));
    }

    if (!localStorage.getItem("typeArticles")) 
    {axios(`${API_BASE_URL}/articles/types`)
      .then((res) => res.data)
      .then(
        (result) => {
          //console.log("result555 all", result);
          setTypesAll(result);
      let typesF = [];
      typesF = result.filter(obj => { return obj.sectionId == 1 })
      setTypes(typesF);
      //console.log(typesF); 
          setIsLoaded(true);
          localStorage.setItem("typeArticles", JSON.stringify(result));
        },
        (error) => {
          setError(error);
          setIsLoaded(true);
        }
      );} else {
        setTypesAll(JSON.parse(localStorage.getItem("typeArticles")));
        let typesF = [];
        typesF = JSON.parse(localStorage.getItem("typeArticles")).filter(obj => { return obj.sectionId == 1 })
        setTypes(typesF);
            setIsLoaded(true);
      }


  }, [countries.id]);

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    let id = e.target.value;
    var countriesF = [];
    //console.log("iddd", id);
    if (id !== "0") {
      countriesF = countries.find((city) => city.id.toString() === id);
      setCities(countriesF.cities);
      //console.log("countriesF", countriesF);
    } else {
      setCity([]);
      //  setModel("");
    }
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
    let id = e.target.value;
    var citiesF = [];
    //console.log("iddd", id);
    if (id !== "0") {
      citiesF = cities.find((delegate) => delegate.id.toString() === id);
      setDelegates(citiesF.delegations);
      //console.log("citiesF", citiesF);
    } else {
      setDelegate([]);
      //  setModel("");
    }
  };

  const handleDelegateChange = (e) => {
    setDelegate(e.target.value);
    let id = e.target.value;
    var delegatesF = [];
    //console.log("iddd", id);
    if (id !== "0") {
      delegatesF = delegates.find((local) => local.id.toString() === id);
      setLocalities(delegatesF.localities);
      //console.log("delegatesF", delegatesF);
    } else {
      setLocality([]);
      //  setModel("");
    }
  };

  const handleTypeChange = (e) => {
//console.log(types);

    setType(e.target.value);
    setSubCategories([]);
    setSubCategory("");
    setSubSubCategories([]);
    setSubSubCategory("");
    let id = e.target.value;
    var typesF = [];
    if (id !== "0") {
      typesF = types.find((category) => category.id.toString() === id);
      setCategories(typesF.categoryArticles);
      setTypeChosen(true);
      //console.log(typesF);
    } else {
      setCategories([]);
      setCategory("");
    }
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
    setSubSubCategories([]);
    setSubSubCategory("");
    let id = e.target.value;
    var categoryF = [];
    if (id !== "0") {
      categoryF = categories.find(
        (subCategory) => subCategory.id.toString() === id
      );
      setSubCategories(categoryF.subCategoryPetShops);
      //console.log(categoryF);
    } else {
      setSubCategories([]);
      setSubCategory("");
    }
  };

  const handleSubCategoryChange = (e) => {
    setSubCategory(e.target.value);
    let id = e.target.value;
    var subCategoryF = [];
    if (id !== "0") {
      subCategoryF = subCategories.find(
        (subCategory) => subCategory.id.toString() === id
      );
      setSubSubCategories(subCategoryF.subSubCategoryPetShops);
      //console.log(subCategoryF);
    } else {
      setSubSubCategories([]);
      setSubSubCategory("");
    }
  };
  const handleSubSubCategoryChange = (e) => {
    setSubSubCategory(e.target.value);
    let id = e.target.value;
  };

  const changePhoneNumber = (event) => {
    //console.log(event.target.checked);
    if(event.target.checked) {setNewPhone(false);setPhoneNumber("");}
    else {setNewPhone(true);
    }
  }
 
  // called every time a file's `status` changes
  const handleChangeStatus = async ({ meta, file }, status) => {

    if (status === 'preparing' && meta.size > 5 * 1024 * 1024) {
      alert(sizeAlert);
      return;
  //file = null;
    }
    setDoneuploadingfile(false);
    let removedfilesx = removedfiles

    if (status === 'preparing' && !(meta.size > 5 * 1024 * 1024)) { setImgCountA(imgCountA+1)}     
    if (status.toString() === "done" && !(meta.size > 5 * 1024 * 1024)) {  
      let newalb = album;
      let options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
      };
     const abortController = new AbortController();
      const fileId = meta.id;
      const updatedAbortControllers = { ...abortControllers, [fileId]: abortController };
      setAbortControllers(updatedAbortControllers);
      options.signal = abortController.signal;

      try {
        file = await imageCompression(file, options);
      } catch (error) {
        //console.log('erro compress : ',error);
       // return;
      }
         if ((!removedfiles.includes(meta.id) && !removedfilesx.includes(meta.id)) && !(meta.size > 5 * 1024 * 1024)) 
         {
          newalb.push(file);
         // setAlbum(prevAlbum => [...prevAlbum, file]);
         setAlbum(newalb)
          setImgCount(prevCount => prevCount + 1);
    } else {
       //console.log("new album in done else");
    //console.log(newalb.length ,newalb);
   }
      setDoneuploadingfile(true);
    }
   if (status.toString() === "removed" && !(meta.size > 5 * 1024 * 1024)) {
    removedfilesx.push(meta.id);
    setremovedfiles(removedfiles)
  // setremovedfiles(prevA => [...prevA, meta.id]);
      const fileId = meta.id;
      if (abortControllers[fileId]) {
        abortControllers[fileId].abort();
        const updatedAbortControllers = { ...abortControllers };
        delete updatedAbortControllers[fileId];
        setAbortControllers(updatedAbortControllers);
      
      }

      let index = album.findIndex((obj) => obj.name === file.name);
      let newAlbum = album;
     { newAlbum.splice(index, 1);
     if(index>-1) {
     if(imgCount>0) setImgCount(newAlbum.length);}
      if(imgCountA>0) setImgCountA(imgCountA-1);
      setAlbum(newAlbum);
     }
      setDoneuploadingfile(true);

    } else  setDoneuploadingfile(true);
  };

  const handleFileSection = async ( event) => {
    setUploadingImage(true);
    let file = event.target.files[0];
    if (file.size > 5 * 1024 * 1024) {
      alert(sizeAlert);
      setpImageThumb(null);
      setImgPrincipal(null);
      setUploadingImage(false);    
      event.target.value = '';
      return;
    }

    let options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
    };
    try {
      file = await imageCompression(file, options);

    } catch (error) {
      //console.log(error);
    }  
    setpImageThumb(URL.createObjectURL(file));
    setImgPrincipal(file);
    setUploadingImage(false);   
  }; 



 


  const handleSectionChange = (i, label) => {
      setSection(i);
      setSectionLabel(label);
      setType('');
      setCategory('');
      var typesF = [];
      //console.log(label);
var typesF = [];
  typesF = typesAll.filter(obj => { return obj.sectionId == i })
  setTypes(typesF);
  //console.log(typesF);     
    };

  return localStorage.getItem("roles") === "ROLE_MEMBER" && isLogin() ? (
    <>
      <div className="container addingPage">
      <Breadcrumb />
        <main className="l-main">
 
          <div className="l-section l-section--archive">
            <div className="information-title">
              <h2>Créer une annonce</h2>
            </div>
            <p></p>
            <CTabs>
              <div className="row ">
                <CCol xs="12" md="12">
                  <CNav variant="tabs" className="vehicule-form-tabs add_page">
                    <CNavItem className="vehicule-form-navItem articleIcon"
                        onClick={() => handleSectionChange("1", "Animalerie")}
                    >
                      <CNavLink className="vehicule-form-navLink">
                        <img
                          src={"assets/icons/petShop.svg"}
                          className="icon-vehicle"
                          alt="pet Shop"
                        />
                      </CNavLink>
                    </CNavItem>
                    <CNavItem className="vehicule-form-navItem articleIcon"
                        onClick={() => handleSectionChange(2, "Informatique")}
                    >
                      <CNavLink className="vehicule-form-navLink">
                        <img
                          src={"assets/icons/computerScience.svg"}
                          className="icon-vehicle"
                          alt="computer accessory"
                        />
                      </CNavLink>
                    </CNavItem>
                    <CNavItem className="vehicule-form-navItem articleIcon"
                        onClick={() => handleSectionChange(3, "Smart Home")}
                    >
                      <CNavLink className="vehicule-form-navLink">
                        <img
                          src={"assets/icons/smartHome.svg"}
                          className="icon-vehicle"
                          alt="smart Home"
                        />
                      </CNavLink>
                    </CNavItem>
                    <CNavItem className="vehicule-form-navItem articleIcon"
                        onClick={() => handleSectionChange(4, "Maison et déco")}
                    >
                      <CNavLink className="vehicule-form-navLink">
                        <img
                          src={"assets/icons/homeDeco.svg"}
                          className="icon-vehicle"
                          alt="electromenager"
                        />
                      </CNavLink>
                    </CNavItem>
                    <CNavItem className="vehicule-form-navItem articleIcon"
                        onClick={() => handleSectionChange(5, "Sport et Fitness")}
                    >
                      <CNavLink className="vehicule-form-navLink">
                        <img
                          src={"assets/icons/sportFitness.svg"}
                          className="icon-vehicle"
                          alt="sportFitness"
                        />
                      </CNavLink>
                    </CNavItem>
                    <CNavItem className="vehicule-form-navItem articleIcon"
                        onClick={() => handleSectionChange(6, "Auto et Accessoires")}
                    >
                      <CNavLink className="vehicule-form-navLink">
                        <img
                          src={"assets/icons/autoAccessory.svg"}
                          className="icon-vehicle"
                          alt="autoAccessory"
                        />
                      </CNavLink>
                    </CNavItem>
                    <CNavItem className="vehicule-form-navItem articleIcon"
                        onClick={() => handleSectionChange(7, "Shopping")}
                    >
                      <CNavLink className="vehicule-form-navLink">
                        <img
                          src={"assets/icons/clothes.svg"}
                          className="icon-vehicle"
                          alt="clothes"
                        />
                      </CNavLink>
                    </CNavItem>
                    <CNavItem className="vehicule-form-navItem articleIcon"
                        onClick={() => handleSectionChange(8, "AudioVisuel")}
                    >
                      <CNavLink className="vehicule-form-navLink">
                        <img
                          src={"assets/icons/audiovisuel.svg"}
                          className="icon-vehicle"
                          alt="Audiovisuel"
                        />
                      </CNavLink>
                    </CNavItem>
                    <CNavItem className="vehicule-form-navItem articleIcon"
                        onClick={() => handleSectionChange(9, "Divers")}
                    >
                      <CNavLink className="vehicule-form-navLink">
                        <img
                          src={"assets/icons/divers.svg"}
                          className="icon-vehicle"
                          alt="divers tunisia living"
                        />
                      </CNavLink>
                    </CNavItem>
                  </CNav>
                </CCol>
              </div>

              <CTabContent >
              <div>
              {isLoaded ?
               <form onSubmit={handleSubmit} id="form-add">
          {/* <div className="information-title">
            <h2>{sectionLabel}</h2>
          </div> */}
          <div className="advInfoSzctionCard" >
       <Categori name={sectionLabel} />
          <CRow xs="12">
            <CCol>
              <CLabel>Etat </CLabel> 
              <CFormGroup variant="checkbox" inline>
                <CInputRadio
                  className="form-check-input"
                  id="1"
                  name="status"
                  value="1"
                  onChange={(e) => setStatus(e.target.value)}
                />
                <CLabel variant="checkbox" htmlFor="neuf">
                  Neuf
                </CLabel>
              </CFormGroup>
              <CFormGroup variant="checkbox" inline>
                <CInputRadio
                  className="form-check-input"
                  id="0"
                  name="status"
                  value="0"
                  onChange={(e) => setStatus(e.target.value)}
                />
                <CLabel variant="checkbox" htmlFor="occasion">
                  Occasion
                </CLabel>
              </CFormGroup>
              {submitted && !status && (
                <span className="invalid-feedback error">
                  Veuillez choisir l'état
                </span>
              )}
            </CCol>
          </CRow><br />
          <CRow xs="12">
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="type">Type </CLabel>
                <CSelect
                  name="type"
                  value={type}
                  onChange={(e) => handleTypeChange(e)}
                >
                  <option value="0"> Choisir le type</option>
                  {types.map((type) => {
                    return (
                      <option key={type.id} value={type.id}>
                        {type.label}
                      </option>
                    );
                  })}
                </CSelect>

                {submitted && !type && (
                  <span className="invalid-feedback error">
                    Veuillez choisir le type
                  </span>
                )}
              </CFormGroup>
            </CCol>
            {!typeChosen || (typeChosen && categories.length >0 )&& 
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="category">Categorie</CLabel>
                <CSelect
                  name="category"
                  value={category}
                  onChange={(e) => handleCategoryChange(e)}
                >
                  <option value="0"> Choisir la categorie </option>
                  {categories.map((category) => {
                    return (
                      <option key={category.id} value={category.id}>
                        {category.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !category && (
                  <span className="invalid-feedback error">
                    Veuillez choisir le categorie
                  </span>
                )}
              </CFormGroup>
            </CCol>}     
              {subCategories.length > 0 ? (
                     <CCol xs="6">  <CFormGroup>
                  <CLabel htmlFor="subCategory">Sous Categorie</CLabel>
                  <CSelect
                    name="subCategory"
                    value={subCategory}
                    onChange={(e) => handleSubCategoryChange(e)}
                  >
                    <option value="0"> Choisir la sous categorie </option>
                    {subCategories.map((subCategory) => {
                      return (
                        <option key={subCategory.id} value={subCategory.id}>
                          {subCategory.label}
                        </option>
                      );
                    })}
                  </CSelect>
                  {submitted && !subCategory && (
                    <span className="invalid-feedback error">
                      Veuillez choisir le sous categorie
                    </span>
                  )}
                </CFormGroup>   </CCol>
              ) : null}
         
            {subSubCategories.length > 0 ? (
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="subSubCategory">Sous sous Categorie</CLabel>
                  <CSelect
                    name="subSubCategory"
                    value={subSubCategory}
                    onChange={(e) => handleSubSubCategoryChange(e)}
                  >
                    <option value="0"> Choisir la sous categorie </option>
                    {subSubCategories.map((subSubCategory) => {
                      return (
                        <option
                          key={subSubCategory.id}
                          value={subSubCategory.id}
                        >
                          {subSubCategory.label}
                        </option>
                      );
                    })}
                  </CSelect>
                  {submitted && !subSubCategory && (
                    <span className="invalid-feedback error">
                      Veuillez choisir le sous categorie
                    </span>
                  )}
                </CFormGroup>
              </CCol>
            ) : null}
          </CRow>
          <Scroll/>
         </div>

         <div className="advInfoSzctionCard" >
        <Basic/>
         <CRow xs="12">
          <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="nom"> Nom du produit</CLabel>
                <CInput
                  type="text"
                  name="nom"
                  value={nom}
                  onChange={(e) => setNom(e.target.value)}
                ></CInput>
              </CFormGroup>
            </CCol>
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="price">Prix en TND</CLabel>
                <CInput
                  type="number"
                  name="price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                ></CInput>
                {submitted && !price && (
                  <span className="invalid-feedback error">Obligatoire</span>
                )}
              </CFormGroup>
            </CCol>
          </CRow>
          <Scroll/>
         </div>

         <div className="advInfoSzctionCard" >
          <Adresse/>
          <CRow xs="12">
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="country">Pays</CLabel>
                <CSelect
                  name="country"
                  value={country}
                  onChange={(e) => handleCountryChange(e)}
                >
                  <option value="0"> Choisissez un pays</option>
                  {countries.map((country) => {
                    return (
                      <option key={country.id} value={country.id}>
                        {country.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !country && (
                  <span className="invalid-feedback error">Error</span>
                )}
              </CFormGroup>
            </CCol>
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="Région">Gouvernorat</CLabel>
                <CSelect
                  name="city"
                  value={city}
                  onChange={(e) => handleCityChange(e)}
                >
                  <option value="0"> Gouvernorat</option>
                  {cities.map((city) => {
                    return (
                      <option key={city.id} value={city.id}>
                        {city.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !city && (
                  <span className="invalid-feedback error">Error</span>
                )}
              </CFormGroup>
            </CCol>
          </CRow>        
          <CRow xs="12">
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="delegate">Délégation</CLabel>
                <CSelect
                  name="delegate"
                  value={delegate}
                  onChange={(e) => handleDelegateChange(e)}
                >
                  <option value="0"> Choisissez une délégation</option>
                  {delegates.map((delegate) => {
                    return (
                      <option key={delegate.id} value={delegate.id}>
                        {delegate.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !delegate && (
                  <span className="invalid-feedback error">Error</span>
                )}
              </CFormGroup>
            </CCol>
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="loclité">Localité</CLabel>
                <CSelect
                  name="locality"
                  value={locality}
                  onChange={(e) => setLocality(e.target.value)}
                >
                  <option value="0"> Localité</option>
                  {localities.map((local) => {
                    return (
                      <option key={local.id} value={local.id}>
                        {local.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !locality && (
                  <span className="invalid-feedback error">Error</span>
                )}
              </CFormGroup>
            </CCol>
          </CRow>
          <Scroll/>
         </div>

         <div className="advInfoSzctionCard" >
       <Contact/>
         <CRow xs="12">
          <CCol xs="6">
              {newPhone && <CFormGroup className='formPhoneNumber'>
                <CLabel htmlFor="phoneNumber">Numéro téléphone</CLabel>
                <PhoneInput
                  type="text"
                  onChange={(value) => setPhoneNumber(value)}
                  placeholder="Entrez un numéro de téléphone"
                  defaultCountry="TN"
                  international
                  name="phoneNumber"
                  id="phoneNumber"
                  value={phoneNumber}
                />
                 {submitted && newPhone && phoneNumber.length>0 && !isValidPhoneNumber(phoneNumber) && (
                  <span className="invalid-feedback error">Ce numéro de téléphone n'est pas valide</span>
                )}
              </CFormGroup>}
              <CFormGroup variant="checkbox" className="checkbox">
                <CInputCheckbox
                  onChange={changePhoneNumber}                 
                />
                <CLabel variant="checkbox" className="form-check-label">
                Utilisez le numéro téléphone de votre compte
                </CLabel>
              </CFormGroup> 
            </CCol>
          </CRow>
          <Scroll/>
         </div>

         <div className="advInfoSzctionCard" >
     <Extra/>
       <CRow>
            <CCol>
              <CFormGroup>
                <CKEditor
                  editor={ClassicEditor}
                  data={description}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setDescription(data);
                  }}
                />

              </CFormGroup>
            </CCol>
          </CRow>
          <Scroll/>
         </div>

         <div className="advInfoSzctionCard" >

<Imgs/>
          <CRow>
            <CCol>
            <CFormGroup style={{display:"inline-block"}}>
                <CLabel htmlFor="pImage">Principal image</CLabel>
                <input 
                          type="file"
                          name="pImage"
                          onChange={handleFileSection}
                          accept="image/*"
                         //accept=".svg,.jpg,.png,.jpeg"
                           ></input>
            </CFormGroup>
            {uploadingImage &&<span style={{height:"fit-content",marginLeft:"10px",lineHeight:"90px"}}>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span>}
              {!uploadingImage && pImageThumb && 
               <img style={{height: "85px", width: "auto"}}
          src={pImageThumb}
          alt="preview"
          className="img-fluid img-thumbnail "
        />} 
           {submitted && !imgPrincipal && (
                <span className="invalid-feedback error">
                  Veuillez uploader une image
                </span>
              )}
            </CCol>
          </CRow><br/>
          {imgCountA>0 && <> <div className="imgCounter"> {imgCount} {imgCount > 1 ? ' images téléchargées sur ' :' image téléchargée sur ' }  {imgCountA} </div>
       <div className="loading-bar">
          <div className="loading-bar-fill" style={{ width: `${imgCountA==0?0 : imgCount<=imgCountA ?(imgCount / imgCountA) * 100 :'100'}%` }}></div>
        </div><br/></>}
          <CRow>
            <CCol>
            <CLabel >Autres images</CLabel>
              <Dropzone
               
                onChangeStatus={handleChangeStatus}
                // onSubmit={handleSubmitF}
                accept="image/*"
               // accept=".svg,.jpg,.png,.jpeg"
                maxFiles="9"
                maxSizeBytes={5 * 1024 * 1024}
              >
                <input />
              </Dropzone>
            </CCol>
          </CRow>
          <br />
          </div>
          {success && (
            <CAlert show={true} placement="top-end" color="success">
              {`L'annonce a été ajoutée avec succès !`}
            </CAlert>
          )}

          <CRow>
            <CCol md="3" className="offset-9">
           
{
  ((!(doneuploadingfile && imgCountA == album.length)) || uploadingImage)? 
  (
              <CButton color="primary" block disabled>
                <span>
                  {" "}
                  <CSpinner color="default" size="sm" />
                </span>

                <span> chargement des images</span>
              </CButton>
            )

       :  loading ? (
              <CButton color="primary" block disabled>
                <span>
                  {" "}
                  <CSpinner color="default" size="sm" />
                </span>

                <span> Valider</span>
              </CButton>
            )  
            :
              <CButton color="primary" block type="submit">
                Valider
              </CButton>
            
}
            </CCol>
          </CRow>
              </form>
              : <Loading/>
              }
             </div>

              </CTabContent>
            </CTabs>
          </div>
        </main>
      </div>
    </>
  ) : (
    <LoginMember />
  );
}




