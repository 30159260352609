import React, { useEffect, useState } from "react";
import { API_BASE_URL , tokenU} from "../../../config";
import {
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CButton,
  CCollapse,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CAlert,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { Link } from "react-router-dom";
import axios from "axios";

const getBadge = (status) => {
  switch (status) {
    case "Active":
      return "success";
    case "Inactive":
      return "secondary";
    case "Pending":
      return "warning";
    case "Banned":
      return "danger";
    default:
      return "primary";
  }
};

export default function Users() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [modal, setModal] = useState(false);
  const [details, setDetails] = useState([]);
  const [errorDel, setErrorDel] = useState("");

  const toggle = () => {
    setModal(!modal);
  };
  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };

  function deleteUser(id) {
    //console.log(id);
    //console.log('itttttems');
    //console.log(items);
    toggle();
    axios
      .delete(`${API_BASE_URL}/users/delete/${id}`,{
        params: { userId: tokenU,
                 },
      })
      .then((res) => {
        console.log(res);
        if (res.data.status.toString() === "ok") {
          setItems((items) => items.filter((x) => x.id !== id));
        }
        if (
          res.data.status.toString() ===
          "ForeignKeyConstraintViolationException"
        ) {
          setErrorDel("Vous ne pouvez pas supprimer cette actualité");
        }
      })
      .catch((err) => console.log(err));
  }

  const fields = [
    {
      key: "prenom",
      label: "Prénom",
    },
    { key: "nom" },
    { key: "email" },
    { key: "roles" ,
    label: "Role"
    },

    {
      key: "show_details",
      label: "Actions",
      _style: { width: "1%" },
      sorter: false,
      filter: false,
    },
  ];
  useEffect(() => {
    axios
      .get(API_BASE_URL + "/users",{
      params: { userId: tokenU,
               },
    }
  )
  .then((res) => res.data)
  .then(
        (result) => {
          console.log('reeees',result);
          setIsLoaded(true);
          setItems(result.users);
        },
        (error) => {
          console.log('eeeeee',error);
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  if (error) {
    return <div>Erreur : {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Chargement...</div>;
  } else {
    return (
      <div>
        <CRow>
          <CCol xl={12}>
            <Link
              className="btn btn-outline-primary btn-lg "
              to="/5467854/register"
            >
              {" "}
              <CIcon name="cil-user-follow" /> Ajouter un utilisateur
            </Link>

            <p></p>
            <CCard>
              <CCardHeader>Utilisateurs</CCardHeader>
              <CCardBody>
              {errorDel !== "" ? (
                  <CAlert color="danger" closeButton>
                    {errorDel}
                  </CAlert>
                ) : (
                  ""
                )}
                <CDataTable
                  items={items}
                  fields={fields}
                  columnFilter
                  tableFilter
                  footer
                  itemsPerPageSelect
                  itemsPerPage={10}
                  hover
                  sorter
                  pagination
                  scopedSlots={{
                    roles: (item) => (
                      <td>
                          {item.roles[0]==='ROLE_SUPER_ADMIN' && "Admin"}
                          {item.roles[0]==='ROLE_EDITOR_IN_CHIEF' && "Rédacteur en chef"}
                          {item.roles[0]==='ROLE_JOURNALIST' && "Journaliste"}
                          {item.roles[0]==='ROLE_MANAGER' && "Manager"}
                      </td>
                      
                    ),
                    status: (item) => (
                      <td>
                        <CBadge color={getBadge(item.status)}>
                          {item.status}
                        </CBadge>
                      </td>
                    ),
                    show_details: (item, index) => {
                      return (
                        <td className="py-2">
                          <CButton
                            color="primary"
                            variant="outline"
                            shape="square"
                            size="sm"
                            onClick={() => {
                              toggleDetails(index);
                            }}
                          >
                            {details.includes(index) ? "Hide" : "Show"}
                          </CButton>
                        </td>
                      );
                    },
                    details: (item, index) => {
                      return (
                        <CCollapse show={details.includes(index)}>
                          <CCardBody>
                            <h4>{item.username}</h4>
                            <Link to={`/5467854/users/edit/${item.id}`}>
                              <CButton size="sm" color="info">
                                Modifier
                              </CButton>
                            </Link>

                            <CButton size="sm" color="danger" className="ml-1" onClick={toggle}>
                              Delete
                            </CButton>
                            <CModal show={modal} onClose={toggle}>
                              <CModalHeader closeButton></CModalHeader>
                              <CModalBody>
                                Voulez vous vraiment supprimer cet utilisateur ?
                              </CModalBody>
                              <CModalFooter>
                                <CButton
                                  color="primary"
                                  onClick={() => {
                                    deleteUser(item.id);
                                    toggleDetails(index);
                                  }}
                                >
                                  Oui
                                </CButton>

                                <CButton color="secondary" onClick={toggle}>
                                  Cancel
                                </CButton>
                              </CModalFooter>
                            </CModal>
                          </CCardBody>
                        </CCollapse>
                      );
                    },
                  }}
                />
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </div>
    );
  }
}
