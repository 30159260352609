
import axios from 'axios';
import React, { Component } from 'react'
import { API_BASE_URL } from "../../../config";
import {
    CAlert,
    CButton,
    CCard,
    CCardBody,
    CLabel,
    CCol,
    CContainer,
    CForm,
    CInput,
    CRow,
    CFormGroup,
  } from "@coreui/react";
export class EditCatNews extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             typeNews:[],
             label: '',
             createdBy: localStorage.getItem("id"),
             errors: {
               label: "",
             },
             success: "",
        }
    }
    
    componentDidMount() {
        const { id } = this.props.match.params;
        axios(`${API_BASE_URL}/category-news/show/${id}`)
      .then((res) => res.data)
      .then((result) => {
       
        this.setState({
            typeNews: result,
            label: result.label
        });
       
      });
    }
    handleChange = (event) =>
    this.setState({
      [event.target.name]: event.target.value,
    });

  handleValidation = () => {
    let fields = this.state.label;
    let errors = {};
    let formIsValid = true;
    if (!fields) {
      formIsValid = false;
      errors["label"] = "Le libéllé ne peut pas être vide";
    } else {
      errors["label"] = "";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };
  handleSubmit = (event) => {
    const { id } = this.props.match.params;
    event.preventDefault();

    if (this.handleValidation()) {
     
      
      axios
        .post(`${API_BASE_URL}/category-news/edit/${id}`, this.state)
        .then((res) => {
          if (res.data.status.toString() === "UniqueConstraintViolationException") {
            this.setState({
              errors: {
                label: "Cette catégorie d'actualité existe déjà",
              },
            });
          }
          if (res.data.status.toString() === "ok") {
            this.setState({
              success: "La catégorie d'actualité été modifié avec succès",
              label:'',
            });
            
           
          }
          // this.props.history.push("/5467854/index");
        })
        .catch((error) => {
          console.log("login errors : ", error);
        });
    }
  };
    render() {
       
        let errors = this.state.errors;
        return (
            <CContainer>
        <CRow className="justify-content-center">
          <CCol md="12" lg="12" xl="12">
            <CCard className="mx-4">
              <CCardBody className="p-4">
              {this.state.success !== "" ? (
                    <CAlert color="success" closeButton>
                      {this.state.success}
                    </CAlert>
                  ) : (
                    ""
                  )}
                <CForm onSubmit={this.handleSubmit} id="form-add">
                  <h1>Modifier type d'actualité</h1>
                  <CCol>
                    <p></p>
                  </CCol>
                  
                  <CRow>
                    <CCol xs="6">
                      <CFormGroup>
                        <CLabel htmlFor="label">Catégorie d'actualité</CLabel>
                        <CInput
                          id="label"
                         
                          name="label"
                          value={this.state.label}
                          onChange={this.handleChange}
                        />
                      </CFormGroup>
                    </CCol>
                  </CRow>
                  
                    {errors.label.length > 0 && (
                     <CCol> <p
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.label}
                       </p></CCol>
                    )}
                 
                  <CRow>
                    <CCol xs="12" sm="3">
                      <CButton color="success" block type="submit">
                        Valider
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
        )
    }
}

export default EditCatNews
