import React, { Component } from "react";
import { API_BASE_URL } from "../../../config";
import axios from "axios";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CSpinner
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
export class LoginBack extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //captchaToken: '',
      isloading: false,
      email: "",
      password: "",
      loginErrors: "",
      loginErrorsA:"",
      showPass: false,
    };
  }

  // componentDidMount() {
  //   window.grecaptcha.ready(() => {
  //     window.grecaptcha.execute('6Ld0TcgpAAAAANxm4lF75_QlEAPOV5kbbQYXa-EY', { action: 'submit' }).then(token => {
  //       this.setState({ captchaToken: token }); // Set captchaToken in state
  //     });
  //   });
  // }

  viewPassword = () => {
    let show = !this.state.showPass;
    this.setState({
      showPass: show,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  

  handleSubmit = (event) => {
   //if(document.cookie.match(new RegExp('(^| )' + 'tokenU' + '=([^;]+)'))) 
   //console.log(  document.cookie.match(new RegExp('(^| )' + 'tokenU' + '=([^;]+)'))[2]);

   this.setState({ loginErrors: "" });
    if (this.state.password.length < 4 || this.state.email.length < 2) {
      this.setState({
        loginErrors:
          "Veuillez saisir votre email ou mot de passe correctement!",
      });
    } else {
      this.setState({
        isloading: true,
      });
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute('6Ld0TcgpAAAAANxm4lF75_QlEAPOV5kbbQYXa-EY', { action: 'submit' }).then(token => {
        
          // const formData = new FormData();           
          // formData.append("email", this.state.email);
          // formData.append("password", this.state.password);
          // formData.append('captchaToken', token); 

          // console.log("/users/login", formData)
          //console.log("/users/login", this.state)
//return;
          axios
            .post(API_BASE_URL + "/users/login", {
              ...this.state, 
              captchaToken: token 
          })
            .then((res) => {
              console.log('res : ', res)
              if(res.data.status=='ok') {
              localStorage.setItem("auth", res.data);
            localStorage.setItem("username", res.data.prenom + ' '+res.data.nom );
              localStorage.setItem("roles", res.data.roles);
              if(res.data.roles=='ROLE_JOURNALIST' && res.data.cats)
              {
                localStorage.setItem("allowedCats", res.data.roles);
              }
              //localStorage.setItem("id", res.data.user.id);
              const expirationDate = new Date();
              expirationDate.setTime(expirationDate.getTime() + (73 * 60 * 60 * 1000)); // 70 hours in milliseconds
      
              // Format the expiration date in the required UTC format
              const formattedExpiration = expirationDate.toUTCString();
      
              // Save the token in a cookie without HttpOnly with expiration after 70 hours
              document.cookie = `tokenU=${res.data.token}; expires=${formattedExpiration}; path=/; secure; SameSite=Strict`;
      
              //document.cookie = `token=${res.data.token}; expires=Thu, 01 Jan 2030 00:00:00 UTC; path=/; secure; SameSite=Strict`;
    
              this.setState({
                isloading: false,
              });
              //console.log(localStorage.getItem("roles"));
            
             //console.log('Cookies:', document.cookie);
             //this.props.history.push("/5467854/index");
             window.location.replace("/5467854/index");
               } else  if (res.data.status == "captcha-failed") {
                this.setState({
                  isloading: false,
                  loginErrors: "Google Recaptcha n'a pas pu vérifier que vous n'êtes pas un robot, veuillez réessayer.",
                  loginErrorsA:" تعذر التحقق من كونك لست روبوت، الرجاء المحاولة مرة اخرى   ",
                });
              }
               else {
                this.setState({
                  isloading: false,
                  loginErrors: "Une erreur s'est produite, veuillez réessayer",
                });
               }
              })
            .catch((error) => {
              console.log("login errorssssss : ", error);
              this.setState({
                isloading: false,
                loginErrors: "L'email ou le mot de passe entré est incorrect.",
              });
            });

        });
      });


    }
    event.preventDefault();
  };

  render() {
    return (
      <div className="c-app c-default-layout flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md="8">
              <CCardGroup>
                <CCard className="p-4">
                  <CCardBody>
                    <form onSubmit={this.handleSubmit}>
                      <h1>Connexion</h1>
                      <p className="text-muted">
                        Connectez vous à votre compte
                      </p>
                      <CInputGroup className="mb-3">
                        <CInputGroupPrepend>
                          <CInputGroupText>
                            <CIcon name="cil-user" />
                          </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput
                          type="text"
                          placeholder="Email"
                          name="email"
                          value={this.state.email}
                          onChange={this.handleChange}
                        />
                      </CInputGroup>
                      <CInputGroup className="mb-4">
                        <CInputGroupPrepend>
                          <CInputGroupText>
                            <CIcon name="cil-lock-locked" />
                          </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput
                          type={this.state.showPass ? "text" : "password"}
                          placeholder="mot de passe"
                          name="password"
                          value={this.state.password}
                          onChange={this.handleChange}
                        />
                      </CInputGroup>
                      <CCol className="showPassRow">
                        <label htmlFor="showpassword">
                          <input
                            name="showpassword"
                            type="checkbox"
                            onChange={this.viewPassword}
                          />
                          Show password
                        </label>
                      </CCol>
                      {this.state.loginErrors && <>
                      <p className="alert-danger">{this.state.loginErrors}</p>
                      <p className="alert-danger">{this.state.loginErrorsA}</p>
                      </>}
                      <CRow>
                        <CCol xs="6">
                          <CButton
                            color="primary"
                            className="px-4"
                            type="submit"
                            disabled={this.state.isloading}
                          >
                         {this.state.isloading  && <CSpinner size="sm"/>}   Connexion
                          </CButton>
                        </CCol>

                        <CCol xs="6" className="text-right">
                          <a
                            href="/5467854/forget"
                            style={{ color: "blue", cursor: "pointer" }}
                          >
                            mot de passe oublié?
                          </a>
                        </CCol>
                      </CRow>
                      
                    </form>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    );
  }
}

export default LoginBack;
