import React, { useState , useEffect} from 'react';
import MemberContext from './MemberContext';
import { API_BASE_URL , tokenCookie , keyHeader} from 'src/config';
import axios from 'axios';
import { logoutLocal } from 'src/components/middleware/auth';

const MemberProvider = ({ children }) => {
    const [loadedAnimals, setloadedAnimals] = useState(false);
    const [animals, setAnimals] = useState([]);
    const [loadedArticles, setloadedArticles] = useState(false);
    const [articles, setArticles] = useState([]);
    const [loadedPlans, setloadedPlans] = useState(false);
    const [plans, setPlans] = useState([]);
    const [loadedVehicles, setloadedVehicles] = useState(false);
    const [vehicles, setVehicles] = useState([]); 
    const [loadedEstates, setloadedEstates] = useState(false);
    const [estates, setEstates] = useState([]); 
    const [loadedServices, setloadedServices] = useState(false);
    const [services, setServices] = useState([]);
    const [loadedJobs, setloadedJobs] = useState(true);
    const [jobs, setJobs] = useState([]);
    const [testm, setTestm] = useState('rererererererettttttt');
    const [failed, setFailed] = useState(false);

  

 
    useEffect(() => {
        const source = axios.CancelToken.source();
        //console.log('in member provvv --- ')
        const formData = new FormData();
        formData.append("key",tokenCookie );
      
        axios.post(`${API_BASE_URL}/animals/By_member`, formData, {
          cancelToken: source.token,
          ...keyHeader,
        })
            .then(response => {  
              setloadedAnimals(true);
              setAnimals(response.data.items);
              ////console.log('response anim --- ', response)
    
             
            })
            .catch(error => {
              //setloadedAnimals(true);
              //setError(error);
             
                //console.log('error animals --- ',error.response.status , '>>>>',  error)
                if (error.response && error.response.status === 401){
                  setFailed(true)
                }
      
            });
    
       axios.post(`${API_BASE_URL}/articles/By_member`, formData, {
        cancelToken: source.token,
        ...keyHeader,
      })
                .then(response => {
                //console.log('response arttt --- ', response);
                  setloadedArticles(true);
                  setArticles(response.data.items);
                 
                })
                .catch(error => {
                  //console.log('error  --- ',error.response.status , '>>>>',  error)
                  if (error.response && error.response.status === 401){
                    setFailed(true)
                  }
          
                });    
      
       axios.post(`${API_BASE_URL}/goodPlans/By_member`, formData, {
        cancelToken: source.token,
        ...keyHeader,
      })
                .then(response => {
                  //console.log('response plans --- ', response);
                  setloadedPlans(true);
                  setPlans(response.data.items);
                 
                })
                .catch(error => {
                  //console.log('error  --- ',error.response.status , '>>>>',  error)
                  if (error.response && error.response.status === 401){
                    setFailed(true)
                  }
          
                });
    
                axios.post(`${API_BASE_URL}/vehicle/By_member`, formData, {
                  cancelToken: source.token,
                  ...keyHeader,
                })
                .then(response => {
                  //console.log('response vvvvv --- ', response);
                  setloadedVehicles(true);
                  setVehicles(response.data.items);
                 
                })
                .catch(error => {
                  if (axios.isCancel(error)) {
                    //console.log("Request cancelled vvvvvv:", error.message);
                  } else {
                   // console.log('error  --- ',error.response.status , '>>>>',  error)
                    if (error.response && error.response.status === 401){
                      setFailed(true)
                    }
                  }
                });        
    
                axios.post(`${API_BASE_URL}/real_estate/By_member`, formData, {
                  cancelToken: source.token,
                  ...keyHeader,
                })
                .then(response => {
                  //console.log('response estates --- ', response);
                  setloadedEstates(true);
                  setEstates(response.data.items);
                 
                })
                .catch(error => {
                  if (axios.isCancel(error)) {
                    //console.log("Request cancelled estttt:", error.message);
                  } else {
                   // //console.log('error  --- ',error.response.status , '>>>>',  error)
                    if (error.response && error.response.status === 401){
                      setFailed(true)
                    }
                  }
                });
    
                axios.post(`${API_BASE_URL}/services/By_member`, formData, {
                  cancelToken: source.token,
                  ...keyHeader,
                })
                .then(response => {
                  //console.log('response serrr --- ', response);
                  setloadedServices(true);
                  setServices(response.data.items);
                 
                })
                .catch(error => {
            if (axios.isCancel(error)) {
              //console.log("Request cancelled serrr:", error.message);
            } else {
             // //console.log('error  --- ',error.response.status , '>>>>',  error)
              if (error.response && error.response.status === 401){
                setFailed(true)
              }
            }
                });


            if(localStorage.getItem("typeCompte") == 2 ) 
             {  setloadedJobs(false);
               axios.post(`${API_BASE_URL}/jobs/my-jobs`, formData, {
                  cancelToken: source.token,
                  ...keyHeader,
                })
                .then(response => {
                  //console.log('response jobss --- ', response);
                  setloadedJobs(true);
                  setJobs(response.data.items);
                 
                })
                .catch(error => {
            if (axios.isCancel(error)) {
              //console.log("Request cancelled serrr:", error.message);
            } else {
              ////console.log('error  --- ',error.response.status , '>>>>',  error)
              if (error.response && error.response.status === 401){
                setFailed(true)
              }
            }
                });
              }
       // Clean up function to cancel the request on component unmount
       return () => {
        source.cancel("Request cancelled on component unmount.");
      };
    
        }, []);

        useEffect(() =>   {

        if(failed)    {
          logoutLocal();
          }
    }  , [failed]);

  const value = {
        animals,
        articles,
        vehicles,
        estates,
        services,
        plans,
        jobs,
        loadedAnimals,
        loadedArticles,
        loadedEstates,
        loadedPlans,
        loadedServices,
        loadedVehicles,
        loadedJobs,
        setAnimals,
        setArticles,
        setEstates,
        setPlans,
        setServices,
        setVehicles,
        setJobs,
        testm,
        failed
  };

  return (
   
    <MemberContext.Provider value={value}>
      {children}
    </MemberContext.Provider>
  );
};

export default MemberProvider;
