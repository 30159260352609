import React, { Component } from "react";
import { API_BASE_URL } from "../../../config";
import axios from "axios";
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CLabel,
  CCol,
  CContainer,
  CForm,
  CInput,
  CRow,
  CFormGroup,
  CSelect,
} from "@coreui/react";


export class AddCatNews extends Component {
  constructor(props) {
    super(props);

    this.state = {
      label: "",
      createdBy: localStorage.getItem("id"),
      type:[],
      errors: {
        label: "",
      },
      success: "",
    };
  }
  componentDidMount() {
    axios(`${API_BASE_URL}/type-news/all`)
    .then((res) => res.data)
    .then((result) => {
      //console.log('resultttt', result)
      this.setState({
        typeSelect: result,
       
       // type_annoce: [{ id:1,label:"annonce_car"},{id:2,label:"annonce_camion" },{id:3, label:"annonce_bike" },{id:4, label:"annonce_moto"},{id:5, label:"annonce_bus"}],
        loading :false

      });

    });
  }

  handleChange = (event) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    const name = event.target.name;
    this.setState({
      [name]: value,
    });
  };

    
  handleValidation = () => {
    let fields = this.state.label;
    let errors = {};
    let formIsValid = true;
    if (!fields) {
      formIsValid = false;
      errors["label"] = "Le libéllé ne peut pas être vide";
    } else {
      errors["label"] = "";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };
  handleSubmit = (event) => {
    event.preventDefault();

    if (this.handleValidation()) {
      //console.log(this.state)
      axios
        .post(API_BASE_URL + "/category-news/add", this.state)
        .then((res) => {
          if (res.data.status.toString()==="UniqueConstraintViolationException") {
            this.setState({
              errors: {
                label: "Cette catégorie d'actualité existe déjà",
              },
            });
          }
          if (res.data.status.toString()=== "ok") {
            this.setState({
              success: "La catégorie d'actualité été ajoutée avec succès",
              label:'',
            });
            
           
          }
          // this.props.history.push("/5467854/index");
        })
        .catch((error) => {
          console.log("add errors : ", error);
        });
    }
  };
 

  render() {
    let errors = this.state.errors;
    return (
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="12" lg="12" xl="12">
            <CCard className="mx-4">
              <CCardBody className="p-4">
              {this.state.success !== "" ? (
                    <CAlert color="success" closeButton>
                      {this.state.success}
                    </CAlert>
                  ) : (
                    ""
                  )}
                <CForm onSubmit={this.handleSubmit} id="form-add">
                  <h1>Ajouter un nouvelle catégorie d'actualité</h1>
                  <CCol>
                    <p></p>
                  </CCol>
                  
                  <CRow>
                    <CCol xs="6">
                      <CFormGroup>
                        <CLabel htmlFor="label">Catégorie d'actualité</CLabel>
                        <CInput
                          id="label"
                          placeholder="Entrer le libellé de la catégorie d'actualité"
                          name="label"
                          value={this.state.label}
                          onChange={this.handleChange}
                        />
                      </CFormGroup>
                    </CCol>
                    
            </CRow>
                  <CRow>
                  <CCol xs="8">
                      <CFormGroup>
                        <CLabel htmlFor="content">
                         Type d'actualité
                        </CLabel>
                        <CSelect
                          name="type"
                          value={this.state.type.id}
                          onChange={this.handleChange}
                        >
                          <option value="">--------------------</option>
                          {this.state.typeSelect && this.state.typeSelect.map((type) => {
                            return (
                              <option
                                key={type.id}
                                id={type.id}
                                value={type.id}
                              >
                                {type.label}
                              </option>
                            );
                          })}
                        </CSelect>
                      </CFormGroup>
                    </CCol>
                 
                 
                
                  </CRow>
                  <CCol>
                    {errors.label.length > 0 && (
                      <p
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.label}
                      </p>
                    )}
                  </CCol>
                  <CRow>
                    <CCol xs="12" sm="3">
                      <CButton color="success" block type="submit">
                        Valider
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    );
  }
}

export default AddCatNews;
