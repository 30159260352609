import React, { Component } from 'react'
import { API_BASE_URL } from "../../config";
import axios from 'axios';

export class Policy extends Component {
    constructor(props) {
        super(props)
       
        this.state = {
            accept:false,

        }
    }
    

    render() {
        return(
    <div className="container">
 <div className='termsText'>
    <h2>POLITIQUE DE CONFIDENTIALITÉ DU SITE WEB « TUNISIA LIVING »</h2>
    <p>La société DS, exploitant du site web Tunisia Living, accorde une grande importance à la protection et au respect de votre vie privée.
La présente politique de protection des données personnelles vise à vous informer sur la manière dont Tunisia Living traite vos données personnelles conformément à la loi organique n°63 du 27/07/2004 relative à la protection des données personnelles (ci-après dénommée la « Loi »). 
Cette politique régit nos pratiques en matière de collecte, d'utilisation et de partage des informations à caractère personnel que nous recueillons lorsque vous accédez à notre site web officiel (désigné ci-après le "Site").
Notre objectif est de vous informer sur les types de données personnelles susceptibles d'être collectées ou détenues vous concernant, sur la manière dont nous les utilisons, la durée pendant laquelle nous les conservons, les mesures de protection que nous mettons en place, les cookies que nous utilisons ainsi que leurs objectifs, et enfin sur les droits dont vous disposez concernant vos données personnelles.</p>
 <strong>1- Les données que nous collectons:</strong><br/>
 <p>En utilisant le site web, vous nous communiquez des informations, dont certaines peuvent vous identifier et sont donc considérées comme des données à caractère personnel (ci-après désignées les « Données »). Cela se produit notamment lorsque vous effectuez des navigations sur le Site.</p>
 <p>Ces informations comprennent notamment :</p>
 <ul>
    <li>
    Données du compte : il s'agit des informations que vous fournissez lors de la création d'un compte en remplissant le formulaire d'inscription, telles que votre genre, votre nom et votre prénom, votre date de naissance/âge, votre lieu de résidence et votre adresse e-mail.
    </li>
    <li>
    Données rendues publiques : cela englobe toutes les informations que vous choisissez de partager volontairement sur le Site , telles que les commentaires sur les blogs et les forums, les photos, les discussions sur les forums et le profil de compte.
    </li>
    <li>
    Données relatives à la navigation : il s'agit d'informations concernant votre ordinateur ou tout autre appareil de connexion (tablette, smartphone), notamment votre adresse IP, votre localisation géographique, le type et la version de votre navigateur, votre système d'exploitation, ainsi que d'autres données sur vos visites et votre utilisation du Site, comme la source référente, la durée de la visite, les pages consultées et les parcours de navigation sur les sites web.
    </li>
 </ul>
 <strong>2- Comment nous collectons vos données ?</strong><br/>
 <p>Conformément à la Loi, qui stipule que « la collecte des données à caractère personnel ne peut être effectuée que pour des finalités licites, déterminées et explicites », nous collectons et traitons vos données de la manière suivante :</p>
<ul>
    <li>
    Lors de votre inscription en ligne ou lorsque vous passez une commande pour l'un de nos produits ou services.
    </li>
    <li>
    Lorsque vous répondez volontairement à une enquête client ou fournissez des commentaires sur l'un de nos tableaux d'affichage, ou encore par courrier électronique.
    </li>
    <li>
    Lorsque vous utilisez ou consultez notre site web via les cookies de votre navigateur.
    </li>
</ul>
<strong>3-Comment utilisons-nous les données que nous collectons ?</strong><br/>
<p>Conformément à la Loi, qui stipule que « les données à caractère personnel doivent être traitées loyalement, et dans la limite nécessaire au regard des finalités pour lesquelles elles ont été collectées », nous utilisons les données collectées pour des finalités spécifiques, telles que notamment :</p>
<ul>
    <li>
    Administrer notre site web et notre entreprise ;
    </li>
    <li>
    Personnaliser notre site web pour vous ;
    </li>
    <li>
    Faciliter votre utilisation des services proposés sur notre site web ;
    </li>
    <li>
    Livrer les produits achetés sur notre site si c'est la cas;
    </li>
    <li>
    Fournir les services achetés sur notre site ;
    </li>
    <li>
    Envoyer des notifications par e-mail que vous avez expressément demandées ;
    </li>
    <li>
    Envoyer notre newsletter par e-mail, si vous l'avez demandé (vous pouvez vous désabonner à tout moment) ;
    </li>
    <li>
    Envoyer des communications marketing relatives à notre entreprise ou à des entreprises tierces sélectionnées avec soin, sous forme de publication, ou si vous avez expressément donné votre accord, par e-mail ou technologie similaire (vous pouvez vous désabonner à tout moment) ;
    </li>
    <li>
    Fournir des informations statistiques sur nos utilisateurs à des tiers (sans que ces tiers puissent identifier un utilisateur individuel avec ces informations) ;
    </li>
    <li>
    Traiter les demandes et les réclamations relatives à votre site web effectuées par vous ou vous concernant ;
    </li>
    <li>
    Maintenir la sécurité de notre site web et prévenir la fraude ;
    </li>
    <li>
    Vérifier le respect des conditions générales régissant l'utilisation de notre site web (y compris surveiller les messages privés envoyés via le service de messagerie de notre site web) ;
    </li>
    <li>
    Orienter nos offres et promotions en fonction de l'analyse des tendances d'achat.
    </li>
</ul>
<strong>4-La conservation de vos données et les mesures de sécurité que nous mettons en place pour protéger vos données :</strong><br/>
<p>Les données enregistrées suite à leur collecte sur le site sont conservées pendant la durée strictement nécessaire aux fins pour lesquelles elles sont collectées.</p>
<p>En outre, nous conservons des documents (y compris des documents électroniques) contenant des données personnelles dans les cas suivants : (A) lorsque la loi et la réglementation en vigueur l'exigent, (B) si nous estimons que les documents peuvent être pertinents pour toute procédure judiciaire en cours ou potentielle, et (C) pour établir, exercer ou défendre nos droits légaux (y compris fournir des informations à d'autres à des fins de prévention des fraudes).</p>
<p>Afin de garantir la confidentialité de vos données et de prévenir toute utilisation illicite, nous prenons les mesures suivantes :</p>
<p className='termsMargined' >
Nous mettons en place des précautions techniques et organisationnelles raisonnables pour prévenir la perte, l'abus ou l'altération de vos informations personnelles.
Toutes les informations personnelles que vous nous fournissez sont stockées sur des serveurs sécurisés, protégés par mot de passe et pare-feu.
Toutes les transactions financières électroniques effectuées sur notre site web sont protégées par des technologies de cryptage.
Vous devez savoir que la transmission d'informations par internet comporte des risques inhérents à la sécurité, et nous ne pouvons garantir la sécurité des données que vous envoyez par internet.
Vous êtes responsable de la confidentialité de votre mot de passe utilisé pour accéder à notre site web ; nous ne vous demanderons jamais votre mot de passe (sauf lorsque vous vous connectez sur notre site web).
</p>
<strong>5-Quels sont vos droits sur vos données ?</strong>
<p>Conformément à la législation applicable en matière de protection des données personnelles, vous disposez d'un certain nombre de droits concernant vos données personnelles :</p>
<ul>
    <li>
    Le droit d'accès : il s'agit du droit de consulter les données vous concernant, ainsi que celui d'obtenir une copie de ces données dans un format clair et compréhensible. Cela vous permet de prendre connaissance des traitements effectués sur vos données par Tunisia Living.
    </li>
    <li>
    Le droit de rectification : si vos données personnelles sont incomplètes, inexactes ou non mises à jour, vous avez le droit de les faire rectifier, compléter, mettre à jour, modifier ou clarifier.
    </li>
    <li>
    Le droit d'opposition : vous avez le droit de vous opposer au traitement de vos données pour des raisons légitimes.
    </li>
    <li>
    Le droit à l'effacement : vous pouvez demander à Tunisia Living de supprimer vos données personnelles. Toutefois, ce droit peut être limité dans certains cas, notamment lorsque vos données sont nécessaires à la fourniture de services, au respect des obligations légales de Tunisia Living ou à l'exercice de ses droits légitimes.
    </li>
</ul>
<p>Si vous souhaitez exercer l'un de ces droits, veuillez nous contacter via l'adresse d'assistance sur le site web : team@tunisialiving.tn</p>
<strong>6-Utilisation des cookies :</strong>
<p>Notre site web utilise des cookies. Un cookie est un fichier contenant un identifiant (une chaîne de lettres et de chiffres) envoyé par un serveur web vers un navigateur web et stocké par le navigateur. L'identifiant est alors renvoyé au serveur à chaque fois que le navigateur demande une page au serveur. Les cookies peuvent être de deux types : « persistants » ou « de session ». Un cookie persistant est stocké par le navigateur et reste valide jusqu'à sa date d'expiration, sauf s'il est supprimé par l'utilisateur avant cette date. En revanche, un cookie de session expire à la fin de la session utilisateur, c'est-à-dire lors de la fermeture du navigateur. Les cookies en général ne contiennent pas d'informations permettant d'identifier personnellement un utilisateur. Cependant, les informations personnelles que nous stockons à votre sujet peuvent être associées aux informations stockées dans les cookies et obtenues par leur intermédiaire.</p>
 <p>Notre site Web utilise des cookies pour améliorer l'expérience de l'utilisateur, selon les catégories suivantes :</p>
<p className='termsMargined'>	Fonctionnalité : Ces cookies permettent à notre société de vous reconnaître sur notre site Web et de mémoriser vos préférences précédemment sélectionnées. Cela peut inclure la langue que vous préférez et votre emplacement. Nous utilisons à la fois des cookies internes et des cookies tiers pour cette fonctionnalité.
	Publicité : Ces cookies nous aident à collecter des informations sur votre visite sur notre site Web, telles que le contenu que vous avez consulté, les liens que vous avez suivis, ainsi que des détails sur votre navigateur, votre appareil et votre adresse IP. Parfois, notre société partage certains aspects limités de ces données avec des tiers à des fins publicitaires. De plus, les données en ligne collectées par le biais de cookies peuvent être partagées avec nos partenaires publicitaires. Ainsi, lorsque vous visitez d'autres sites Web, vous pouvez voir des publicités basées sur vos habitudes de navigation sur notre site Web.</p>
<strong>7. Modification de notre politique de confidentialité</strong>
<p>Nous pouvons être amenés à modifier occasionnellement la présente politique, afin notamment de se conformer à toutes évolutions réglementaires, jurisprudentielles, éditoriales ou techniques. Le cas échéant, nous changerons la date de « dernière mise à jour » et indiquerons la date à laquelle les modifications ont été apportées. Vous devez vérifier cette page régulièrement pour vous assurer de prendre connaissance de tout changement effectué à cette politique. Nous pouvons vous informer des changements effectués à cette politique par courrier électronique ou par le biais du service de messagerie privée de notre site web.</p>
<br/>
<p>Version mise à jour le 31/05/2023</p>
<br/>




 </div>
</div>
   )
 }
}

    export default Policy